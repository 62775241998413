import React, { Component } from "react";
import { Button, Modal, Radio } from "semantic-ui-react";
import {
  connect,
  bindActionCreators,
  actions,
  withRouter,
} from "../../../../functional/global-import";
class ChangeSignerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectSigner: false,
      isDisabled : false
    };
  }
  render() {
    const waiverArr = this.props.waiver.sort(function (a, b) {
      return a.minor - b.minor;
    });
    // const isAllMinorsTrueExceptNone = (isDisabled) => {
    //   for (let i = 1; i < waiverArr.length; i++) {
    //     if (!waiverArr[i].minor || isDisabled) {
    //       return false;
    //     }
    //   }
    //   return true;
    // };

    const selectSigner = () => {
      const { documentCustomFields } = this.props;
      const selectedDetails = this.props.waiver.filter((waiver) => {
        return waiver.MainSigner && waiver.customerId === null;
      });
      const selectedCustomerId = selectedDetails && selectedDetails.length > 0;
      if (this.state.selectSigner) {
        let data = [...this.props.waiver];
        let finalData = data.filter((waiver) => {
          return waiver.IsSigned || waiver.MainSigner;
        });

        const finalDataAfterSort = finalData.sort(
          (a, b) => b.MainSigner - a.MainSigner
        );

        const finalDataWithCustomFields = finalDataAfterSort.map((waiver) => ({
          ...waiver,
          customField: documentCustomFields,
        }));

        this.props.allFuction.setState({ waiver: finalDataWithCustomFields });
        this.props.closeModal();
      } else if (selectedCustomerId) {
        removeData();
      } 
      else {
              
        let data = [...this.props.waiver];
        let finalData = data.filter((waiver) => {
          return waiver.customerId !== null;
        });
        const finalDataAfterSort = finalData.sort(
          (a, b) => b.MainSigner - a.MainSigner
        );

        const finalDataWithCustomFields = finalDataAfterSort.map((waiver) => ({
          ...waiver,
          customField: documentCustomFields,
        }));
        this.props.allFuction.setState({ waiver: finalDataWithCustomFields });
        if (finalData.length > 1) {
          this.setState({
            selectSigner: !this.state.selectSigner,
          });
        } else {
          this.props.closeModal();
        }
      }
    };
    const selectSignFor = (index) => {
      let data = [...this.props.waiver];
      if (!data[index].MainSigner) {
        data[index]["IsSigned"] = !data[index]["IsSigned"];
        this.setState({ data });
        this.props.allFuction.setState({ data });
      }
    };
    const onChangeSelectSigner = (index, event) => {
      if(index === 0) {
        this.setState({isDisabled: true})
      }
      let data = [...this.props.waiver];
      let finalArr = data.map((obj) => {
        return { ...obj, MainSigner: false, IsSigned: false };
      });
      finalArr[index].MainSigner = !finalArr[index].MainSigner;
      finalArr[index].IsSigned = !finalArr[index].IsSigned;
      this.props.allFuction.setState({ waiver: finalArr });
    };
    const removeData = () => {
      const data = [];
      let ob = {
        customerId: null,
        email: this.props.waiver[1].email,
        phoneNumber: "1",
        firstName: "",
        IsSigned: true,
        lastName: "",
        year: "",
        month: "",
        date: "",
        minor: false,
        MainSigner: true,
      };
      data.push(ob);
      this.props.allFuction.setState({
        waiver: data,
      });
      this.props.closeModal();
      if (this.props.isSignedWidget) {
        this.props.getItemDocument();
      } else {
        this.props.getDocument();
      }
    };

    return (
      <Modal
        open={this.props.openModal}
        onClose={removeData}
        closeIcon
        closeOnDimmerClick={false}
        size={"tiny"}
      >
        <>
          {!this.state.selectSigner ? (
            <div className="existingWaiver">
              <Modal.Header className="signerModalHeader">
                The email is registered to the following, please confirm which
                is yours or select none and continue to create a new account.
              </Modal.Header>

              {waiverArr.map((waiver, index) => (
                <div className="radioSigner">
                  {!waiver.minor ? (
                    <>
                      {waiver.firstName && (
                        <Radio
                          className="custom-radio-btn"
                          label={`${waiver.firstName} ${waiver.lastName}`}
                          name="Minor"
                          onClick={(event) =>
                            onChangeSelectSigner(index, event)
                          }
                          value={waiver.MainSigner}
                          checked={waiver.MainSigner === true}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {waiver.firstName && (
                        <div className="minorCustumer">
                          {`${waiver.firstName} ${waiver.lastName}`}
                          <span className="minorWviour">
                            {` *Minor Account - CANNOT be main signer`}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <>
              <div className="main-content existingWaiver">
                <div className="headerSignFor">
                  Select all accounts to sign for
                </div>
                {waiverArr.map((waiver, index) => (
                  <>
                    <div
                      className={`selectSignFor ${
                        waiver.IsSigned ? "Active" : ""
                      }`}
                      onClick={() => selectSignFor(index)}
                    >
                      <div className="waiverName">
                        {`${waiver.firstName} ${waiver.lastName}`}
                        {waiver.minor && (
                          <>
                            <span className="show-Minor">-Minor</span>
                          </>
                        )}
                      </div>
                      <div className="recent-signature">
                        Most recent signature of current document.
                      </div>
                      <div className="signature-showdate">
                        {waiver.SignedDate}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          )}

          <div className="continueWithSigner">
            <Button
              type="submit"
              className="continue-Waiver-Data"
              onClick={selectSigner}
              // disabled={isAllMinorsTrueExceptNone(this.state.isDisabled)}
            >
              Continue
            </Button>
          </div>
        </>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangeSignerModal)
);
