import React, { Component } from "react";
import { commonFunctions } from "../../shared/functional/global-import";
import { Grid, List, Image } from "semantic-ui-react";

class HeaderMenu extends Component {
  onMoreClick(e, { product }) {
    const { allFunction } = this.props;
    if (
      product.businessCategoryType === 1 &&
      allFunction.state.startDate !== "" &&
      allFunction.state.EndDate !== "" &&
      allFunction.state.startTime !== "" &&
      allFunction.state.endTime !== ""
    ) {
      allFunction.setState({ categorySelected: "" });
      allFunction.setState({
        items: true,
        banner: true,
        ProductDetailPage: false,
      });
      this.props.actions.apiCall({
        urls: ["GETCATEGORIESFORBUSINESS"],
        method: "GET",
        data: {
          businessId: allFunction.state.businessId,
          BusinessLocationId: product.businessLocationId,
          startDate: allFunction.state.startDate,
          EndDate: allFunction.state.EndDate,
          startTime: allFunction.state.startTime,
          endTime: allFunction.state.endTime,
          ParentId: product.parentId,
          BusinessCategoryType: product.businessCategoryType,
          PageNo: 1,
          PageSize: 100,
        },
        onSuccess: (response) => {
          allFunction.props.actions.storeParentId(null);
          allFunction.setState({
            items: true,
            locatoion: false,
            nocategory: false,
            locations: response[0].businessLocationId,
            bookNowRightButton: true,
            BannerImageMenu:this.props.BannerImageMenu,
            bookNowViewfunction: false,
            isMuiltiplyLocationView: false,
          });
          allFunction.setState({
            allProducts: response,
            businessCategoryType: response[0].businessCategoryType,
            bookNowRightButton: true,
            bookNowViewfunction: false,
            isMuiltiplyLocationView: false,
          });
          if (response.length === 0) {
            allFunction.setState({ nocategory: true });
          }
        },
      });
    } else {
      allFunction.setState({ categorySelected: "" });
      allFunction.setState({
        items: true,
        banner: true,
        ProductDetailPage: false,
      });
      allFunction.props.actions.apiCall({
        urls: ["GETCATEGORIESFORBUSINESS"],
        method: "GET",
        data: {
          businessId: allFunction.state.businessId,
          BusinessLocationId: product.businessLocationId,
          ParentId: product.parentId,
          BusinessCategoryType: product.businessCategoryType,
          PageNo: 1,
          PageSize: 100,
        },
        onSuccess: (response) => {
          allFunction.props.actions.storeParentId(null);
          allFunction.setState({
            items: true,
            locatoion: false,
            nocategory: false,
            locations: response[0].businessLocationId,
            bookNowRightButton: true,
            BannerImageMenu:this.props.BannerImageMenu,
            bookNowViewfunction: false,
            isMuiltiplyLocationView: false,
          });
          allFunction.setState({
            allProducts: response,
            businessCategoryType: response[0].businessCategoryType,
            bookNowRightButton: true,
            bookNowViewfunction: false,
            isMuiltiplyLocationView: false,
          });
          if (response.length === 0) {
            allFunction.setState({ nocategory: true });
          }
        },
      });
    }
  }
  render() {
    const { allCategories, categorySelected, isLocationsHeader } = this.props;
    return (
      <List horizontal className="navbar">
        {allCategories.map((allcategorie) => {
          let products =
            allcategorie.Categories === null ? false : allcategorie.Categories;
          return (
            <List.Item>
              <span
                onClick={() =>
                  this.props.onHandleCategoryClick(allcategorie.CodeName)
                }
              >
                {allcategorie.catregoryCodeName === "Season Passes"
                  ? "Passes"
                  : allcategorie.catregoryCodeName}
              </span>
              <div
                className={`dropdownMenu ${
                  categorySelected === allcategorie.CodeName ? "show" : ""
                }`}
              >
                {products && (
                  <Grid columns="4" textAlign="center">
                    {products.map((product, index) => {
                       
                      if (index <= 2) {
                        let Tags =
                          JSON.parse(product.Tags) === null
                            ? false
                            : JSON.parse(product.Tags);
                        return (
                          <Grid.Column
                            onClick={() => this.props.showItem(product)}
                          >
                            <div className="dropdownMenuInner">
                              {product.image === "NullImage.png" ? null : (
                                <Image
                                  src={commonFunctions.concatenateImageWithAPIUrl(
                                    product.image
                                  )}
                                  fluid
                                />
                              )}

                              <div className="overTitle">
                                <p>{product.productName}</p>
                              </div>
                              <div class="productCaption">
                                <b className="tags"></b>
                                {Tags &&
                                  Tags.map((item) => {
                                    return <b>{`${item}, `}</b>;
                                  })}
                              </div>
                            </div>
                          </Grid.Column>
                        );
                      } else {
                        return (
                          <Grid.Column
                            className="More"
                            onClick={(e) => this.onMoreClick(e, { product })}
                          >
                            <div className="dropdownMenuInner">
                              <Image fluid />
                              <div className="overTitle">
                                <p>More</p>
                              </div>
                            </div>
                          </Grid.Column>
                        );
                      }
                    })}
                  </Grid>
                )}
              </div>
            </List.Item>
          );
        })}
        <>
          {this.props.insideWordpress ? (
            <List.Item onClick={() => this.props.showItems("location")}>
              Locations
            </List.Item>
          ) : (
            <>
              {isLocationsHeader ? (
                <List.Item onClick={() => this.props.showItems("location")}>
                  Locations
                </List.Item>
              ) : null}
            </>
          )}
        </>
      </List>
    );
  }
}
export default HeaderMenu;
