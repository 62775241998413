import React, { Component } from "react";
import { Button, Modal, Grid, Header, Icon } from "semantic-ui-react";
import {
  env,
  connect,
  bindActionCreators,
  actions,
  withRouter,
} from "../../../../functional/global-import";
class OrderPlacedModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      time: "",
      dateTime: "",
      datesRange: "",
      onClickContniue: false,
    };
  }

  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };
  onHandleWordpressClick = () => {
                                   
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
      this.props.onHandleBackToHome();
    }, 5000);
  };
  onHandleClick = () => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
      this.props.history.push(`${env.PUBLIC_URL}`);
      window.location.reload();
    }, 5000);
  };

  render() {
    const { insideWordpress } = this.props;
    return (
      <Modal
        open={this.props.openModal}
        closeOnDimmerClick={false}
        onClose={this.props.onClose}
        size={"mini"}
      >
        <Modal.Content>
          <Modal.Description className="orderPlaced">
            <Grid textAlign="center">
              <Grid.Column width={16}>
                <Icon name="check circle" className="orderPlaceSuccess" />
                <Header as="h2">Thank you for your order.</Header>
                <p>A confirmation email has been </p>
                <p>sent with a receipt attached.</p>
              </Grid.Column>
              <Grid.Column width={16}>
                {insideWordpress === true ? (
                  <Button
                    className="primaryBtn"
                    onClick={this.onHandleWordpressClick}
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? "Loading..." : "Continue"}
                   
                  </Button>
                ) : (
                  <Button
                    className="primaryBtn"
                    onClick={this.onHandleClick}
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? "Loading..." : "Continue"}
                  </Button>
                )}
              </Grid.Column>
            </Grid>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderPlacedModal)
);
