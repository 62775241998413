import React, { Component } from "react";
import {
  Grid,
  Icon,
  Input,
  Select,
  Header,
  Divider,
  Button,
  Table,
  GridColumn,
  Radio,
  Form,
  List,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import moment from "moment";
import ScrollIntoView from "react-scroll-into-view";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../shared/functional/global-import";
import RentalRangePicker from "./rental-range-picker";
import RentalDatePicker from "./rental-date-picker";

const handleResponsive = (setNumberOfMonth) => {
  let width = document.querySelector(".tp-calendar").clientWidth;
  if (width > 900) {
    setNumberOfMonth(3);
  } else if (width < 900 && width > 580) {
    setNumberOfMonth(2);
  } else if (width < 580) {
    setNumberOfMonth(1);
  }
};

const Day = ({ day }) => {
  return (
    <>
      <p className="date">{day.format("DD")}</p>
      <p className="date">7</p>
    </>
  );
};

class RentalDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Duration: null,
      count: 0,
      number: 0,
      retailTotalprice: "",
      availableProductIds: [],
      currentTime: null,
      startDate: "",
      EndDate: "",
      startTime: "",
      endTime: "",
      flatFee: [],
      selectedFlatFee: null,
      errorMessage: "",
      selectedTime: "",
      slotBasedDuration: [],
      slotPriceOptions: [],
      slotDetails: [],
      nonAvalableDAtes: [],
      isRentalFetching: false,
      isAllDatesDisabled: null,
      hasRendered: false,
      customDurationForm: {
        selectedCustomDurationId: null,
        selectedCustomDurationSlots: null,
      },

      startTimeSlot: null,
      startDateHour: "",
      startDateMinute: "",
      startDateAM: "",
      durationId: "",
      durationTime: "",
      totalProductCount: "",
      isShowAvailable: null,
      firstMissingDate: "",
      showSelectedBlackout: null,
      productAmount: "",
      rangeStartDate: "",
      rangeEndDate: "",
      firstAvailableDate: null,
      avaiablitytwentyHours: 24,
      largestNumber: null,
      availableDatePicker: false,
      durationRate: [],
      loading: false,
      isAvailable: false,
    };
  }
  componentDidMount = () => {
    this.getFlatFeePrice();
  };
  onChange = (date) => {
           
    this.setState({ loading: true });
    const { allFuction } = this.props;
    const { customDurationForm, availableDatePicker } = this.state;
    const startDate = availableDatePicker ? date[0] : date?.from;
    const endDate = availableDatePicker ? date[0]: date?.to
    this.setState({
      selectedTime: allFuction.state.selectedTime,
      rangeStartDate: startDate,
      rangeEndDate: endDate,
      loading: false,
    });
    this.props.allFuction.setState({
      ...allFuction.state,
      startDateRange: startDate,
      endDateRange: endDate,
      selectedTime: "",
      availableProductCount: 0,
      count: 0,
      addToCArtOrNot: false,
      totalprice: 0,
      totalPriceToShow: 0,
      loading: false,
    });
    this.setState({
      startDate: allFuction.state.startDateRange,
      EndDate: allFuction.state.endDateRange,
      errorMessage: "",
    });
    customDurationForm.selectedCustomDurationSlots = null;
    customDurationForm.selectedCustomDurationId = null;
    this.state.slotDetails = [];
    const { activityTimeSlots } = this.props;
    const { slotPriceOptions } = this.state;
    if (!activityTimeSlots?.timeForProduct && !slotPriceOptions) {
      let fromDateSelected = startDate !== "";
      let endDateValue = date.to === "" ? startDate :endDate ;
      debugger
      if (fromDateSelected) {
        this.onAutoSelectingDuration(startDate, endDateValue);
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { state } = prevProps.allFuction;
    if (
      state?.rate?.length > 0 &&
      state?.number === 0 &&
      state.durationId === 0 &&
      this.state?.Duration &&
      state.startDateRange !== "" &&
      state.endDateRange !== ""
    ) {
      this.onAutoSelectingDuration(state.startDateRange, state.endDateRange);
    }
    if (
      this.state.flatFee &&
      this.state.flatFee.length > 0 &&
      state.durationId === 0 &&
      state?.number === 0 &&
      this.state?.Duration &&
      state.startDateRange !== "" &&
      state.endDateRange !== ""
    ) {
      this.onAutoSelectingDuration(state.startDateRange, state.endDateRange);
    }
  }

  onAutoSelectingDuration = (fromDateSelected, toDateSelected) => {
    const { number } = this.props?.allFuction?.state;
    const rate = this.props.allFuction.state.rate;
    const allRates = rate && rate.length > 0 && rate[0];
    let durationOptionValue = null;
    const date1 = new Date(fromDateSelected);
    const date2 = new Date(toDateSelected);
    const diffTime = Math.abs(date2 - date1);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    diffDays = diffDays === 0 ? 1 : diffDays;
           
    if (diffDays === 1) {
      durationOptionValue =
        allRates.hour !== null
          ? "Hour"
          : allRates.day !== null
          ? "Day"
          : "Week (7Days per week)";
    }

    if (diffDays > 1) {
      durationOptionValue =
        allRates.day !== null ? "Day" : "Week (7Days per week)";
    }
    if (number !== diffDays) {
      this.props.allFuction.setState({
        number: diffDays,
        addToCArtOrNot: false,
        availableProductCount: 0,
        count: 0,
      });
    }
    let durationOptions = this.state?.Duration;
    let selctionOfTimeDuration =
      durationOptions &&
      durationOptions.filter((item) => {
        return item.text === durationOptionValue;
      });
    if (durationOptions) {
      if (selctionOfTimeDuration.length > 0) {
        this.props.allFuction.setState({
          durationId:
            selctionOfTimeDuration.length > 0
              ? selctionOfTimeDuration[0].value
              : null,
          addToCArtOrNot: false,
          availableProductCount: 0,
          count: 0,
        });
      }
    }
    this.setState({ forUpsate: "2" });
  };

  // Count Handler for Decreament
  countHandleChangeMinusRental = () => {
    const { minRentalItems, count } = this.props.allFuction.state;
    if (count >= 1) {
      let number =
        minRentalItems === count ? count - minRentalItems : count - 1;
      let amountPrice = this.props.allFuction.state.ItemPrice;
      let retailTotalprice = JSON.parse((amountPrice * number).toFixed(2));
      this.setState({ count: number, retailTotalprice: retailTotalprice });
      this.props.allFuction.setState({
        count: number,
        totalprice: retailTotalprice,
        totalPriceToShow: retailTotalprice,
      });
      if (number <= 0) {
        this.props.allFuction.setState({ addToCArtOrNot: false });
      }
    }
  };

  // Count Handler for Increament
  countHandleChangePlusRental = () => {
    const { minRentalItems, maxRentalItems } = this.props.allFuction.state;
    const count = this.props.allFuction.state.count;
    const { flatFee } = this.state;
    const { allFuction } = this.props;
    if (
      flatFee &&
      flatFee.length > 0 &&
      allFuction.state.rentalFlatFeeId === null
    ) {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please select Flat fee",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else {
      if (
        count <
        (maxRentalItems
          ? maxRentalItems
          : this.props.allFuction.state.availableProductCount)
      ) {
        let number =
          minRentalItems > 0 && count === 0 ? minRentalItems : count + 1;
        let amountPrice = this.props.allFuction.state.ItemPrice;
        let retailTotalprice = JSON.parse((amountPrice * number).toFixed(2));
        this.setState({ count: number, retailTotalprice: retailTotalprice });
        this.props.allFuction.setState({
          count: number,
          totalprice: retailTotalprice,
          totalPriceToShow: retailTotalprice,
        });
        if (number > 0) {
          this.props.allFuction.setState({ addToCArtOrNot: true });
        }
        if (minRentalItems > 0 && count === 0) {
          this.props.actions.showNotification(
            {
              title: "success",
              message: `This Rental required min ${minRentalItems} items.`,
              position: "br",
              autoDismiss: commonFunctions.notificationTime(),
            },
            "success"
          );
        }
      } else {
        this.props.actions.showNotification(
          {
            title: "Warning",
            message: "There are no more available items that can be added. ",
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      }
    }
  };
  handleSelectTime = (e) => {
    this.setState({ selectedTime: e.target.name }, () =>
      this.GetProductAvailabilityCount(e.target.name, this.state.currentTime)
    );
  };
  // Count for Rental Availability
  GetProductAvailabilityCount = (missingDate) => {
    const { availableTime, allFuction } = this.props;
    const missingDateAvaible =
      this.props.allFuction.state.startDateRange === ""
        ? missingDate
        : moment(
            this.props.allFuction.state.startDateRange,
            "YYYY-MM-DD"
          ).format("MM/DD/YYYY");
    let startTimeRange =
      availableTime.startTimeHour +
      ":" +
      availableTime.startTimeMinute +
      " " +
      availableTime.startTimeAm;
    let endTimeRange =
      availableTime.endTimeHour +
      ":" +
      availableTime.endTimeMinute +
      " " +
      availableTime.endTimeAm;
    let sDate = missingDateAvaible.concat(" ", startTimeRange);
    var startDateTime = new Date(sDate);
    if (missingDateAvaible !== "") {
      if (
        startDateTime > this.props.currentDateFromApi === undefined
          ? new Date()
          : this.props.currentDateFromApi
      ) {
        this.props.actions.apiCall({
          urls: ["GETRENTALAVAILABILITY"],
          method: "GET",
          data: {
            InWidget: true,
            ProductId: this.props.addItem.productId,
            StartDate: this.props.allFuction.state.startDateRange,
            EndDate:
              allFuction.state.value === "custom"
                ? allFuction.state.endDateRange
                : null,
            StartTime: startTimeRange,
            EndTime: allFuction.state.value === "custom" ? endTimeRange : null,
            DurationTime:
              allFuction.state.value === "duration"
                ? allFuction.state.number
                : null,
            DurationId:
              allFuction.state.value === "duration"
                ? allFuction.state.durationId
                : null,
          },
          onSuccess: (response) => {
            if (
              response[0].availableProductCount === 0 &&
              response[0].responseCode === 200
            ) {
              this.setState({
                errorMessage:
                  "No Product is available for the Selected Date/Time.",
              });
              this.props.allFuction.setState({
                availableProductCount: response[0].availableProductCount,
              });
            } else if (
              response[0].availableProductCount !== 0 &&
              response[0].responseCode === 200 &&
              this.state.flatFee.length > 0 &&
              allFuction.state.rentalFlatFeeId !== null &&
              this.props.allFuction.state.durationId !== 0 &&
              allFuction.state.number !== 0
            ) {
              this.props.allFuction.setState(
                {
                  count: 0,
                  availableProductCount: response[0].availableProductCount,
                  endDateRange: response[0].endDate,
                  endTimeRange: response[0].endTime,
                  totalPriceToShow: 0,
                  totalprice: 0,
                },
                () => {
                  this.countHandleChangePlusRental();
                }
              );
            } else if (
              response[0].availableProductCount !== 0 &&
              response[0].responseCode === 200 &&
              this.state.flatFee.length === 0 &&
              this.props.allFuction.state.durationId !== 0 &&
              allFuction.state.number !== 0
            ) {
              this.props.allFuction.setState(
                {
                  count: 0,
                  availableProductCount: response[0].availableProductCount,
                  endDateRange: response[0].endDate,
                  endTimeRange: response[0].endTime,
                  totalPriceToShow: response[0].amount,
                  ItemPrice: response[0].amount,
                  totalprice: response[0].amount,
                },
                () => {
                  if (
                    response[0].availableProductCount > 0 &&
                    response[0].responseCode === 200
                  ) {
                    this.countHandleChangePlusRental();
                  }
                }
              );
            } else if (
              response[0].availableProductCount !== 0 &&
              response[0].responseCode === 200 &&
              this.props.allFuction.state.durationId === 0 &&
              allFuction.state.number === 0
            ) {
              this.props.actions.showNotification(
                {
                  title: "Warning",
                  message: "Please select end time duration.",
                  position: "br",
                  autoDismiss: commonFunctions.notificationTime(),
                },
                "error"
              );
            } else if (
              response[0].availableProductCount !== 0 &&
              response[0].responseCode === 200 &&
              allFuction.state.number === 0
            ) {
              this.props.actions.showNotification(
                {
                  title: "Warning",
                  message: "Please select end time duration.",
                  position: "br",
                  autoDismiss: commonFunctions.notificationTime(),
                },
                "error"
              );
            } else if (
              response[0].responseCode === 500 &&
              this.props.allFuction.state.durationId === 0 &&
              allFuction.state.number === 0
            ) {
              this.props.actions.showNotification(
                {
                  title: "Please select end time duration",
                  message: response[0].responseMessage,
                  position: "br",
                  autoDismiss: commonFunctions.notificationTime(),
                },
                "error"
              );
            } else if (response[0].responseCode === 500) {
              this.props.actions.showNotification(
                {
                  title: "Warning",
                  message: response[0].responseMessage,
                  position: "br",
                  autoDismiss: commonFunctions.notificationTime(),
                },
                "error"
              );
            } else if (
              response[0].availableProductCount !== 0 &&
              response[0].responseCode === 200 &&
              this.state.flatFee.length > 0 &&
              allFuction.state.rentalFlatFeeId === null &&
              this.props.allFuction.state.durationId !== 0
            ) {
              this.props.actions.showNotification(
                {
                  title: "Warning",
                  message: "Please select Flat fee",
                  position: "br",
                  autoDismiss: commonFunctions.notificationTime(),
                },
                "error"
              );
            } else {
              this.props.allFuction.setState({
                availableProductCount: response[0].availableProductCount,
              });
            }
          },
        });
      } else {
        this.props.actions.showNotification(
          {
            title: "Warning",
            message:
              "Item booking time is less then current time, please change the item booking time.",
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      }
    } else {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please select a date range First(Start Date & End Date)",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };
  // Count for Rental Availability
  GetProductAvailability = (missingDate) => {
    const { allFuction } = this.props;
    let { customDurationForm } = this.state;
    let startTimeRange = customDurationForm.selectedCustomDurationSlots; //03:25 PM
    this.props.allFuction.setState({
      startTimeRange:
        moment(startTimeRange, "hh:mm a").format("hh") +
        ":" +
        moment(startTimeRange, "hh:mm a").format("mm") +
        " " +
        moment(startTimeRange, "hh:mm a").format("A"),
      startDateRange: missingDate,
    });

    if (
      missingDate !== "" &&
      // this.props.allFuction.state.endDateRange !== "" &&
      this.props.allFuction.state.selectedTime !== ""
    ) {
      this.props.actions.apiCall({
        urls: ["GETRENTALAVAILABILITY"],
        method: "GET",
        data: {
          InWidget: true,
          ProductId: this.props.addItem.productId,
          StartDate: this.props.allFuction.state.startDateRange,
          EndDate: null,
          StartTime: this.props.allFuction.state.selectedTime,
          EndTime: null,
          DurationTime: this.state.durationTime,
          DurationId: this.state.durationId,
        },
        onSuccess: (response) => {
          if (
            response[0].availableProductCount !== 0 &&
            response[0].responseCode === 200 &&
            this.state.flatFee.length === 0 &&
            this.props.allFuction.state.durationId === 0 &&
            allFuction.state.number === 0
          ) {
            this.props.allFuction.setState({
              count: 0,
              availableProductCount: response[0].availableProductCount,
              endDateRange: response[0].endDate,
              endTimeRange: response[0].endTime,
              productAmount: response[0].amount === 0 ? "" : response[0].amount,
              totalProductCount: response[0].availableProductCount,
            });
          }
          this.setState(
            {
              productAmount: response[0].amount === 0 ? "" : response[0].amount,
              totalProductCount: response[0].availableProductCount,
              isShowAvailable: response[0].isShowAvailableItem,
            },
            () => {
              if (
                response[0].availableProductCount > 0 &&
                response[0].responseCode === 200
              ) {
                this.slotBasedPlusCount();
              }
            }
          );

          if (response[0].responseCode != 200) {
            this.props.actions.showNotification(
              {
                title: "Warning",
                message: response[0].responseMessage,
                position: "br",
                autoDismiss: commonFunctions.notificationTime(),
              },
              "error"
            );
          }
        },
      });
    } else {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message:
            "Please select a date range/Duration/Slot First(Start Date & End Date)",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };

  getGlobalCode = () => {
    this.props.actions.apiCall({
      urls: ["GLOBALCODE"],
      method: "GET",
      data: {
        CategoryName: "Duration",
        GlobalCodeId: -1,
      },
      onSuccess: (response) => {
        if (this.state.flatFee.length <= 0) {
          let timeFrameDropdownOption = [];
          let timeFrameDropdownOptionMinute = [];
          let timeFrameDropdownOptionHour = [];
          let timeFrameDropdownOptionDay = [];
          let timeFrameDropdownOptionWeek = [];
          let timeFrameDropdownOptionMonth = [];
          let timeFrameDropdownOptionYear = [];
          const rateArray = this.props.allFuction.state.rate;
          response.map((singleData) => {
            if (rateArray[0]?.minute && "Minute" === singleData.codeName) {
              timeFrameDropdownOptionMinute.push({
                text: singleData.codeName,
                value: singleData.globalCodeId,
              });
            } else if (rateArray[0]?.hour && "Hour" === singleData.codeName) {
              timeFrameDropdownOptionHour.push({
                text: singleData.codeName,
                value: singleData.globalCodeId,
              });
            } else if (rateArray[0]?.day && "Day" === singleData.codeName) {
              timeFrameDropdownOptionDay.push({
                text: singleData.codeName,
                value: singleData.globalCodeId,
              });
            } else if (
              rateArray[0]?.week &&
              "Week (7Days per week)" === singleData.codeName
            ) {
              timeFrameDropdownOptionWeek.push({
                text: singleData.codeName,
                value: singleData.globalCodeId,
              });
            }
            return {
              text: singleData.codeName,
              value: singleData.globalCodeId,
            };
          });
          timeFrameDropdownOption = [
            ...timeFrameDropdownOptionMinute,
            ...timeFrameDropdownOptionHour,
            ...timeFrameDropdownOptionDay,
            ...timeFrameDropdownOptionWeek,
            ...timeFrameDropdownOptionMonth,
            ...timeFrameDropdownOptionYear,
          ];
          this.setState({ Duration: timeFrameDropdownOption });
        } else {
          let timeFrameDropdownOption = [];
          let timeFrameDropdownOptionHour = [];
          let timeFrameDropdownOptionDay = [];
          let timeFrameDropdownOptionMonth = [];
          let timeFrameDropdownOptionYear = [];
          const globalCode = response.map((singleData) => {
            if (singleData.codeName === "Hour") {
              timeFrameDropdownOptionHour.push({
                text: singleData.codeName,
                value: singleData.globalCodeId,
              });
            } else if (singleData.codeName === "Day") {
              timeFrameDropdownOptionDay.push({
                text: singleData.codeName,
                value: singleData.globalCodeId,
              });
            }
            return {
              text: singleData.codeName,
              value: singleData.globalCodeId,
            };
          });
          timeFrameDropdownOption = [
            ...timeFrameDropdownOptionHour,
            ...timeFrameDropdownOptionDay,
            ...timeFrameDropdownOptionMonth,
            ...timeFrameDropdownOptionYear,
          ];
          this.setState({ Duration: timeFrameDropdownOption });
        }
      },
      showNotification: false,
    });
  };

  handleChange = (e, { value }) => {
    this.setState({ value: value });
  };

  onRangeDateInScreen = (window) => {
    const initialMonth = this.props.allFuction.state.availabilityStartDate;
    let initialMoment = moment(initialMonth).format("YYYY-MM-DD");
    this.setState({ isRentalFetching: true });
    this.props.actions.apiCall({
      urls: ["GETPRODUCTAVAILABILTY"],
      method: "GET",
      data: {
        StartDate: window.start ? window.start : initialMoment,
        ProductId: this.props.addItem.productId,
        businessCategoryType: this.props.addItem.businessCategoryType,
      },
      onSuccess: (response) => {
        let nonAvalableDAte =
          response &&
          response[0] &&
          response[0].notAvailableDays &&
          JSON.parse(response[0].notAvailableDays).map((post) => {
            return post.dates;
          });
        this.setState({
          nonAvalableDAtes: nonAvalableDAte === null ? [] : nonAvalableDAte,
          isRentalFetching: false,
        });
        this.props.allFuction.setState({
          startDateRange: moment(this.props?.currentDateFromApi).format(
            "YYYY-MM-DD"
          ),
        });
        if (nonAvalableDAte) {
          var currentdate =
            this.props.currentDateFromApi === undefined
              ? new Date()
              : this.props.currentDateFromApi;
          let aa = moment(currentdate).format("YYYY-MM-DD");
          if (nonAvalableDAte.indexOf(aa) > -1) {
            this.props.allFuction.setState({
              startDateRange: "",
              endDateRange: "",
            });
          } else {
            var currdate =
              this.props.currentDateFromApi === undefined
                ? new Date()
                : this.props.currentDateFromApi;
            let aa = [];
            aa.push(moment(currdate).format("YYYY-MM-DD"));
            this.setState({ currentTime: aa });
          }
        } else {
          this.props.allFuction.setState({
            startDateRange: moment(this.props?.currentDateFromApi).format(
              "YYYY-MM-DD"
            ),
            endDateRange: moment(this.props?.currentDateFromApi).format(
              "YYYY-MM-DD"
            ),
          });
        }
        const today = moment().format("YYYY-MM-DD");
        if (nonAvalableDAte === null && !this.state.hasRendered) {
          this.setState({
            firstAvailableDate: window.start < today ? today : window.start,
            hasRendered: true,
          });
        }

        if (!this.state.hasRendered) {
          let firstAvailableDate =
            commonFunctions.findFirstMissingDate(nonAvalableDAte);
          if (firstAvailableDate) {
            this.setState({
              firstAvailableDate:
                firstAvailableDate <= initialMoment
                  ? initialMoment
                  : firstAvailableDate,
              hasRendered: true,
            });
          }
        }
      },
    });
  };

  // Get Flat fee data
  getFlatFeePrice = () => {
    this.props.actions.apiCall({
      urls: ["GETORDERRENTALPRICE"],
      method: "GET",
      data: {
        productId: this.props.addItem.productId,
        inWebsiteWidget: true,
      },
      onSuccess: (response) => {
               
        /**************** New Code 24 Duration Regrading ******************/
        const slotBasedDurationData = response[0]?.slotBasedPrice
          ? JSON.parse(response[0].slotBasedPrice)
          : JSON.parse(response[0].specialPrice);
        const durationRate =
          response[0]?.rate === "[]"
            ? null
            : JSON.parse(response[0]?.rate)?.[0];
        const slotBasedDurationJson = slotBasedDurationData.map(
              (user) => user.CustomDurationTitleInHours
            );
        let durationRateValue = false;
        let specialValue = false;
        const numbers = slotBasedDurationJson?.map(Number);
        const largestNumber = numbers.length > 0 ? Math.max(...numbers) : false;
        if (durationRate) {
          const specialPrice = largestNumber === 0 ? false : largestNumber < this.state.avaiablitytwentyHours;
          durationRateValue = durationRate.day === null && durationRate.week === null;
          specialValue = slotBasedDurationData.length > 0 ? specialPrice : durationRateValue;
          this.setState({
            durationRateValue: durationRateValue,
            availableDatePicker: specialValue,
          },
          () => this.getGlobalCode());
        }
        else if(slotBasedDurationJson){
          const largestNumberValue = largestNumber === false ? false : largestNumber < this.state.avaiablitytwentyHours;
          this.setState(
            {
              slotBasedDuration: slotBasedDurationData,
              flatFee:
                response && response?.length && JSON.parse(response[0]?.price),
              largestNumber: largestNumber,
              availableDatePicker: largestNumberValue,
            },
            () => this.getGlobalCode()
          );
  
        }
        
        let slotPriceOptionData =
          response &&
          response?.length &&
          response[0]?.slotBasedPrice &&
          JSON.parse(response[0].slotBasedPrice).map((slotObj) => {
            return {
              text: slotObj.CustomDurationTitle,
              value: slotObj.CustomDurationId,
              id: slotObj.CustomDurationId,
            };
          });
        this.setState({ slotPriceOptions: slotPriceOptionData });
      },
    });
  };

  onHandleChangeSlot = (e, { value, type, data }) => {
    var customDurationForm = commonFunctions.onHandleChange(
      e,
      { value, type, data },
      this.state.customDurationForm
    );
    customDurationForm.selectedCustomDurationSlots = null;
    this.setState(
      { customDurationForm, slotDetails: [], selectedDurationDetails: null },
      () => this.onGetProductSlots()
    );
    this.setState({ availableProductCount: "", productAmount: "" });
  };

  onHandleChangeSlotSelected = (e, { value, type, data }) => {
    var customDurationForm = commonFunctions.onHandleChange(
      e,
      { value, type, data },
      this.state.customDurationForm
    );
    this.setState({ customDurationForm });
    this.props.allFuction.setState({
      selectedTime: customDurationForm.selectedCustomDurationSlots,
    });
    this.setState({ availableProductCount: "", productAmount: "" });
  };

  onGetProductSlots = () => {
    const { startDateRange } = this.props.allFuction.state;
    const { rangeEndDate } = this.state;
    this.props.actions.apiCall({
      urls: ["GETPRODUCTDURATIONSLOTS"],
      method: "GET",
      data: {
        CustomDurationId:
          this.state.customDurationForm.selectedCustomDurationId,
        StartDate: startDateRange,
        EndDate: rangeEndDate ? rangeEndDate : startDateRange,
      },
      onSuccess: ([response]) => {
        if (!response.slots) {
          this.props.actions.showNotification(
            {
              title: "Warning",
              message: `No time slots available.`,
              position: "br",
              autoDismiss: commonFunctions.notificationTime(),
            },
            "error"
          );
        } else {
          let slotDetails = JSON.parse(response.slots).map((slotObj) => {
            return {
              text: slotObj,
              value: slotObj,
            };
          });
          this.setState({ slotDetails, selectedDurationDetails: response });
          this.setState({
            durationId: response.durationId,
            durationTime: response.durationTime,
          });
          this.props.allFuction.setState({
            rentalSlotes: JSON.parse(response.slots).length > 0,
          });
        }
      },
    });
  };

  slotBasedMinusCount = () => {
    const count = this.props.allFuction.state.count;
    const { minRentalItems } = this.props.allFuction.state;
    const { productAmount } = this.state;
    if (count >= 1) {
      let number =
        minRentalItems === count ? count - minRentalItems : count - 1;
      let slotPrice = productAmount * number;
      this.props.allFuction.setState({
        count: number,
        availableProductCount: 0,
        totalprice: slotPrice,
        totalPriceToShow: slotPrice,
        ItemPrice: productAmount,
      });
      if (number <= 0) {
        this.props.allFuction.setState({ addToCArtOrNot: false });
      }
    }
    this.setState({ forUpsate: "2" });
  };

  slotBasedPlusCount = () => {
    const count = this.props.allFuction.state.count;
    const { totalProductCount, productAmount } = this.state;
    const { minRentalItems, maxRentalItems } = this.props.allFuction.state;
    if (count < (maxRentalItems ? maxRentalItems : totalProductCount)) {
      let number =
        minRentalItems > 0 && count === 0 ? minRentalItems : count + 1;
      let slotPrice = productAmount * number;
      this.props.allFuction.setState({
        count: number,
        addToCArtOrNot: true,
        totalprice: slotPrice,
        totalPriceToShow: slotPrice,
        ItemPrice: productAmount,
        availableProductCount: 0,
      });
      if (minRentalItems > 0 && count === 0) {
        this.props.actions.showNotification(
          {
            title: "success",
            message: `This Rental required min ${minRentalItems} items.`,
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "success"
        );
      }
    } else {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "There are no more available items that can be added. ",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
    this.setState({ forUpsate: "2" });
  };

  countHandleChange = () => {
    const { number } = this.props.allFuction.state;
    this.props.allFuction.setState({
      number: number + 1,
      addToCArtOrNot: false,
      availableProductCount: 0,
      count: 0,
    });
    this.setState({ forUpsate: "2" });
  };

  countHandleChangeMinus = () => {
    const { number } = this.props.allFuction.state;
    if (number >= 1) {
      this.props.allFuction.setState({
        number: number - 1,
        addToCArtOrNot: false,
        availableProductCount: 0,
        count: 0,
      });
    }
    this.setState({ forUpsate: "2" });
  };

  handleChange = (e, { value }) => {
    this.props.allFuction.setState({ value: value });
    this.setState({ forUpsate: "2" });
  };

  onHandleChange = (e, { value }) => {
    this.props.allFuction.setState({
      durationId: value,
      addToCArtOrNot: false,
      availableProductCount: 0,
      count: 0,
    });
    this.setState({ forUpsate: "2" });
  };

  handleChangeFlat = (item) => {
    const { allFuction } = this.props;
    let startDateData = this.props.allFuction.state.startDateRange;
    // let endDateData = this.props.allFuction.state.endDateRange;
    if (startDateData === undefined) {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please select a date range (Start Date & End Date)",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
    this.setState({ inWebsiteWidget: true });
    this.props.allFuction.setState({
      ...allFuction.state,
      count: 0,
      totalPriceToShow: item.ItemPrice,
      ItemPrice: item.ItemPrice,
      totalprice: item.ItemPrice,
      rentalFlatFeeId: item.RentalPricingId,
    });
  };

  render() {
    const {
      flatFee,
      errorMessage,
      slotBasedDuration,
      slotPriceOptions,
      customDurationForm,
      slotDetails,
      totalProductCount,
      productAmount,
      isShowAvailable,
    } = this.state;
    const rentalFlatFeeId = this.props.allFuction.state.rentalFlatFeeId;
    const hideSection =
      this.props.allFuction.state.availableProductCount === 0 ? false : true;
    const {
      allFuction,
      CustomTimefirst,
      CustomTimetwo,
      CustomTimethree,
      availableTime,
      selectedTime,
      activityTimeSlots,
    } = this.props;

    const { isShowAvailableItem } = this.props;
    let rate = this.props.allFuction.state.rate;
    let specialPrice = this.props.allFuction.state.specialPrice;
    const initialMonth = allFuction.state.availabilityStartDate;
    let initialMoment = moment(initialMonth).format("YYYY-MM-DD");
    const today = moment().format("YYYY-MM-DD");
    if (initialMoment < today) {
      initialMoment = today;
    }
    const selctedDateshow = this.state.rangeStartDate
      ? this.state.rangeStartDate
      : this.state.firstAvailableDate;
    this.props.allFuction.setState({
      startDateRange: selctedDateshow,
    });
    let startTimeRange =
      availableTime.startTimeHour +
      ":" +
      availableTime.startTimeMinute +
      " " +
      availableTime.startTimeAm;
    const selctedDates = moment(selctedDateshow).format("MM-DD-YYYY");
    const selctedEndDates = moment(
      this.props.allFuction.state.endDateRange
    ).format("MM-DD-YYYY");
    return (
      <>
        <Grid>
          <Grid.Column width={16}>
            <Header as="h5">Select Date Range</Header>
          </Grid.Column>
          <Grid.Column width={16} className="activityDate">
            <Grid>
              <Grid.Column width={16} class="calanderWidget">
                {this.state.loading ? (
                  <Dimmer active inverted>
                    <Loader />
                  </Dimmer>
                ) : (
                  <>
                    {this.state.availableDatePicker ? (
                      <RentalDatePicker
                        allFuction={this}
                        fullstate={this.state}
                        initialMonthAndYear={
                          initialMoment < today ? today : initialMoment
                        }
                        selctedDateshow={selctedDateshow}
                        handleResponsive={handleResponsive}
                        today={today}
                        Day={Day}
                      />
                    ) : (
                      <RentalRangePicker
                        allFuction={this}
                        fullstate={this.state}
                        initialMonthAndYear={
                          initialMoment < today ? today : initialMoment
                        }
                        selctedDateshow={selctedDateshow}
                        handleResponsive={handleResponsive}
                        today={today}
                        Day={Day}
                      />
                    )}
                  </>
                )}
              </Grid.Column>

              {flatFee && flatFee.length > 0 && (
                <Grid.Column width={16}>
                  <Grid>
                    <Grid.Column
                      computer={4}
                      tablet={4}
                      mobile={16}
                    ></Grid.Column>
                    <Grid.Column computer={12} tablet={12} mobile={16}>
                      <Header>Select Flat Fee</Header>
                      <div className="commonTable">
                        <Table unstackable singleLine>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell></Table.HeaderCell>
                              <Table.HeaderCell>Title</Table.HeaderCell>
                              <Table.HeaderCell>Price</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {flatFee &&
                              flatFee.map((fee) => {
                                return (
                                  <Table.Row>
                                    <Table.Cell>
                                      <Radio
                                        className="custom-radio-btn"
                                        id={fee.RentalPricingId}
                                        checked={
                                          rentalFlatFeeId ===
                                          fee.RentalPricingId
                                        }
                                        name="radioGroup2"
                                        onChange={() =>
                                          this.handleChangeFlat(fee)
                                        }
                                      ></Radio>
                                    </Table.Cell>
                                    <Table.Cell>
                                      {fee.RentalPricingTitle}
                                    </Table.Cell>
                                    <Table.Cell>$ {fee.ItemPrice}</Table.Cell>
                                  </Table.Row>
                                );
                              })}
                          </Table.Body>
                        </Table>
                      </div>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              )}

              {rate && rate.length > 0 && (
                <Grid.Column computer={4} mobile={16}>
                  <>
                    <Header as="h5" className="Duration-Price">
                      Duration Price
                    </Header>
                    {rate[0].minute && (
                      <List>
                        <List.Item>
                          {commonFunctions.isNumberInteger(rate[0].minute)} /
                          minute
                        </List.Item>
                      </List>
                    )}
                    {rate[0].hour && (
                      <List>
                        <List.Item>
                          {commonFunctions.isNumberInteger(rate[0].hour)} / hour
                        </List.Item>
                      </List>
                    )}
                    {rate[0].day && (
                      <List>
                        <List.Item>
                          {commonFunctions.isNumberInteger(rate[0].day)} / day
                        </List.Item>
                      </List>
                    )}
                    {rate[0].week && (
                      <List>
                        <List.Item>
                          {commonFunctions.isNumberInteger(rate[0].week)} / week
                        </List.Item>
                      </List>
                    )}
                  </>
                </Grid.Column>
              )}
              {specialPrice && specialPrice.length > 0 && (
                <Grid.Column computer={4} mobile={16}>
                  <>
                    <Header as="h5" className="Special-Price">
                      Special Price
                    </Header>

                    {specialPrice.map((specailPriceObj) => {
                      return (
                        <List>
                          <List.Item>
                            {commonFunctions.isNumberInteger(
                              specailPriceObj.ItemPrice
                            )}{" "}
                            / {specailPriceObj.Duration}
                          </List.Item>
                        </List>
                      );
                    })}
                  </>
                </Grid.Column>
              )}
              {activityTimeSlots?.timeForProduct && (
                <Grid.Column width={16}>
                  <Header as="h5">Available Time Slots</Header>
                </Grid.Column>
              )}
              {activityTimeSlots &&
                activityTimeSlots?.timeForProduct !== null &&
                Object.keys(activityTimeSlots?.timeForProduct).length !== 0 &&
                activityTimeSlots?.timeForProduct.split(",").map((item) => {
                  return (
                    <>
                      <Button
                        name={item}
                        basic
                        className="select-btn"
                        active={selectedTime.indexOf(item) > -1 ? true : false}
                        onClick={(e) => allFuction.GetAvailabilityCountPrice(e)}
                      >
                        {item}
                      </Button>
                    </>
                  );
                })}
              {!activityTimeSlots?.timeForProduct && !slotPriceOptions && (
                <Grid.Column computer={12} tablet={12} mobile={16}>
                  <Grid>
                    <Grid.Column width={16}>
                      <Header as="h5" className="maroon-color">
                        Start Time{" "}
                      </Header>
                    </Grid.Column>
                    <Grid.Column computer={4} mobile={5}>
                      <Select
                        options={CustomTimefirst}
                        value={availableTime.startTimeHour}
                        name="startTimeHour"
                        fluid
                        onChange={allFuction.onTimeHandleChange}
                      />
                    </Grid.Column>
                    <Grid.Column computer={4} mobile={5}>
                      <Select
                        options={CustomTimetwo}
                        value={availableTime.startTimeMinute}
                        name="startTimeMinute"
                        fluid
                        onChange={allFuction.onTimeHandleChange}
                      />
                    </Grid.Column>
                    <Grid.Column computer={4} mobile={6}>
                      <Select
                        options={CustomTimethree}
                        value={availableTime.startTimeAm}
                        name="startTimeAm"
                        fluid
                        onChange={allFuction.onTimeHandleChange}
                      />
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column width={16}>
                      <Header as="h5" className="maroon-color">
                        Total Duration
                      </Header>
                    </Grid.Column>
                    <Grid.Column computer={6} mobile={7}>
                      <Form.Field className="add-numbers">
                        <Icon
                          name="minus"
                          className="orange-button"
                          onClick={() => this.countHandleChangeMinus()}
                        ></Icon>
                        <Input
                          readOnly
                          value={this.props.allFuction.state.number}
                          disabled={
                            this.props.allFuction.state.value !== "duration"
                          }
                        />
                        <Icon
                          name="plus"
                          className="orange-button plus-btn"
                          onClick={() => this.countHandleChange()}
                        ></Icon>
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={6} mobile={9}>
                      <Form.Select
                        fluid
                        type="select"
                        name="rentalOrderDropdown"
                        placeholder="Time Period"
                        options={this.state.Duration}
                        categoryType="Duration"
                        value={this.props.allFuction.state.durationId}
                        onChange={this.onHandleChange}
                      />
                    </Grid.Column>

                    {!activityTimeSlots?.timeForProduct && (
                      <Grid.Column computer={12} mobile={16} textAlign="center">
                        <Button
                          onClick={(e) =>
                            this.GetProductAvailabilityCount(e, selctedDateshow)
                          }
                          className="primaryBtn"
                        >
                          Check Availability
                        </Button>
                      </Grid.Column>
                    )}

                    {this.props.allFuction.state.availableProductCount === 0 &&
                    errorMessage ? (
                      <GridColumn width={16}>
                        <Header as="h4">
                          {" "}
                          Total Available : <span className="itemValue">0</span>
                        </Header>
                        <Header as="h4"> {this.state.errorMessage}</Header>
                      </GridColumn>
                    ) : (
                      ""
                    )}
                    {hideSection && (
                      <Grid.Column width={16}>
                        {isShowAvailableItem ? (
                          <>
                            <Header as="h4" className="durationSubCaption">
                              {" "}
                              Total Available :{" "}
                              <span className="itemValue">
                                {
                                  this.props.allFuction.state
                                    .availableProductCount
                                }
                              </span>
                            </Header>
                          </>
                        ) : null}
                        <Header as="h4" className="durationSubCaption">
                          Choose Total :
                          <span className="itemValue">
                            <div className="add-numbers">
                              <Icon
                                name="minus"
                                className="orange-button"
                                onClick={this.countHandleChangeMinusRental}
                              ></Icon>
                              <Input
                                value={this.props.allFuction.state.count}
                                name="numberOfTickets"
                                readOnly
                                fluid
                              />
                              <Icon
                                name="plus"
                                className="orange-button plus-btn"
                                onClick={this.countHandleChangePlusRental}
                              ></Icon>
                            </div>
                          </span>
                        </Header>
                        <Header
                          as="h4"
                          id="rentalDiscription"
                          className="pt-15 mb-3 durationSubCaption"
                        >
                          <b className="secondary">Total : </b>
                          <span className="itemValue">
                            <b>
                              {commonFunctions.isNumberInteger(
                                this.props.allFuction.state.totalprice
                              )}
                            </b>
                          </span>
                        </Header>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                          <p>
                            <b>
                              Selected Time & Date:{" "}
                              <span>
                                <span>{startTimeRange}</span>{" "}
                                <span>{selctedDates}</span> to{" "}
                                <span>
                                  {this.props.allFuction.state.endTimeRange}
                                </span>{" "}
                                <span>{selctedEndDates}</span>
                              </span>
                            </b>
                          </p>
                        </Grid.Column>
                      </Grid.Column>
                    )}
                  </Grid>
                </Grid.Column>
              )}
              {slotBasedDuration && slotBasedDuration.length > 0 && (
                <Grid.Column width={16}>
                  <Header as="h2">Select Duration</Header>
                  <Form.Select
                    className="slotDurationDropDown"
                    fluid
                    options={slotPriceOptions}
                    data="selectedCustomDurationId"
                    value={customDurationForm.selectedCustomDurationId}
                    type="select"
                    placeholder="Select Duration"
                    onChange={this.onHandleChangeSlot}
                  />
                </Grid.Column>
              )}

              <Grid.Column width={16}>
                {slotDetails && slotDetails.length > 0 && (
                  <Header as="h2">Select Time</Header>
                )}

                {slotDetails && slotDetails.length > 0 && (
                  <Form.Select
                    fluid
                    options={slotDetails}
                    data="selectedCustomDurationSlots"
                    value={customDurationForm.selectedCustomDurationSlots}
                    type="select"
                    placeholder="Select Time"
                    onChange={this.onHandleChangeSlotSelected}
                  />
                )}
              </Grid.Column>

              {slotDetails && slotDetails.length > 0 && (
                <GridColumn computer={12} mobile={16} textAlign="center">
                  <Button
                    className="primaryBtn"
                    onClick={() => this.GetProductAvailability(selctedDateshow)}
                  >
                    Check Availability
                  </Button>
                </GridColumn>
              )}
              <Divider hidden />
              {productAmount &&
                totalProductCount !== 0 &&
                customDurationForm.selectedCustomDurationSlots && (
                  <Grid>
                    {isShowAvailable ? (
                      <>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                          <Header as="h4" className="durationSubCaption">
                            Available Items :{" "}
                            <span className="itemValue">
                              {totalProductCount}
                            </span>
                          </Header>
                        </Grid.Column>
                      </>
                    ) : null}
                    <Grid.Column
                      mobile={16}
                      tablet={16}
                      computer={16}
                      verticalAlign="middle"
                    >
                      <Header as="h4" className="durationSubCaption">
                        Choose Items :
                        <span className="itemValue">
                          <Form.Field className="add-numbers">
                            <Icon
                              name="minus"
                              className="orange-button"
                              onClick={this.slotBasedMinusCount}
                            />
                            <Form.Input
                              value={this.props.allFuction.state.count}
                              fluid
                            />
                            <Icon
                              name="plus"
                              className="orange-button plus-btn"
                              onClick={this.slotBasedPlusCount}
                            />
                          </Form.Field>
                        </span>
                      </Header>
                    </Grid.Column>

                    {this.props.allFuction.state.count > 0 && (
                      <Grid.Column mobile={16} tablet={16} computer={16}>
                        <Header as="h4" className="durationSubCaption">
                          Total :
                          <span className="itemValue">
                            {this.props.allFuction.state.count > 0 && (
                              <>
                                {commonFunctions.isNumberInteger(
                                  this.props.allFuction.state.totalprice
                                )}
                              </>
                            )}
                          </span>
                        </Header>
                      </Grid.Column>
                    )}

                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <p>
                        <b>
                          Selected Time & Date:{" "}
                          <span>
                            <span>
                              {customDurationForm.selectedCustomDurationSlots}
                            </span>{" "}
                            <span>{selctedDates}</span> to{" "}
                            <span>
                              {this.props.allFuction.state.endTimeRange}
                            </span>{" "}
                            <span>{selctedEndDates}</span>{" "}
                          </span>
                        </b>
                      </p>
                    </Grid.Column>
                  </Grid>
                )}

              {totalProductCount === 0 &&
                this.props.allFuction.state.availableProductCount === 0 && (
                  <Grid>
                    <Divider hidden />
                    <GridColumn computer={16} mobile={16} textAlign="center">
                      <Header as="h3">No Item Available.</Header>
                    </GridColumn>
                  </Grid>
                )}
            </Grid>
          </Grid.Column>
          {this.props.allFuction.state.rentalInfo && (
            <Grid.Column width={16}>
              <ScrollIntoView smooth={true} id="rentalDiscription">
                <Header as="h4">Item Description : </Header>
                <p> {this.props.allFuction.state.rentalInfo}</p>
              </ScrollIntoView>
            </Grid.Column>
          )}
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RentalDetail);
