import React, { Component } from "react";
import {
  Container,
  Grid,
  Button,
  Form,
  Loader,
  Icon,
  Dimmer,
} from "semantic-ui-react";
import SignaturePad from "react-signature-canvas";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../shared/functional/global-import";
class Sign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isApiLoading: false,
      item: false,
      isSignLoading: false,
      CheckOut: true,
      disableFineshed: true,
    };
  }

  handleChevronRight = () => {
    const { number, documentList } = this.props.allFuction.state;
    const count = number + 1;
    if (count <= documentList.length) {
      const nextDocument = documentList[count - 1];

      this.props.allFuction.setState({
        ImageFile: nextDocument.image,
        documentName: nextDocument.documentName,
        number: count,
      });
    }
  };

  handleChevronLeft = () => {
    const { number, documentList } = this.props.allFuction.state;
    const count = number - 1;

    if (count >= 1) {
      const previousDocument = documentList[count - 1];

      this.props.allFuction.setState({
        ImageFile: previousDocument.image,
        documentName: previousDocument.documentName,
        number: count,
      });
    }
  };

  clearWaiverSignature = () => {
    this.canvasRef.clear();
    this.setState({ CheckOut: true });
  };
  onSubmitDetails = (e) => {
             
    if (!this.props.allFuction.state.IsPolicy) {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Check-box selection is required",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else if (this.canvasRef.isEmpty()) {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please sign the document first",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    } else if (!this.props.waiverProps.inSideWidget) {
      const queryParams = new URLSearchParams(window.location.search);
      const documentid = queryParams.get("documentid");
      const url = window.location.search;
      const locationId = url.split("&")[1];
      const actionPerformed = this.props.waiver[0].email;
      this.setState({ isSignLoading: true });
      this.props.actions.apiCall({
        urls: ["ADDWAIVERCUSTOMERS"],
        method: "POST",
        data: {
          DocumentId: documentid,
          BusinessLocationId: locationId,
          ImageFile: this.props.ImageFile,
          ActionPerformedBy: actionPerformed,
          Waivers: this.props.waiver,
          SignatureImage: this.canvasRef.toDataURL(),
          IsSigned: true,
          IsPolicy: this.props.IsPolicy,
        },
        onSuccess: () => {
          this.props.allFuction.setState({
            showThankYouPage: true,
            signatureImage: this.canvasRef.toDataURL(),
          });
          this.setState({ disableFineshed: false });
        },
        onFailure: () => {
          this.setState({ isSignLoading: false });
        },
        showNotification: true,
      });
    } else {
      this.props.waiverProps.allData.setState({
        isIndividualProductLoader: true,
      });
      this.props.waiverProps.allData.setState({
        waiver: this.props.waiver,
        signatureImage: this.canvasRef.toDataURL(),
        IsPolicy: this.props.IsPolicy,
      });
      setTimeout(() => {
        if (e.target.id === "CHECKOUTNOW") {
          this.props.waiverProps.onFinish.addTocart(e);
        } else {
          this.props.waiverProps.onFinish.addTocart("ADDTOCART");
        }
      }, 1000);
    }
  };

  componentDidMount() {
    this.props.allFuction.setState({
      IsPolicy: false,
    });
  }

  render() {
    const addAcknolage = () => {
      this.props.allFuction.setState({
        IsPolicy: !this.props.allFuction.state.IsPolicy,
      });
    };
    const backToWaiverscreen = () => {
      this.setState({ isApiLoading: !this.state.isApiLoading });
      this.props.openSignerPage();
      setTimeout(() => {
        this.props.allFuction.setState({
          IsPolicy: false,
        });
      }, 1000);
    };
    return (
      <>
        {this.state.isSignLoading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        <Container className="">
          <Grid>
            <Grid.Column>
              <div className="doumentName">
                {this.props.allFuction.state.documentName}
              </div>
              <label className="dateOfBirthLable">
                Please read the document below. All language from the waiver
                will apply to all persons being signed for and the main signer.
              </label>
              {this.props.documentList.length > 0 && (
                <Grid.Column width={16}>
                  <Icon
                    name="chevron left"
                    className="orange-color"
                    onClick={this.handleChevronLeft}
                  />
                  {this.props.number}/ {this.props.documentList.length}
                  <Icon
                    name="chevron right"
                    className="orange-color"
                    onClick={this.handleChevronRight}
                  />
                </Grid.Column>
              )}

              {!this.props.waiverProps.inSideWidget ? (
                <>
                  <Grid.Column width={16}>
                    <div
                      style={{
                        padding: "10px 0",
                        width: "100%",
                        height: "400px",
                      }}
                    >
                      <iframe
                        src={`https://docs.google.com/gview?url=${encodeURIComponent(
                          commonFunctions.concatenateImageWithAPIUrl(
                            this.props.allFuction.state.ImageFile
                          )
                        )}&embedded=true`}
                        
                        style={{ width: "100%", height: "100%" }}
                        frameborder="0"
                      ></iframe>
                    </div>
                  </Grid.Column>
                </>
              ) : (
                <>
                  <Grid.Column width={16}>
                    <div
                      style={{
                        padding: "35px",
                        width: "100%",
                        height: "380px",
                      }}
                    >
                      <iframe
                        id="iframeid"
                        style={{ overflow: "auto", padding: "2px" }}
                        width="100%"
                        height="100%"
                        src={commonFunctions.concatenateImageWithAPIUrl(
                          this.props.allFuction.state.ImageFile
                        )}
                      ></iframe>
                    </div>
                  </Grid.Column>
                </>
              )}
              <div className="insideMultipleChoice">
                <Form>
                  <Form.Field>
                    <Form.Checkbox
                      className="custom-checkbox waiver-checkbox"
                      label=""
                      checked={this.props.allFuction.state.IsPolicy}
                      value={this.props.allFuction.state.IsPolicy}
                      onClick={addAcknolage}
                    />
                    <p>
                      By signing below, I acknowledge that I have read and
                      understood this waiver and certify that all personal
                      information provided is correct.
                      <span className="astrickSign">*</span>
                    </p>
                  </Form.Field>
                </Form>
              </div>
            </Grid.Column>
          </Grid>
          <div className="document-canvasRef">
            <Grid>
              <Grid.Column>
                <SignaturePad
                  canvasProps={{ width: 1100, height: 180 }}
                  ref={(ref) => (this.canvasRef = ref)}
                />
              </Grid.Column>
            </Grid>
          </div>
          <Form>
            <Form.Group widths="equal" className="checkOutActionBtn">
              <Form.Field>
                {/* <Button
                  type="submit"
                  className="Finish-Waiver-Data"
                  onClick={saveSignDoc}>Sign</Button> */}
              </Form.Field>

              <Form.Field>
                <div className="text-right group-buttons">
                  <Button
                    type="submit"
                    className="back-Waiver-page"
                    onClick={backToWaiverscreen}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    className="back-Waiver-page"
                    onClick={this.clearWaiverSignature}
                  >
                    Clear
                  </Button>
                  <Button
                    type="submit"
                    disabled={!this.state.disableFineshed}
                    className={
                      this.props.waiverProps.inSideWidget
                        ? "primaryBtn"
                        : "Finish-Waiver-Data"
                    }
                    onClick={this.onSubmitDetails}
                  >
                    {this.props.waiverProps.inSideWidget
                      ? "Add to cart"
                      : "Finished"}
                  </Button>
                  {this.props.waiverProps.inSideWidget && (
                    <>
                      <Button
                        onClick={this.onSubmitDetails}
                        className={"primaryBtn"}
                        disabled={!this.state.disableFineshed}
                        id="CHECKOUTNOW"
                      >
                        Checkout Now
                      </Button>
                    </>
                  )}
                </div>
                {this.props.waiverProps.inSideWidget && (
                  <div className="checkOutButtonwaiver">
                    <span className="astrickSign">*</span>For multiple items,
                    click "Add to Cart". "Checkout Now" only applies to current
                    item .
                  </div>
                )}
              </Form.Field>
            </Form.Group>
          </Form>
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sign);
