import React, { Component } from "react";
import { Button, Modal, Grid, Dimmer, Loader } from "semantic-ui-react";

class DeleteCouponModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { api } = this.props;
    return (
      <Modal
        open={this.props.openModal}
        onClose={this.props.onclose}
        size={"mini"}
        closeIcon
      >
        <Modal.Content>
          <Modal.Description>
            {api.isApiLoading && (
              <Dimmer active inverted>
                <Loader />
              </Dimmer>
            )}
            <Grid textAlign="center">
              <Grid.Column width={16}>
                Are you sure you want to delete this coupon?
              </Grid.Column>
              <Grid.Column width={16}>
                <Button className="primaryBtn" onClick={this.props.onclose}>
                  No
                </Button>
                <Button
                  className="primaryBtn"
                  onClick={this.props.deleteSessonPassOrCopon}
                >
                  Yes
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default DeleteCouponModal;
