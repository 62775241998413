import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { Image, Header, Button, Container } from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  withRouter,
  Notifications,
} from "../../shared/functional/global-import";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

class LocationViewFolder extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { multipltLocationData, allFunction,bussinessPathName } = this.props;
                            
    return (
      <div  className="locationViewModalWrapper">
              <div className="locationViewModal">
                <Container>
                  <>
                    <Swiper
                        spaceBetween={15}
                        navigation={multipltLocationData.length > 4 ? true : false}
                        autoplay
                        className="desktopSwiper"
                        centerInsufficientSlides
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log("slide change")}
                        breakpoints={{
                          768: {
                            slidesPerView: 3,
                          },// when window width is >= 768px
                          1199: {
                            slidesPerView: 4,
                          },
                        }}>
                        {multipltLocationData &&
                          multipltLocationData.map(
                            (multipleLocationView, index) => {
                              const imageShowValue = multipleLocationView.image === "NullImage.png"
                              return (
                                <SwiperSlide className="noImageThumbnail">
                                <>
                                 
                                  <div className="orderPlacedItem">
                                    <div className="cmn-shad minlength">
                                    {multipleLocationView.image === "NullImage.png" ? null : (
                                      <Image
                                        src={commonFunctions.concatenateImageWithAPIUrl(
                                          multipleLocationView.image
                                        )}
                                        fluid
                                      />
                                    )}
                                      <div className={imageShowValue ? "locatioshowCaptionText locationActionBtn":"locationActionBtn"}>
                                        <Header as="h5">
                                          {multipleLocationView.displayName}
                                        </Header>
                                        <Button
                                          className="primaryBtn"
                                          style={{marginTop: "10px"}}
                                          fluid
                                          onClick={() =>
                                            allFunction.onClickLocationView(
                                              multipleLocationView ,bussinessPathName
                                            )
                                          }
                                        >
                                          View
                                        </Button>

                                      </div>
                                    </div>
                                  </div>
                                </>
                                </SwiperSlide>
                              );
                            }
                          )}
                 
                    </Swiper>
                    <div className="mobView">
                    {multipltLocationData &&
                          multipltLocationData.map(
                            (multipleLocationView, index) => {
                              return (
                                <>           
                                  <div className="orderPlacedItem">
                                    <div className="cmn-shad">
                                    {multipleLocationView.image === "NullImage.png" ? null : (
                                      <Image
                                        src={commonFunctions.concatenateImageWithAPIUrl(
                                          multipleLocationView.image
                                        )}
                                        fluid
                                      />
                                    )}
                                      <div className="locationActionBtn">
                                        <Header as="h5">
                                          {multipleLocationView.displayName}
                                        </Header>
                                        <Button
                                          className="primaryBtn"
                                          style={{marginTop: "10px"}}
                                          fluid
                                          onClick={() =>
                                            allFunction.onClickLocationView(
                                              multipleLocationView ,bussinessPathName
                                            )
                                          }
                                        >
                                          View
                                        </Button>

                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          )}
                    </div>
                  </>
                </Container>
              </div>
      </div>
      
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      StoreBussinessUrl: bindActionCreators(
        actions.StoreBussinessUrl,
        dispatch
      ),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      DeleteAllItemDetail: bindActionCreators(
        actions.DeleteAllItemDetail,
        dispatch
      ),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      storeThemeColor: bindActionCreators(actions.storeThemeColor, dispatch),
      storeParentId: bindActionCreators(actions.storeParentId, dispatch),
      SHOWHEADERWEBSITE: bindActionCreators(
        actions.SHOWHEADERWEBSITE,
        dispatch
      ),
      storeBussinessLocationId: bindActionCreators(
        actions.storeBussinessLocationId,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LocationViewFolder)
);
