import React, { Component } from "react";
import { Grid, List, Image, Header, Button } from "semantic-ui-react";
import { commonFunctions } from "../../shared/functional/global-import";

class LocationForHeader extends Component {
  render() {
    const { LOCATIONFORBUSINESS } = this.props;
    return (
      <>
        {LOCATIONFORBUSINESS &&
          LOCATIONFORBUSINESS.map((locationbusines) => {
            return (
              <Grid.Column width={4}>
                <div className="itemsBox">
                  {locationbusines.image === "NullImage.png" ? null : (
                    <Image
                      src={commonFunctions.concatenateImageWithAPIUrl(
                        locationbusines.image
                      )}
                      fluid
                    />
                  )}

                  <div className="location-detail">
                    <Header as="h5">{locationbusines.displayName}</Header>
                    <List>
                      <List.Item
                        icon="time"
                        content={
                          <p>{locationbusines.businessLocationTiming}</p>
                        }
                      />
                      <List.Item
                        icon="mobile alternate"
                        className="rentit-mobileNumber"
                        content={locationbusines.mobileNo}
                      />
                      <List.Item
                        icon="map marker alternate"
                        content={<p>{locationbusines.businessAddress}</p>}
                      />
                      <List.Item
                        icon="globe"
                        content={<p>{locationbusines.timeZone}</p>}
                      />
                    </List>
                    <Button
                      className="primaryBtn"
                      onClick={() => this.props.openInventory(locationbusines)}
                      fluid
                    >
                      Open Inventory
                    </Button>
                  </div>
                </div>
              </Grid.Column>
            );
          })}
      </>
    );
  }
}
export default LocationForHeader;
