import React, { Component } from "react";
import {
  Button,
  Modal,
  Grid,
  Image,
  Popup,
  Header,
  Step,
  Container,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import StepOne from "../../../../../pages/home/step-one";
import SessonPass from "../../../../../pages/home/sesson-pass";
import StepTwo from "../../../../../pages/home/step-two";
import RetailBulkOne from "../../../../../pages/home/retail-bulk-one";
import RentalDetail from "../../../../../pages/home/rental-detail";
import RetailTrackable from "../../../../../pages/home/retail-trackable";
import StepThree from "../../../../../pages/home/step-three";
import StepFour from "../../../../../pages/home/step-four";
import StepFive from "../../../../../pages/home/step-five";
import StepSix from "../../../../../pages/home/step-six";
import Waiver from "../../../../../pages/wavier";
import { Swiper, SwiperSlide } from "swiper/react";
import moment from "moment";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
  env,
} from "../../../../../shared/functional/global-import";
// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";
// install Swiper modules

SwiperCore.use([Navigation]);
const CustomTimefirst = [
  { key: "1", value: "1", text: "01" },
  { key: "2", value: "2", text: "02" },
  { key: "3", value: "3", text: "03" },
  { key: "4", value: "4", text: "04" },
  { key: "5", value: "5", text: "05" },
  { key: "6", value: "6", text: "06" },
  { key: "7", value: "7", text: "07" },
  { key: "8", value: "8", text: "08" },
  { key: "9", value: "9", text: "09" },
  { key: "10", value: "10", text: "10" },
  { key: "11", value: "11", text: "11" },
  { key: "12", value: "12", text: "12" },
];
const CustomTimetwo = [
  { key: "00", value: "00", text: "00" },
  { key: "05", value: "05", text: "05" },
  { key: "10", value: "10", text: "10" },
  { key: "15", value: "15", text: "15" },
  { key: "20", value: "20", text: "20" },
  { key: "25", value: "25", text: "25" },
  { key: "30", value: "30", text: "30" },
  { key: "35", value: "35", text: "35" },
  { key: "40", value: "40", text: "40" },
  { key: "45", value: "45", text: "45" },
  { key: "50", value: "50", text: "50" },
  { key: "55", value: "55", text: "55" },
];
const CustomTimethree = [
  { key: "AM", value: "AM", text: "AM" },
  { key: "PM", value: "PM", text: "PM" },
];
class AddCartModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsPolicy: false,
      seasonPassCustomFields: [],
      sessoinPass: [],
      documentId: [],
      documentList: [],
      waiver: [],
      signatureImage: null,
      isIndividualProductLoader: false,
      number: 0,
      durationId: 0,
      value: "duration",
      onPrevious: false,
      activeStep: this.props.addItem?.isLocationTabVisible ? 0 : 1,

      rentalFlatFeeId: null,
      availableProductCount: 0,
      bundleProduct: false,
      AssociateAddOnsPrice: 0,
      CustomQuestions: [],
      selectedTime: "",
      locationDetails: [],
      addOns: [],
      tickets: [],
      Requirements: [],
      currentTime: null,
      rentalSlotes: false,
      startDateRange: "",
      endDateRange: "",
      startTimeRange: "7:00 am",
      endTimeRange: "9:00 am",
      totalTaxAmount: 0,
      availabilityStartDate: "",
      bundle: true,
      addToCArtOrNot: false,
      policyDefaultValue: false,
      count: 0,
      slotCount: 0,
      Itemimages: [],
      bundleItems: [],
      addItem: [],
      activeItem: "",
      amount1: 0,
      deposit: 0,
      isFetching: false,
      activityPricingId: {
        ActivityPricingId: -1,
        ActivityId: this.props?.addItem?.productId,
        BusinessId: this.props?.addItem?.businessId,
        BusinessLocationId: this.props?.addItem?.businessLocationId,
        PageNo: 1,
        PageSize: 100,
      },
      availableTime: {
        startTimeHour: "9",
        startTimeMinute: "00",
        startTimeAm: "AM",
        endTimeHour: "9",
        endTimeMinute: "00",
        endTimeAm: "AM",
      },
      retailTrackableItems: [],
      itemCount: "",
      retailItems: [],
      retailType: {
        retailId: "",
        itemDescription: "",
        addOn: null,
        briefDetails: "",
      },
      totalPriceToShow: null,
      currentDateFromApi: undefined,
      allDataRecevied: null,
      checkoutNow: false,
      isFormInValid: false,
      ItemShowInretail: null,
      customeShowInretail: [],
      seassonFalse: false,
      blackoutEndDate: "",
      blackOutStartDate: "",
      availAbilityEndDate: "",
      loaderActivityData: false,
      selectedDate: null,
    };

    this.validator = commonFunctions.initializeSimpleValidator();
    this.activityValidations = commonFunctions.initializeSimpleValidator();
    this.sessoinPassValidations = commonFunctions.initializeSimpleValidator();
    this.scrollToTop = this.scrollToTop.bind(this);
  }
  componentDidMount() {
    this.onGetBusinesdate();
    const { addItem } = this.props;

    if (addItem.shopStartTime) {
      const [hours, amPM] = addItem.shopStartTime?.split(":00");
      this.setState({
        availableTime: {
          startTimeHour: hours,
          startTimeMinute: "00",
          startTimeAm: amPM,
        },
      });
    } else {
      if (addItem) {
        if (!addItem?.isLocationTabVisible) {
          this.setState({ isIndividualProductLoader: true });
          setTimeout(() => this.onNext(1), 1000);
        }
      }
    }
  }

  onCallAllApis = () => {
    this.GetActivityTimeSlots();
    // this.getActivityPrice();
    // this.getNewActivityTimeSlots();
    this.getProductAvailability();
    // this.GETORDERBUNDLEPRICE();
    this.getRetailItemDetail();
    this.getItemDocument();
    if (this.props.addItem.type === "Rental") {
      this.setState({ allDataRecevied: false }, () =>
        this.getOrderRentalPrice()
      );
    }
    this.getRetailTrackableDetail();
    this.setState({ addItem: this.props.addItem });
    this.getItemDetailSummary();
    this.getOrderPassDetail();
  };

  getItemDetailSummary = () => {
    this.props.actions.apiCall({
      urls: ["BUSINESSCATEGORYWEBSITEWIDGET"],
      method: "GET",
      data: {
        ProductId: this.props.addItem.productId,
        BusinessCategoryType: this.props.addItem.businessCategoryType,
      },
      onSuccess: (response) => {
        this.setState({
          maxRentalItems: response[0].maxItemPerOrder,
          minRentalItems: response[0].minItemPerOrder,
          availabilityStartDate: response[0].availAbilityStartDate,
          blackoutEndDate: response[0]?.blackOutEndDate,
          blackOutStartDate: response[0]?.blackOutStartDate,
          availAbilityEndDate: response[0]?.availAbilityEndDate,
        });
        this.setState({
          rentalItemStoke: response[0].quantity,
        });
        if (response[0].customField) {
          let addPeramiters = JSON.parse(response[0].customField).map((v) => ({
            ...v,
            value: "",
          }));
          this.setState({
            seasonPassCustomFields: addPeramiters,
            availabilityStartDate: response[0].availAbilityStartDate,
          });
        }
      },
    });
  };
  onGetBusinesdate = () => {
    this.props.actions.apiCall({
      urls: ["CURRENTDATEFORBUSINESS"],
      method: "GET",
      data: { BusinessLocationId: this.props.addItem.businessLocationId },
      onSuccess: (response) => {
        let aa =
          response &&
          response[0] &&
          response[0].startTime &&
          new Date(response[0].startTime);
        this.setState({ currentDateFromApi: aa }, () => this.onCallAllApis());
      },
    });
  };

  handleSelectTime = (e, currentTime) => {
    this.setState(
      {
        selectedTime: e.target.name,
        addToCArtOrNot: false,
        currentTime: currentTime,
        timeChoosen:
          this.state.currentTime === "" ? currentTime : this.state.currentTime,
      },
      () => this.getActivityTickets(e.target.name, currentTime)
    );
  };

  handleBundleSelectTime = (e) => {
    this.setState({
      selectedTime: e.target.name,
      addToCArtOrNot: false,
      bundleProduct: false,
      timeChoosen: this.state.currentTime,
      count: 0,
      totalprice: 0,
      totalPriceToShow: 0,
    });
  };
  GetAvailabilityCountPrice = (e) => {
    this.setState(
      { selectedTime: e.target.name, addToCArtOrNot: false, count: 0 },
      () => this.getRentalsTickets(e.target.name)
    );
  };

  getRentalsTickets = (selectedDate) => {
    // const selectedTime = allFuction.state.selectedTime;
    let StartTime = this.state.startDateRange.concat(selectedDate);
    let EndTime = this.state.endDateRange.concat(selectedDate);
    if (
      this.state.startDateRange !== undefined &&
      this.state.endDateRange !== undefined
    ) {
      this.props.actions.apiCall({
        urls: ["GETRENTALAVAILABILITY"],
        method: "GET",
        data: {
          InWidget: true,
          ProductId: this.props.addItem.productId,
          StartDate: this.state.startDateRange,
          EndDate: this.state.endDateRange,
          StartTime: StartTime,
          EndTime: EndTime,
        },
        onSuccess: (response) => {
          if (response[0].availableProductCount === 0) {
            this.props.actions.showNotification(
              {
                title: "Warning",
                message: response[0].responseMessage,
                position: "br",
                autoDismiss: commonFunctions.notificationTime(),
              },
              "error"
            );
          } else {
            this.setState({
              availableProductCount: response[0].availableProductCount,
              totalPriceToShow: response[0].amount,
              totalprice: response[0].amount,
              ItemPrice: response[0].amount,
            });
          }
        },
      });
    } else {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please select a date range First(Start Date & End Date)",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };
  // Activity
  getActivityPrice = (timeChoosen, selectedDate) => {
    this.setState({ loaderActivityData: true });
    if (selectedDate !== undefined && selectedDate !== "") {
      let StartDate = selectedDate.concat(" ", timeChoosen);
      this.props.actions.apiCall({
        urls: ["GETAVAILABLEACTIVITYTICKETS"],
        method: "GET",
        data: {
          InWidget: true,
          ActivityId: this.props.addItem.productId,
          BusinessId: this.props.addItem.businessId,
          BusinessLocationId: this.state.addItem.businessLocationId,
          BusinessCategoryType: this.state.addItem.businessCategoryType,
          StartDate: StartDate,
        },
        onSuccess: (response) => {
          var result = JSON.parse(response.availableActivityTickets).map(
            function (el) {
              var o = Object.assign({}, el);
              if (o.customField !== null) {
                o.customField = JSON.parse(o.customField);
                o.customAnswers = [];
                o.customFields = [];
              }
              o.minvalue = el.min;
              o.numberOfTickets = 0;
              return o;
            }
          );
          this.setState({ tickets: result, loaderActivityData: false });
        },
        onFailure: (response) => {
          this.props.actions.showNotification(
            {
              title: "Warning",
              message: response.response.data.responseMessage,
              position: "br",
              autoDismiss: commonFunctions.notificationTime(),
            },
            "error"
          );
          this.setState({ selectedTime: "", loaderActivityData: false });
        },
      });
    } else {
      this.setState({ loaderActivityData: true });
      if (this.state.currentTime !== "") {
        const { availableTime } = this.state;
        let startTimeRange =
          availableTime.startTimeHour +
          ":" +
          availableTime.startTimeMinute +
          availableTime.startTimeAm;
        let activityDate = this.state.currentTime;
        let StartDate = activityDate.concat(" ", startTimeRange);
        this.props.actions.apiCall({
          urls: ["GETAVAILABLEACTIVITYTICKETS"],
          method: "GET",
          data: {
            InWidget: true,
            ActivityId: this.props.addItem.productId,
            BusinessId: this.props.addItem.businessId,
            BusinessLocationId: this.state.addItem.businessLocationId,
            BusinessCategoryType: this.state.addItem.businessCategoryType,
            StartDate: StartDate,
          },
          onSuccess: (response) => {
            var result = JSON.parse(response.availableActivityTickets).map(
              function (el) {
                var o = Object.assign({}, el);
                if (o.customField !== null) {
                  o.customField = JSON.parse(o.customField);
                  o.customField &&
                    o.customField.length > 0 &&
                    o.customField.map((customObjs, index) => {
                      if (index === 0) {
                        customObjs.customFieldStart = true;
                      }
                      if (o.customField.length - 1 === index) {
                        customObjs.isDivderTrue = true;
                      }
                    });
                  o.customAnswers = [];
                  o.customFields = [];
                }
                o.minvalue = el.min;
                o.numberOfTickets = 0;
                return o;
              }
            );
            this.setState({ tickets: result });
          },
          onFailure: (response) => {
            this.props.actions.showNotification(
              {
                title: "Warning",
                message: response.response.data.responseMessage,
                position: "br",
                autoDismiss: commonFunctions.notificationTime(),
              },
              "error"
            );
            this.setState({ loaderActivityData: false });
          },
        });
      } else {
        this.props.actions.showNotification(
          {
            title: "Warning",
            message: "Please select a date first",
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      }
    }
  };

  // Get total tickets from API
  getActivityTickets = (timeChoosen, selectedDate) => {
    this.setState({ isIndividualProductLoader: true });
    let { availableTime } = this.state;
    this.setState({
      startTimeRange:
        availableTime.startTimeHour +
        ":" +
        availableTime.startTimeMinute +
        availableTime.startTimeAm,
      endTimeRange:
        availableTime.endTimeHour +
        ":" +
        availableTime.endTimeMinute +
        availableTime.endTimeAm,
    });

    this.getActivityPrice(timeChoosen, selectedDate);
    if (timeChoosen !== null) {
      this.props.actions.apiCall({
        urls: ["GETACTIVITYTICKETDETAILS"],
        method: "GET",
        data: {
          ProductId: this.props.addItem.productId,
          TimeRangeFrom: timeChoosen,
          StartDate: selectedDate,
        },
        onSuccess: (response) => {
          this.setState({ ticketsTable: true, ticketsDetails: response });
        },
        onFinally: () => {
          this.setState({ isIndividualProductLoader: false });
        },
        onFailure: (response) => {
          this.props.actions.showNotification(
            {
              title: "Warning",
              message: response.response.data.responseMessage,
              position: "br",
              autoDismiss: commonFunctions.notificationTime(),
            },
            "error"
          );
        },
      });
    } else {
      this.setState({ ticketsTable: false });
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please Select booking Date First",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };

  // Get total item count from API
  handleRentalSelectTime = (e) => {
    this.setState({ selectedTime: e.target.name }, () =>
      this.GetProductAvailabilityCount()
    );
  };

  // Count for Rental Availability
  GetProductAvailabilityCount = (e) => {
    const { availableTime } = this.state;

    // const selectedTime = allFuction.state.selectedTime;
    // let startTimeRange =
    //   availableTime.startTimeHour +
    //   ":" +
    //   availableTime.startTimeMinute +
    //   availableTime.startTimeAm;
    let endTimeRange =
      availableTime.endTimeHour +
      ":" +
      availableTime.endTimeMinute +
      availableTime.endTimeAm;
    if (
      this.state.startDateRange !== undefined &&
      this.state.endDateRange !== undefined
    ) {
      this.props.actions.apiCall({
        urls: ["GETRENTALAVAILABILITY"],
        method: "GET",
        data: {
          InWidget: true,
          ProductId: this.props.addItem.productId,
          StartDate: this.state.startDateRange,
          EndDate: this.state.endDateRange,
          StartTime: this.state.selectedTime,
          EndTime: endTimeRange,
        },
        onSuccess: (response) => {
          if (response[0].availableProductCount === 0) {
            this.props.actions.showNotification(
              {
                title: "Warning",
                message: "No Product is available for the Selected Date/Time.",
                position: "br",
                autoDismiss: commonFunctions.notificationTime(),
              },
              "error"
            );
          } else {
            this.setState({
              availableProductCount: response[0].availableProductCount,
            });
          }
        },
      });
    } else {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Please select a date range First(Start Date & End Date)",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };

  onTimeHandleChange = (e, { value, name }) => {
    let { availableTime } = this.state;
    this.setState({
      startTimeRange:
        availableTime.startTimeHour +
        ":" +
        availableTime.startTimeMinute +
        availableTime.startTimeAm,
      endTimeRange:
        availableTime.endTimeHour +
        ":" +
        availableTime.endTimeMinute +
        availableTime.endTimeAm,
    });
    this.setState({
      ...this.state,
      tickets: [],
      availableTime: { ...this.state.availableTime, [name]: value },
      bundleProduct: false,
      availableProductCount: 0,
      count: 0,
      addToCArtOrNot: false,
      totalprice: 0,
      totalPriceToShow: 0,
    });
  };

  // Availability from API
  getProductAvailability = () => {
    if (!this.state.isFetching) {
      return;
    }

    const today = moment().format("MM/DD/YYYY");
    this.setState({ isFetching: true });
    this.props.actions.apiCall({
      urls: ["GETPRODUCTAVAILABILTY"],
      method: "GET",
      data: {
        StartDate: today,
        ProductId: this.props.addItem.productId,
        BusinessCategoryType: this.props.addItem.businessCategoryType,
      },
      onSuccess: (response) => {
        this.setState({ isFetching: false });

        let nonAvalableDAte =
          response &&
          response[0] &&
          response[0].notAvailableDays &&
          JSON.parse(response[0].notAvailableDays).map((post) => {
            return post.dates;
          });
        var currentdate = null;
        if (response[0].notAvailableDays === null) {
          currentdate = this.state.currentDateFromApi;
          let aa = [];
          aa.push(moment(currentdate).format("YYYY-MM-DD"));
          this.setState({ currentAllTime: aa });
        }
        if (nonAvalableDAte) {
          currentdate = this.state.currentDateFromApi;
          let aa = moment(currentdate).format("YYYY-MM-DD");
          if (nonAvalableDAte.indexOf(aa) > -1) {
            this.setState({ currentAllTime: "" });
          } else {
            currentdate = this.state.currentDateFromApi;
            let aa = [];
            aa.push(moment(currentdate).format("YYYY-MM-DD"));
            this.setState({ currentAllTime: aa });
          }
          this.setState({ nonAvalableDAtes: nonAvalableDAte });
          this.onSettingPartialDates();
        } else {
          this.setState({ nonAvalableDAtes: [] });
        }
      },
    });
  };

  showActivitySelectTicket = () => {
    // const { orderState, activity } = this.props;
    // let selectedDate = {
    //   startDate: activity.startDate,
    //   endDate: activity.endDate,
    // };
    // const nextState = orderState.currentOrder.orderDetails.filter(
    //   (item) => item.id === orderState.currentOrderDetails.id
    // );
    if (this.state.currentTime !== undefined) {
      const { bookingSlot } = this.state;
      let data = {
        timeFrameRange: this.props.activityTimeSlots.timeRange,
        startDate: this.state.currentTime,
        timeRangeFrom:
          this.state.selectedTime === ""
            ? bookingSlot.startTimeHour +
              ":" +
              bookingSlot.startTimeMinute +
              bookingSlot.startTimeAm
            : this.state.selectedTime,
        timeRangeTo:
          this.state.selectedTime !== ""
            ? null
            : bookingSlot.endTimeHour +
              ":" +
              bookingSlot.endTimeMinute +
              bookingSlot.endTimeAm,
      };
      this.props.showActivityTicket(data);
    } else {
      this.onShowingerror();
    }
  };

  getItemDocument = () => {
    const dataObj = {
      Products: JSON.stringify([
        {
          ProductId: this.props.addItem.productId,
          BusinessCategoryType: this.props.addItem.businessCategoryType,
        },
      ]),
    };
    this.props.actions.apiCall({
      urls: ["GETORDERPRODUCTDOCUMENT"],
      method: "GET",
      data: dataObj,
      onSuccess: (response) => {
        let documentIds = response.map(({ documentId }) => {
          return { documentId };
        });
        this.setState({
          documentId: documentIds,
          documentList: response,
          documentName: response[0].documentName,
          ImageFile: response[0].image,
        });
      },
    });
  };

  // Get slots from API
  getNewActivityTimeSlots = (time, selectedDate) => {
    if (this.props.addItem.businessCategoryType === 3) {
      //const today = selectedDate;
      const timeConverted = selectedDate;
      let newSelectedDate = "";
      if (selectedDate) {
        newSelectedDate = moment(selectedDate, "YYYY-MM-DD, hh:mmA").format(
          "YYYY/MM/DD"
        );
      }
      this.props.actions.apiCall({
        urls: ["GETSLOTSAVAILABILITY"],
        method: "GET",
        data: {
          businessId: this.props.addItem.businessId,
          businessLocationId: this.props.addItem.businessLocationId,
          ProductId: this.props.addItem.productId,
          startDate:
            selectedDate === undefined ? timeConverted : newSelectedDate,
          businessCategoryType: this.props.addItem.businessCategoryType,
        },
        onSuccess: ([response]) => {
          if (
            response.shopHours === null &&
            response.timeSlotsForProduct === null &&
            response.slotsForProduct === null &&
            response.timeForProduct === ""
          ) {
            this.props.actions.showNotification(
              {
                title: "Warning",
                message: "No Product is available.",
                position: "br",
                autoDismiss: commonFunctions.notificationTime(),
              },
              "error"
            );
            this.setState({
              ...this.state,
              activityTimeSlots: response,
              selectedDate: selectedDate,
            });
          } else {
            this.setState({
              ...this.state,
              activityTimeSlots: response,
              selectedDate: selectedDate,
            });
          }
        },
      });
    } else {
      this.setState({
        ...this.state,
        activityTimeSlots: false,
        selectedDate: selectedDate,
      });
    }
  };
  getOrderPassDetail = () => {
    if (this.props.addItem.type === "Season Pass") {
      this.props.actions.apiCall({
        urls: ["GETORDERSEASONPASSDETAIL"],
        method: "GET",
        data: {
          SeasonPassId: this.props.addItem.productId,
          InWebsiteWidget: true,
        },
        onSuccess: (response) => {
          this.setState({
            isIndividualProductLoader: false,
            maxSesionPass: response[0].maxItemPerOrder,
            minSesionPass: response[0].minItemPerOrder,
          });
          const images = JSON.parse(response[0].image);
          this.setState({ Itemimages: images });
          this.setState({ itemCount: response[0].itemCount });
          let addOn =
            JSON.parse(response[0].addOn) === null
              ? []
              : JSON.parse(response[0].addOn).map(function (el) {
                  var o = el;
                  o.minValue = el.minAddOns;
                  return o;
                });
          this.setState({ addOns: addOn });
          // this.setState({ CustomQuestions: kk, policyDefaultValue: true });
          let toCheckDescription = JSON.parse(response[0].info).map(
            (item) => item.ItemDescription === "" && item.Customfiels === null
          );
          let showData = toCheckDescription[0];
          if (!showData) {
            const itemInfo = JSON.parse(response[0].info);
            // const customFieldInfo = JSON.parse(response[0].info)[0].Customfiels;
            this.setState({ itemInfos: itemInfo, seassonFalse: true });
          } else {
            this.setState({ itemInfos: false });
          }
          let CustomQuestions =
            JSON.parse(
              JSON.parse(response[0].briefDetails)[0].ProductCustomQuestion
            ) === null
              ? []
              : JSON.parse(
                  JSON.parse(response[0].briefDetails)[0].ProductCustomQuestion
                );
          let kk = CustomQuestions.map(function (el) {
            var o = el;
            if (o.AssociateAddOns !== null) {
              o.isQuestionAnswered = "";
              o.AssociateAddOns = JSON.parse(o.AssociateAddOns);
            }
            return o;
          });
          this.setState({ CustomQuestions: kk, policyDefaultValue: true });
        },
      });
    }
  };

  // get data for Rental Details.
  getOrderRentalPrice = () => {
    var today = this.state.currentDateFromApi;
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    let todayFormat = yyyy + "-" + mm + "-" + dd;
    this.setState({ currentTime: todayFormat });
    this.props.actions.apiCall({
      urls: ["GETORDERRENTALPRICE"],
      method: "GET",
      data: {
        ProductId: this.props.addItem.productId,
        InWebsiteWidget: true,
      },
      onSuccess: (response) => {
        const images = JSON.parse(response[0].image);
        this.setState({
          Itemimages: images,
          rate: JSON.parse(response[0]?.rate),
        });
        if (
          JSON.parse(response[0].briefDetails)[0].CancellationPolicy !== null
        ) {
          let CancellationPolicyDescription = JSON.parse(
            JSON.parse(response[0].briefDetails)[0].CancellationPolicy
          )[0].CancellationPolicyDescription;
          this.setState({
            CancellationPolicyDescriptions: CancellationPolicyDescription,
          });
        } else {
          this.setState({
            CancellationPolicyDescriptions: false,
          });
        }

        let tocheckPickUPinstruction = JSON.parse(response[0].info).map(
          (item) =>
            item.PickUpInstructions === "" &&
            item.DropOffInstructions === "" &&
            item.WhattoKnow === "" &&
            item.WhattoKnow === "" &&
            item.ItemDescription === ""
        );
        let customfields = JSON.parse(response[0].customfields);

        let bb = tocheckPickUPinstruction[0];
        if (!bb) {
          const itemInfo = JSON.parse(response[0].info);
          this.setState({ itemInfos: itemInfo, seassonFalse: false });
        } else if (customfields) {
          this.setState({ itemInfos: customfields, seassonFalse: false });
        } else {
          this.setState({ itemInfos: false });
        }
        if (JSON.parse(response[0].briefDetails)[0].Requirements !== null) {
          let Requirement = JSON.parse(
            JSON.parse(response[0].briefDetails)[0].Requirements
          );
          this.setState({ Requirements: Requirement });
        } else {
          this.setState({ Requirements: [], policyDefaultValue: true });
        }
        let addOns = [];
        if (JSON.parse(response[0].addOn).length !== 0) {
          addOns = JSON.parse(response[0].addOn);
          this.setState({ addOns: addOns });
        } else {
          this.setState({ addOns: [] });
        }

        if (
          JSON.parse(response[0].briefDetails)[0].CancellationPolicy !== null
        ) {
          const CancellationRequirements = JSON.parse(
            JSON.parse(response[0].briefDetails)[0].CancellationPolicy
          )[0].CancellationPolicyDescription;
          this.setState({
            CancellationRequirement: CancellationRequirements,
          });
        } else {
          this.setState({ CancellationRequirement: false });
        }
        let CustomQuestions =
          JSON.parse(
            JSON.parse(response[0].briefDetails)[0].ProductCustomQuestion
          ) === null
            ? []
            : JSON.parse(
                JSON.parse(response[0].briefDetails)[0].ProductCustomQuestion
              );
        let aa = CustomQuestions.map(function (el) {
          var o = el;
          if (o.AssociateAddOns !== null) {
            o.AssociateAddOns = JSON.parse(o.AssociateAddOns);
          }
          o.AssociateAddOnsPrice = false;
          o.isQuestionAnswered = "";
          return o;
        });
        let addOn =
          JSON.parse(response[0].addOn) === null
            ? []
            : JSON.parse(response[0].addOn).map(function (el) {
                var o = el;
                o.minValue = el.minAddOns;
                return o;
              });
        this.setState({ addOns: addOn });

        this.setState({
          CustomQuestions: aa,
          specialPrice: JSON.parse(response[0].specialPrice),
        });
        this.setState({ retailItems: response });

        let rentalinfos = JSON.parse(response[0].info)[0].Description;
        this.setState({ rentalInfo: rentalinfos });
        let customFieldInfo = JSON.parse(response[0].customfields);
        this.setState({ customField: customFieldInfo });
      },
      onFinally: () => {
        this.setState({ allDataRecevied: true });
      },
    });
  };

  getRetailItemDetail = () => {
    if (this.props.addItem.type === "Retail-Bulk") {
      this.props.actions.apiCall({
        urls: ["GETRETAILDETAIL"],
        method: "GET",
        data: {
          retailId: this.props.addItem.productId,
          inWebsiteWidget: true,
        },
        onSuccess: (response) => {
          this.setState({
            maxReatilBulkAble: response[0].maxItemPerOrder,
            minReatilBulkAble: response[0].minItemPerOrder,
          });
          let CustomQuestions =
            JSON.parse(
              JSON.parse(response[0].briefDetails)[0].ProductCustomQuestion
            ) === null
              ? []
              : JSON.parse(
                  JSON.parse(response[0].briefDetails)[0].ProductCustomQuestion
                );
          let kk = CustomQuestions.map(function (el) {
            var o = el;
            if (o.AssociateAddOns !== null) {
              o.isQuestionAnswered = "";
              o.AssociateAddOns = JSON.parse(o.AssociateAddOns);
            }
            return o;
          });
          this.setState({ CustomQuestions: kk });
          this.setState({ retailItems: response });
          const images = JSON.parse(response[0].image);
          this.setState({ Itemimages: images });
          this.setState({ itemCount: response[0].itemCount });
          let addOn =
            JSON.parse(response[0].addOn) === null
              ? []
              : JSON.parse(response[0].addOn).map(function (el) {
                  var o = el;
                  o.minValue = el.minAddOns;
                  return o;
                });
          this.setState({ addOns: addOn });

          let customFieldInfo = JSON.parse(response[0].info)[0].Customfiels;
          let descriptionData = JSON.parse(response[0].info)[0].ItemDescription;
          this.setState({
            ItemShowInretail: descriptionData,
            customeShowInretail: customFieldInfo,
          });
          this.setState({ itemInfos: false });
        },
      });
    }
  };

  // get data for Retail trackable Details.
  getRetailTrackableDetail = () => {
    if (this.props.addItem.type === "Retail-Trackable") {
      this.props.actions.apiCall({
        urls: ["GETRETAILDETAIL"],
        method: "GET",
        data: {
          retailId: this.props.addItem.productId,
          inWebsiteWidget: true,
        },
        onSuccess: (response) => {
          this.setState({
            maxReatilTrackAble: response[0].maxItemPerOrder,
            minReatilTrackAble: response[0].minItemPerOrder,
          });
          let CustomQuestions =
            JSON.parse(
              JSON.parse(response[0].briefDetails)[0].ProductCustomQuestion
            ) === null
              ? []
              : JSON.parse(
                  JSON.parse(response[0].briefDetails)[0].ProductCustomQuestion
                );
          let uu = CustomQuestions.map(function (el) {
            var o = el;
            if (o.AssociateAddOns !== null) {
              o.isQuestionAnswered = "";
              o.AssociateAddOns = JSON.parse(o.AssociateAddOns);
            }
            return o;
          });
          this.setState({ CustomQuestions: uu });
          this.setState({ CustomQuestions: CustomQuestions });
          const images = JSON.parse(response[0].image);
          this.setState({ Itemimages: images });
          let addOn =
            JSON.parse(response[0].addOn) === null
              ? []
              : JSON.parse(response[0].addOn).map(function (el) {
                  var o = el;
                  o.minValue = el.minAddOns;
                  return o;
                });
          this.setState({ addOns: addOn });
          this.setState({ itemCount: response[0].itemCount });
          this.setState({ retailTrackableItems: response });
          let customFieldInfo = JSON.parse(response[0].info)[0].Customfiels;
          let descriptionData = JSON.parse(response[0].info)[0].ItemDescription;

          this.setState({
            ItemShowInretail: descriptionData,
            customeShowInretail: customFieldInfo,
          });
          this.setState({ itemInfos: false });
        },
      });
    }
  };

  // get data for Activity Details.
  GetActivityTimeSlots = () => {
    var today = this.state.currentDateFromApi;
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = mm + "/" + dd + "/" + yyyy;
    let todayFormat = yyyy + "-" + mm + "-" + dd;
    if (todayFormat === "") {
      this.setState({ currentTime: "" });
    } else {
      this.setState({ currentTime: todayFormat });
    }
    if (this.props.addItem.type === "Activity") {
      this.props.actions.apiCall({
        urls: ["GETACTIVITYITIMESLOTES"],
        method: "GET",
        data: {
          ActivityId: this.props.addItem.productId,
          StartDate: today,
          InWebsiteWidget: true,
        },
        onSuccess: (response) => {
          const images = JSON.parse(response[0].image);
          this.setState({ Itemimages: images });
          let addOns =
            JSON.parse(response[0].addOn) === null
              ? []
              : JSON.parse(response[0].addOn).map(function (el) {
                  var o = el;
                  o.minValue = el.minAddOns;
                  return o;
                });
          this.setState({ addOns: addOns });

          if (JSON.parse(response[0].briefDetails)[0].Requirements !== null) {
            let Requirement = JSON.parse(
              JSON.parse(response[0].briefDetails)[0].Requirements
            );
            this.setState({ Requirements: Requirement });
          } else {
            this.setState({ Requirements: false, policyDefaultValue: true });
          }
          let aa = response[0].timing;
          this.setState({ timeSlotes: aa });
          if (
            JSON.parse(response[0].briefDetails)[0].CancellationPolicy !== null
          ) {
            let CancellationPolicyDescription = JSON.parse(
              JSON.parse(response[0].briefDetails)[0].CancellationPolicy
            )[0].CancellationPolicyDescription;
            this.setState({
              CancellationPolicyDescriptions: CancellationPolicyDescription,
            });
          } else {
            this.setState({
              CancellationPolicyDescriptions: false,
            });
          }

          if (
            JSON.parse(response[0].briefDetails)[0].CancellationPolicy !== null
          ) {
            const CancellationRequirements = JSON.parse(
              JSON.parse(response[0].briefDetails)[0].CancellationPolicy
            )[0].CancellationPolicyDescription;
            this.setState({
              CancellationRequirement: CancellationRequirements,
            });
          }

          let toCheckDescription = JSON.parse(response[0].info).map(
            (item) =>
              item.ActivityDescription === "" &&
              item.ActivityWhatIsIncluded === "" &&
              item.ActivityWhattoknowandbring === ""
          );
          let customfields = JSON.parse(response[0].customfields);
                                             
          let tt = toCheckDescription[0];
          if (!tt) {
            const itemInfo = JSON.parse(response[0].info);
            this.setState({ itemInfos: itemInfo, seassonFalse: false });
          } else if (customfields) {
            this.setState({ itemInfos: customfields, seassonFalse: false });
          } else {
            this.setState({ itemInfos: false });
          }
          if (
            JSON.parse(response[0].briefDetails)[0].Requirements === null &&
            JSON.parse(response[0].briefDetails)[0].CancellationPolicy === null
          ) {
            this.setState({ policy: false });
          } else {
            this.setState({ policy: true });
          }
          let CustomQuestions =
            JSON.parse(
              JSON.parse(response[0].briefDetails)[0].CustomQuestion
            ) === null
              ? []
              : JSON.parse(
                  JSON.parse(response[0].briefDetails)[0].CustomQuestion
                );
          let qq = CustomQuestions.map(function (el) {
            var o = el;
            if (o.AssociateAddOns !== null) {
              o.isQuestionAnswered = "";
              o.AssociateAddOns = JSON.parse(o.AssociateAddOns);
            }
            return o;
          });
          this.setState({ CustomQuestions: qq });
          this.setState({ CustomQuestions: CustomQuestions });
          let customFieldInfo = JSON.parse(response[0].customfields);
          this.setState({ customField: customFieldInfo });
        },
      });
    }
  };

  // get data for Bundle Details.
  // GETORDERBUNDLEPRICE = () => {
  //   if (this.props.addItem.type === "Bundle") {
  //     var today = this.state.currentDateFromApi;
  //     var dd = String(today.getDate()).padStart(2, "0");
  //     var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  //     var yyyy = today.getFullYear();
  //     today = mm + "/" + dd + "/" + yyyy;
  //     let todayFormat = yyyy + "-" + mm + "-" + dd;
  //     this.setState({ currentTime: todayFormat });
  //     this.props.actions.apiCall({
  //       urls: ["GETORDERBUNDLEPRICE"],
  //       method: "GET",
  //       data: {
  //         BundleId: this.props.addItem.productId,
  //         InWebsiteWidget: true,
  //       },
  //       onSuccess: (response) => {
  //         this.setState({
  //           addOns: [],
  //           itemInfos: false,
  //           bundleDiscription: response,
  //           bundleRate: JSON.parse(response[0].rate),
  //           BundleTimeSlots: JSON.parse(response[0].breifDetails)[0].TimeSlots,
  //         });
  //         const images = JSON.parse(response[0].image);
  //         this.setState({ Itemimages: images });
  //         const bundleItems = JSON.parse(response[0].bundleDetails);
  //         this.setState({ bundleItems: bundleItems });
  //         let bundleCustomFields = (JSON.parse(
  //           response[0].info
  //         )[0].Customfields = ""
  //           ? false
  //           : JSON.parse(response[0].info)[0].Customfields);
  //         this.setState({ BundleCustomFields: bundleCustomFields });
  //         let BundleDescription =
  //           JSON.parse(response[0].info)[0].BundleDescription === ""
  //             ? false
  //             : JSON.parse(response[0].info)[0].BundleDescription;
  //         this.setState({ BundleDescription: BundleDescription });
  //       },
  //     });
  //   }
  // };

  handleChangeInput = (
    event,
    { placeholder, value, id },
    index,
    customObj,
    customIndex
  ) => {
    const { tickets } = this.state;
    let custom = {
      customDescriptions: customObj.customDescriptions,
      customFieldDetailId: id,
      customAnswer: value,
      isDivderTrue: customObj.isDivderTrue,
      customFieldStart: customObj.customFieldStart,
      customRequired: customObj.customRequired,
    };
    if (tickets[index]["customAnswers"].length > 0) {
      tickets[index]["customAnswers"][customIndex] = custom;
      tickets[index]["customFields"][customIndex] = custom;
    }
    this.setState({
      ...this.state,
      tickets: tickets,
      isFormInValid: !this.state.isFormInValid,
    });
  };

  // handleChangeInput = (event, { name, value, id }, index, customObj, customIndex) => {
  //   const { tickets } = this.state;
  //   let custom = { "customFieldDetailId": id, "customAnswer": value }
  //   if (tickets[index]["customAnswers"].length > 0) {
  //     tickets[index]["customAnswers"][customIndex] = custom;
  //   }

  // }

  getTaxPrice = (e) => {
    const { global, addItem, actions } = this.props;
    const isItemLocationIsSame = commonFunctions.onCheckDifferentLocationItem(
      global.allProductDetail,
      addItem.businessLocationId,
      actions.showNotification
    );
    if (isItemLocationIsSame) {
      if (
        this.checkActivityFormValid() &&
        this.state.addItem.type !== "Season Pass"
      ) {
        this.props.actions.apiCall({
          urls: ["APPLYTAXONITEMS"],
          method: "GET",
          data: {
            applicationId: env.APPLICATION_ID,
            BusinessId: this.props.addItem.businessId,
            BusinessLocationId: this.state.addItem.businessLocationId,
            BusinessCategoryType: this.state.addItem.businessCategoryType,
            Amount: this.state.totalPriceToShow,
          },
          onSuccess: (response) => {
            this.setState({
              IncludingTaxAmount: response[0].amount1,
              totalTaxAmount: response[0].discountAmount,
            });

            if (this.state.activeStep === 1) {
              if (this.state.documentList.length > 0) {
                this.onNext(6);
              } else {
                this.addTocart(e);
              }
            }
          },
        });
      } else {
        if (this.state.activeStep === 1) {
          if (this.state.documentList.length > 0) {
            this.onNext(6);
          } else {
            if (
              e?.target?.id === "ADDTOCART" ||
              e?.target?.id === "CHECKOUTNOW"
            ) {
              this.addTocart(e);
            }
          }
        }
      }
    }
  };
  onHandleBookOnline = (e) => {
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      this.getTaxPrice(e);
    }
  };

  checkCoustomQuestion = (e) => {
    this.validator.allValid();
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (
      isFormValid &&
      !this.state.addOns.length > 0 &&
      !this.state.documentList.length > 0
    ) {
      this.addTocart(e);
    } else if (isFormValid && this.state.addOns.length > 0) {
      this.onNext(5);
    } else if (isFormValid && !this.state.addOns.length > 0) {
      this.onNext(6);
    }
  };

  checkActivityFormValid = () => {
    const checkValidation =
      (this?.activityValidations.allValid() &&
        this?.sessoinPassValidations.allValid()) ??
      true;
    if (!checkValidation) {
      this?.activityValidations.showMessages();
      this?.sessoinPassValidations.showMessages();
    }
    this.setState({ isFormInValid: !this.state.isFormInValid });
    return checkValidation;
  };

  
  addTocart = (e) => {
    setTimeout(() => {
      this.setState({ isIndividualProductLoader: false });
    }, 1000);
    if (this.checkActivityFormValid()) {
      const { addItem } = this.state;
      if (this.state.allValue !== undefined) {
        addItem.addOns = this.state.allValue.filter(function (e) {
          return e.quantity !== 0;
        });
      } else {
        addItem.addOns = [];
      }
      addItem.tickets = this.state.tickets.filter(function (e) {
        return e.numberOfTickets !== 0;
      });
      if (this.state.addItem.businessCategoryType === 4) {
        addItem.price = this.state.singlebundlePrice;
        addItem.selectedTime = this.state.selectedTime;
        addItem.selectedDate = this.state.currentTime;
        addItem.endTimeRange = this.state.selectedTime;
        addItem.bundleCount = this.state.count;
        addItem.totalTaxAmount = 0;
        addItem.currentTime = this.state.currentTime;
      }
      if (this.state.addItem.type === "Season Pass") {
        addItem.seasonPassCustomFields = this.state.sessoinPass;
        addItem.seasonPassCount = this.state.count;
        addItem.seasonPassPrice = this.state.totalprice;
        addItem.totalTaxAmount = this.state.totalTaxAmount;
      }
      if (this.state.addItem.type === "Retail-Trackable") {
        addItem.retailtrackablecount = this.state.count;
        addItem.totalTaxAmount = this.state.totalTaxAmount;
      }
      if (this.state.addItem.type === "Retail-Bulk") {
        addItem.retailbulkcount = this.state.count;
        addItem.totalTaxAmount = this.state.totalTaxAmount;
      }
      if (this.state.addItem.businessCategoryType === 1) {
        if (!this.state.value === "duration") {
          addItem.startDate =
            this.state.startDateRange +
            " " +
            this.state.availableTime.startTimeHour +
            ":" +
            this.state.availableTime.startTimeMinute +
            this.state.availableTime.startTimeAm;
          addItem.endDate =
            this.state.endDateRange +
            " " +
            this.state.availableTime.endTimeHour +
            ":" +
            this.state.availableTime.endTimeMinute +
            this.state.availableTime.endTimeAm;
        } else if (this.state.rentalSlotes) {
          addItem.selectedTime = this.state.selectedTime;
          addItem.selectedDate = this.state.startDateRange;
          addItem.startDate =
            this.state.startDateRange + " " + this.state.selectedTime;
          addItem.endDate =
            this.state.endDateRange + " " + this.state.endTimeRange;
        } else {
          addItem.selectedTime =
            this.state.availableTime.startTimeHour +
            ":" +
            this.state.availableTime.startTimeMinute +
            this.state.availableTime.startTimeAm;
          addItem.selectedDate = this.state.startDateRange;
          addItem.startDate =
            this.state.startDateRange +
            " " +
            this.state.availableTime.startTimeHour +
            ":" +
            this.state.availableTime.startTimeMinute +
            this.state.availableTime.startTimeAm;
          addItem.endDate =
            this.state.endDateRange + " " + this.state.endTimeRange;
        }

        addItem.price = this.state.ItemPrice;
        addItem.rentalCount = this.state.count;
        addItem.totalTaxAmount = this.state.totalTaxAmount;
        addItem.Requirements = this.state.Requirements;
        addItem.rentalFlatFeeId = this.state.rentalFlatFeeId;
      }
      if (this.state.addItem.businessCategoryType === 3) {
        addItem.selectedDate =
          this.state.currentTime === ""
            ? this.state.selectedDate
            : this.state.currentTime;
        addItem.endTimeRange = this.state.startTimeRange;
        addItem.totalTaxAmount = this.state.totalTaxAmount;
        addItem.Requirements = this.state.Requirements;
        addItem.currentTime = this.state.currentTime;
        addItem.selectedTime =
          this.state.selectedTime === ""
            ? this.state.startTimeRange
            : this.state.selectedTime;
      }
      if (this.state?.waiver.length > 0) {
        addItem.waivers = this.state.waiver;
        addItem.IsSigned = true;
        addItem.IsPolicy = this.state.IsPolicy;
      }
      addItem.documentId = this.state.documentId;
      addItem.signatureImage = this.state.signatureImage;
      addItem.addOnTotalPrice =
        this.state.addOnTotalPrice === undefined
          ? 0
          : this.state.addOnTotalPrice;
      addItem.bundleItems = this.state.bundleItems;
      addItem.IncludingTaxAmount = this.state.IncludingTaxAmount;
      addItem.totalprice = isNaN(this.state.totalprice)
        ? 0
        : this.state.totalprice;
      addItem.CustomQuestions = this.state.CustomQuestions;

      let extraDetail = {
        selectedTime: this.state.selectedTime,
        selectedDate:
          this.state.currentTime === ""
            ? this.state.selectedDate
            : this.state.currentTime,
        subtotal:
          // this.state.totalprice + this.props.global.allProductDetail.totalprice,
          this.state.totalprice +
          isNaN(this.props.global?.allProductDetail?.totalprice)
            ? 0
            : this.props.global.allProductDetail.totalprice,
        totalprice: isNaN(this.state.totalprice) ? 0 : this.state.totalprice,
      };
      let extraIndividualDetail = {
        selectedTime: this.state.selectedTime,
        selectedDate:
          this.state.currentTime === ""
            ? this.state.selectedDate
            : this.state.currentTime,
        subtotal:
          this.state.totalprice +
          this.props.global.allProductIndividualDetail.totalprice,
        totalprice: isNaN(this.state.totalprice) ? 0 : this.state.totalprice,
      };

      let addItemCopy = JSON.parse(JSON.stringify(addItem));
      if (e?.target?.id === "ADDTOCART" || e === "ADDTOCART") {
               
        this.props.actions.AllItemDetailToPay([
          { addItem: addItemCopy },
          { extraDetail: extraDetail },
        ]);
       
        if (this.props.global.insideWordpress) {
                   
          this.props.actions.storeWordpressBacktoStop(true);
          this.props.actions.storeShowCount(true);
        } else {
          this.props.actions.storeWordpressBacktoStop(false);
          this.props.actions.storeShowCount(true);
        }

        this.onCloseBookingModal(e);
      } else {
        this.props.actions.storeIndividualItemDetail([
          { addItem: addItemCopy },

          { extraDetail: extraIndividualDetail },
        ]);
      if (this.props.global.insideWordpress) {
                   
          this.props.actions.storeWordpressBacktoStop(true);
          this.props.actions.storeShowCount(true);
          this.props.actions.storeInsideWordpress(true);
        } else {
          this.props.actions.storeWordpressBacktoStop(false);
          this.props.actions.storeShowCount(true);
        }

        this.onCheckFromWhereUserCame();
      }

      if (e?.target?.id === "CHECKOUTNOW" || e === "CHECKOUTNOW") {
               
        this.props.allFuction.setState({
          isBookNowWidget: false,
          isHeaderWidget: false,
        });
        this.props.actions.storeShowCount(false);
        this.props.actions.storeWordpressBacktoStop(false);
      } 
      else {
        this.props.allFuction.setState({
          isBookNowWidget: this.props.fullstate.isBookNowWidget,
          isHeaderWidget: this.props.fullstate.isHeaderWidget,
        });
        if (this.props.global.insideWordpress) {
                   
          this.props.actions.storeWordpressBacktoStop(true);
          this.props.actions.storeShowCount(true);
          this.props.actions.storeInsideWordpress(true);
        }
         else {
          this.props.actions.storeWordpressBacktoStop(false);
          this.props.actions.storeShowCount(true);
        }

      }
      this.props.actions.storeItemDetail(this.state);
    }
  };
  onCloseBookingModal = (e) => {
    this.props.closeModal();
  };

  onCheckFromWhereUserCame = () => {
    if (this.props.individualItem) {
      this.props.onDirectlyOpenCheckout();
    } else if (this.props.wordpressModal) {
      this.props.wordpressAddToCart();
      this.props.allFuction.setState({
        isBookNowWidget: false,
        isHeaderWidget: false,
        showCountCart: false,
      });
    } else {
      this.props.closeModal();
      this.props.history.push(`${env.PUBLIC_URL}/my-cart`);
    }
  };

  getStepContent = (stepIndex) => {
    const {
      currentDateFromApi,
      allDataRecevied,
      blackoutEndDate,
      blackOutStartDate,
      availAbilityEndDate,
    } = this.state;
    const policy =
      (this.state.CancellationPolicyDescriptions ? true : false) ||
      (this.state.Requirements.length > 0 ? true : false);
    const { availabilityStartDate } = this.state;
    const parts = availabilityStartDate?.split("T");
    let initialMonthDate;
    if (availabilityStartDate) {
      const [year, month] = parts[0].split("-");
      initialMonthDate = `${year}-${month}-01`;
    } else {
      initialMonthDate = currentDateFromApi;
    }
    switch (stepIndex) {
      case 0:
        return (
          <div className="stepBody" id="stepContent">
            <StepOne addItem={this.props.addItem} allFuction={this} />
            <Grid>
              <Grid.Column width={16} textAlign="right">
                {/* <Button
                  className="secondaryBtn"
                  onClick={() => this.onPrevious(-1)}
                >
                  Back
                </Button> */}
                <Button className="primaryBtn" onClick={() => this.onNext(1)}>
                  <a href="#productTab" className="continueBtn">
                    Continue
                  </a>
                </Button>
              </Grid.Column>
            </Grid>
          </div>
        );

      case 1:
        return (
          <div className="stepBody">
            {this.props.addItem.type === "Retail-Bulk" ? (
              <RetailBulkOne
                isShowAvailableItem={this.props.isShowAvailableItem}
                currentDateFromApi={currentDateFromApi}
                addItem={this.props.addItem}
                itemCount={this.state.itemCount}
                retailItems={this.state.retailItems}
                allFuction={this}
                customField={this.state.customeShowInretail}
                ItemDescription={this.state.ItemShowInretail}
              />
            ) : this.props.addItem.type === "Rental" && allDataRecevied ? (
              <RentalDetail
                isShowAvailableItem={this.props.isShowAvailableItem}
                currentDateFromApi={currentDateFromApi}
                CustomTimefirst={CustomTimefirst}
                CustomTimetwo={CustomTimetwo}
                CustomTimethree={CustomTimethree}
                addItem={this.props.addItem}
                availableTime={this.state.availableTime}
                activityTimeSlots={this.state.activityTimeSlots}
                allFuction={this}
                rentalCount={this.state.rentalCount}
                slotCount={this.state.slotCount}
                selectedTime={this.state.selectedTime}
                addToCArtOrNot={this.state.addToCArtOrNot}
                availabilityStartDate={initialMonthDate}
                isFetching={this.state.isFetching}
                blackoutEndDate={blackoutEndDate}
                blackOutStartDate={blackOutStartDate}
                availAbilityEndDate={availabilityStartDate}
              />
            ) : this.props.addItem.type === "Retail-Trackable" ? (
              <RetailTrackable
                isShowAvailableItem={this.props.isShowAvailableItem}
                currentDateFromApi={currentDateFromApi}
                retailTrackableItems={this.state.retailTrackableItems}
                count={this.state.count}
                addItem={this.props.addItem}
                itemCount={this.state.itemCount}
                allFuction={this}
                customField={this.state.customeShowInretail}
                ItemDescription={this.state.ItemShowInretail}
              />
            ) : this.props.addItem.type === "Season Pass" ? (
              <SessonPass
                seasonPassCustomFields={this.state.seasonPassCustomFields}
                sessoinPass={this.state.sessoinPass}
                isShowAvailableItem={this.props.isShowAvailableItem}
                currentDateFromApi={currentDateFromApi}
                addItem={this.props.addItem}
                itemCount={this.state.itemCount}
                retailItems={this.state.retailItems}
                allFuction={this}
                customField={this.state.customField}
                ItemDescription={this.state.ItemShowInretail}
                isFormInValid={this.state.isFormInValid}
                availabilityStartDate={initialMonthDate}
              />
            ) : (
              <StepTwo
                currentDateFromApi={currentDateFromApi}
                loaderActivityData={this.state.loaderActivityData}
                isShowAvailableItem={this.props.isShowAvailableItem}
                isShowAvailabletickets={this.props.isShowAvailabletickets}
                count={this.state.count}
                bundleDiscription={this.state.bundleDiscription}
                BundleTimeSlots={this.state.BundleTimeSlots}
                bundleRate={this.state.bundleRate}
                itemPrice={this.props.addItem.price}
                addItem={this.props.addItem}
                allFuction={this}
                insideWordpress={this.props.insideWordpress}
                timeSlotes={this.state.timeSlotes}
                selectedTime={this.state.selectedTime}
                tickets={this.state.tickets}
                currentTime={this.state.currentTime}
                businessLocationId={this.props.addItem.businessLocationId}
                itemInfos={this.state.itemInfos}
                bundleItems={this.props.bundleItems}
                activityTimeSlots={this.state.activityTimeSlots}
                ticketsTable={this.state.ticketsTable}
                getNewActivityTimeSlots={this.getNewActivityTimeSlots}
                ticketsDetails={this.state.ticketsDetails}
                addToCArtOrNot={this.state.addToCArtOrNot}
                bundleProduct={this.state.bundleProduct}
                CustomTimefirst={CustomTimefirst}
                CustomTimetwo={CustomTimetwo}
                CustomTimethree={CustomTimethree}
                availableTime={this.state.availableTime}
                activityValidations={this.activityValidations}
                isFormInValid={this.state.isFormInValid}
                availabilityStartDate={initialMonthDate}
                availabiltyStart={availabilityStartDate}
                isFetching={this.state.isFetching}
                blackoutEndDate={blackoutEndDate}
                blackOutStartDate={blackOutStartDate}
                availAbilityEndDate={availAbilityEndDate}
                businessId={this.props?.addItem?.businessId}
              />
            )}
            <Grid>
              <Grid.Column width={16} textAlign="right">
                {this.props.addItem.isLocationTabVisible === false ? null : (
                  <Button
                    className="secondaryBtn"
                    onClick={() => this.onPrevious(0)}
                  >
                    Back
                  </Button>
                )}
                {this.state.addOns.length > 0 ? (
                  <Button
                    className="primaryBtn"
                    disabled={!this.state.addToCArtOrNot}
                    customField={this.state.customField}
                    onClick={() =>
                      this.onNext(
                        policy
                          ? 2
                          : 3 && this.state.itemInfos
                          ? 3
                          : 4 && this.state.CustomQuestions.length > 0
                          ? 4
                          : 5
                      )
                    }
                  >
                    <a href="#productTab" className="continueBtn">
                      Continue
                    </a>
                  </Button>
                ) : this.state?.CustomQuestions?.length > 0 ? (
                  <Button
                    className="primaryBtn"
                    disabled={!this.state.addToCArtOrNot}
                    customField={this.state.customField}
                    onClick={() =>
                      this.onNext(
                        policy ? 2 : 3 && this.state.itemInfos ? 3 : 4
                      )
                    }
                  >
                    <a href="#productTab" className="continueBtn">
                      Continue
                    </a>
                  </Button>
                ) : this.state?.itemInfos?.length > 0 ? (
                  <Button
                    className="primaryBtn"
                    disabled={!this.state.addToCArtOrNot}
                    customField={this.state.customField}
                    onClick={() =>
                      this.onNext(
                        policy ? 2 : 3 && this.state.itemInfos ? 3 : 4
                      )
                    }
                  >
                    <a href="#productTab" className="continueBtn">
                      Continue
                    </a>
                  </Button>
                ) : policy !== false ? (
                  <Button
                    className="primaryBtn"
                    disabled={!this.state.addToCArtOrNot}
                    customField={this.state.customField}
                    onClick={() =>
                      this.onNext(
                        policy ? 2 : 3 && this.state.itemInfos ? 3 : 4
                      )
                    }
                  >
                    <a href="#productTab" className="continueBtn">
                      Continue
                    </a>
                  </Button>
                ) : null}
                {!this.state.addOns?.length > 0 &&
                  !this.state?.CustomQuestions?.length > 0 &&
                  !this.state?.itemInfos &&
                  !policy && (
                    <>
                      <Button
                        disabled={!this.state.addToCArtOrNot}
                        onClick={this.getTaxPrice}
                        id="ADDTOCART"
                        className="primaryBtn"
                      >
                        {this.state.documentList.length > 0
                          ? "Continue"
                          : "Add to Cart"}
                      </Button>
                      {!this.state.documentList.length > 0 && (
                        <>
                          <Button
                            disabled={!this.state.addToCArtOrNot}
                            onClick={this.onHandleBookOnline}
                            id="CHECKOUTNOW"
                            className="primaryBtn"
                          >
                            Checkout Now
                          </Button>
                          <div className="checkOutButton">
                            <span className="astrickSign">*</span>
                            For multiple items, click "Add to Cart". "Checkout
                            Now" only applies to current item.
                          </div>
                        </>
                      )}
                    </>
                  )}
              </Grid.Column>
            </Grid>
          </div>
        );
      case 2:
        return (
          <div className="stepBody">
            <StepThree
              CancellationPolicyDescriptions={
                this.state.CancellationPolicyDescriptions
              }
              CancellationRequirement={this.state.CancellationRequirement}
              allFuction={this}
              Requirements={this.state.Requirements}
            />
            <Grid>
              <Grid.Column width={16} textAlign="right">
                {
                  <Button
                    className="secondaryBtn"
                    onClick={() => this.onPrevious(1)}
                  >
                    Back
                  </Button>
                }
                {this.state.addOns.length > 0 ? (
                  <Button
                    disabled={!this.state.policyDefaultValue}
                    className="primaryBtn"
                    onClick={() =>
                      this.onNext(
                        this.state.itemInfos
                          ? 3
                          : 4 && this.state.CustomQuestions.length > 0
                          ? 4
                          : 5
                      )
                    }
                  >
                    <a href="#productTab" className="continueBtn">
                      Continue
                    </a>
                  </Button>
                ) : this.state.CustomQuestions.length > 0 ? (
                  <Button
                    disabled={!this.state.policyDefaultValue}
                    className="primaryBtn"
                    onClick={() => this.onNext(this.state.itemInfos ? 3 : 4)}
                  >
                    <a href="#productTab" className="continueBtn">
                      Continue
                    </a>
                  </Button>
                ) : this.state.itemInfos !== false ? (
                  <Button
                    disabled={!this.state.policyDefaultValue}
                    className="primaryBtn"
                    onClick={() => this.onNext(this.state.itemInfos ? 3 : 4)}
                  >
                    <a href="#productTab" className="continueBtn">
                      Continue
                    </a>
                  </Button>
                ) : null}
                {!this.state.addOns.length > 0 &&
                  !this.state.CustomQuestions.length > 0 &&
                  !this.state.itemInfos && (
                    <>
                      {!this.state.documentList.length > 0 ? (
                        <>
                          <Button
                            disabled={!this.state.policyDefaultValue}
                            onClick={this.addTocart}
                            id="ADDTOCART"
                            className="primaryBtn"
                          >
                            Add to Cart
                          </Button>
                          <Button
                            disabled={!this.state.policyDefaultValue}
                            onClick={this.addTocart}
                            id="CHECKOUTNOW"
                            className="primaryBtn"
                          >
                            Checkou Now
                          </Button>
                          <div className="">
                            <span className="astrickSign">*</span>
                            For multiple items, click "Add to Cart". "Checkout
                            Now" only applies to current item.
                          </div>
                        </>
                      ) : (
                        <Button
                          disabled={!this.state.addToCArtOrNot}
                          className="primaryBtn"
                          onClick={() => this.onNext(6)}
                        >
                          <a href="#productTab" className="continueBtn">
                            Continue
                          </a>
                        </Button>
                      )}
                    </>
                  )}
              </Grid.Column>
            </Grid>
          </div>
        );

      case 3:
        return (
          <div className="stepBody">
            <StepFour
              itemInfos={this.state.itemInfos}
              customField={this.state.customField}
              seassonFalse={this.state.seassonFalse}
            />
            <Grid>
              <Grid.Column width={16} textAlign="right">
                <Button
                  className="secondaryBtn"
                  onClick={() =>
                    this.onPrevious(
                      this.state.CancellationPolicyDescriptions ? 2 : 1
                    )
                  }
                >
                  Back
                </Button>

                {this.state.CustomQuestions.length > 0 ? (
                  <Button
                    disabled={!this.state.policyDefaultValue}
                    className="primaryBtn"
                    onClick={() => this.onNext(4)}
                  >
                    <a href="#productTab" className="continueBtn">
                      Continue
                    </a>
                  </Button>
                ) : this.state.addOns.length > 0 ? (
                  <Button
                    disabled={!this.state.policyDefaultValue}
                    className="primaryBtn"
                    onClick={() => this.onNext(5)}
                  >
                    <a href="#productTab" className="continueBtn">
                      Continue
                    </a>
                  </Button>
                ) : this.state.documentList.length > 0 ? (
                  <Button
                    disabled={!this.state.policyDefaultValue}
                    className="primaryBtn"
                    onClick={() => this.onNext(6)}
                  >
                    <a href="#productTab" className="continueBtn">
                      Continue
                    </a>
                  </Button>
                ) : null}

                {!this.state.addOns.length > 0 &&
                  !this.state.CustomQuestions.length > 0 &&
                  !this.state.documentList.length > 0 && (
                    <>
                      <Button
                        disabled={!this.state.addToCArtOrNot}
                        id="ADDTOCART"
                        onClick={this.addTocart}
                        className="primaryBtn"
                      >
                        Add to Cart
                      </Button>
                      <Button
                        disabled={!this.state.addToCArtOrNot}
                        onClick={this.addTocart}
                        id="CHECKOUTNOW"
                        className="primaryBtn"
                      >
                        Checkout Now
                      </Button>
                      <div className="checkOutButton">
                        <span className="astrickSign">*</span>For multiple
                        items, click "Add to Cart". "Checkout Now" only applies
                        to current item.
                      </div>
                    </>
                  )}
              </Grid.Column>
            </Grid>
          </div>
        );
      case 4:
        return (
          <div className="stepBody">
            <StepFive
              CustomQuestions={this.state.CustomQuestions}
              allFuction={this}
            />
            <Grid>
              <Grid.Column width={16} textAlign="right">
                <Button
                  className="secondaryBtn"
                  onClick={() =>
                    this.onPrevious(
                      this.state.itemInfos ? 3 : 2 && policy ? 2 : 1
                    )
                  }
                >
                  Back
                </Button>
                {!this.state.addOns.length > 0 &&
                !this.state.documentList.length > 0 ? (
                  <>
                    <Button
                      disabled={!this.state.addToCArtOrNot}
                      onClick={this.checkCoustomQuestion}
                      id="ADDTOCART"
                      className="primaryBtn"
                    >
                      Add to Cart
                    </Button>
                    <Button
                      disabled={!this.state.addToCArtOrNot}
                      onClick={this.checkCoustomQuestion}
                      id="CHECKOUTNOW"
                      className="primaryBtn"
                    >
                      Checkout Now
                    </Button>
                    <div className="checkOutButton">
                      <span className="astrickSign">*</span>For multiple items,
                      click "Add to Cart"."Checkout Now" only applies to current
                      item.
                    </div>
                  </>
                ) : (
                  <Button
                    disabled={!this.state.addToCArtOrNot}
                    className="primaryBtn"
                    onClick={this.checkCoustomQuestion}
                  >
                    <a href="#productTab" className="continueBtn">
                      Continue
                    </a>
                  </Button>
                )}
              </Grid.Column>
            </Grid>
          </div>
        );
      case 5:
        return (
          <div className="stepBody">
            {this.state.addOns.length > 0 && (
              <StepSix
                addOn={this.state.addOns}
                allValue={this.state.allValue}
                allFuction={this}
              />
            )}
            <Grid>
              <Grid.Column width={16} textAlign="right">
                <Button
                  className="secondaryBtn"
                  onClick={() =>
                    this.onPrevious(
                      this.state.CustomQuestions.length > 0
                        ? 4
                        : 3 && this.state.itemInfos
                        ? 3
                        : 2 && this.state.CancellationPolicyDescriptions
                        ? 2
                        : 1
                    )
                  }
                >
                  Back
                </Button>

                {!this.state.documentList.length > 0 ? (
                  <>
                    <Button
                      disabled={!this.state.addToCArtOrNot}
                      onClick={this.addTocart}
                      id="ADDTOCART"
                      className="primaryBtn"
                      style={{ margin: "10px 10px" }}
                    >
                      Add to Cart
                    </Button>
                    <Button
                      disabled={!this.state.addToCArtOrNot}
                      onClick={this.addTocart}
                      id="CHECKOUTNOW"
                      className="primaryBtn"
                    >
                      Checkout Now
                    </Button>
                    <div className="checkOutButton">
                      <span className="astrickSign">*</span>For multiple items,
                      click "Add to Cart". "Checkout Now" only applies to
                      current item.
                    </div>
                  </>
                ) : (
                  <>
                    <Button
                      disabled={!this.state.addToCArtOrNot}
                      className="primaryBtn"
                      onClick={() => this.onNext(6)}
                    >
                      <a href="#productTab" className="continueBtn">
                        Continue
                      </a>
                    </Button>
                  </>
                )}
              </Grid.Column>
            </Grid>
          </div>
        );
      case 6:
        return (
          <div className="stepBody">
            <Grid>
              <Grid.Column className="text-right" width={16}>
                <Button
                  className="secondaryBtn"
                  onClick={() =>
                    this.onPrevious(
                      this.state.addOns.length > 0
                        ? 5
                        : 4 && this.state.CustomQuestions.length > 0
                        ? 4
                        : 3 && this.state.itemInfos
                        ? 3
                        : 2 && this.state.CancellationPolicyDescriptions
                        ? 2
                        : 1
                    )
                  }
                >
                  Back
                </Button>
                {this.props.global.skipOptionInAddItem && (
                  <Popup
                    trigger={
                      <Button className="primaryBtn">Skip Waiver</Button>
                    }
                    flowing
                    hoverable
                    size="tiny"
                    style={{ zIndex: 99999 }}
                    position="top right"
                  >
                    <div className="containerSkipWaiver">
                      <div className="skipWaiver">
                        <Header as="h4">
                          Are you sure you want to Skip Waiver ?
                        </Header>
                      </div>
                      <Grid.Column>
                        <Button
                          type="submit"
                          onClick={this.addTocart}
                          id="ADDTOCART"
                          className="primaryBtn"
                        >
                          Add to cart
                        </Button>
                        <Button
                          disabled={!this.state.addToCArtOrNot}
                          onClick={this.addTocart}
                          id="CHECKOUTNOW"
                          className="primaryBtn"
                        >
                          Checkout Now
                        </Button>
                        <div className="checkOutButton">
                          <span className="astrickSign">*</span>For multiple
                          items, click "Add to Cart". "Checkout Now" only
                          applies to current item.
                        </div>
                      </Grid.Column>
                    </div>
                  </Popup>
                )}
              </Grid.Column>
            </Grid>
            <Waiver
              inSideWidget={true}
              businessId={this.props?.addItem?.businessId}
              onFinish={this}
              allData={this}
              showtheDocumnetData={false}
            />
          </div>
        );
      default:
        return null;
    }
  };
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: smooth scrolling animation
    });
  }
  onNext = (step) => {
    if (this.checkActivityFormValid()) {
      const { global, addItem, actions } = this.props;

      const isItemLocationIsSame = commonFunctions.onCheckDifferentLocationItem(
        global.allProductDetail,
        addItem.businessLocationId,
        actions.showNotification
      );
      if (isItemLocationIsSame) {
        this.setState({ activeStep: step, onPrevious: true });
        if (
          (this.state.totalPriceToShow !== null ? true : false) &&
          this.state.activeStep === 1
        ) {
          this.getTaxPrice();
        }
        this.scrollToTop();
      }
      step === 1 && this.setState({ isIndividualProductLoader: false });
    }
    this.scrollToTop();
  };

  onPrevious = (step) => {
    this.setState({ activeStep: step, onPrevious: true });
  };

  render() {
    const {
      activeStep,
      allDataRecevied,
      isIndividualProductLoader,
      Itemimages,
    } = this.state;
    const { addItem, api } = this.props;
    let policy =
      (this.state.CancellationPolicyDescriptions ? true : false) ||
      (this.state.Requirements.length > 0 ? true : false);
    const isImageshow =
      Itemimages && Itemimages[0]?.ImageFile === "NullImage.png" ? true : false;

    return (
      <Modal
        open={this.props.openModal}
        closeIcon
        onClose={this.props.closeModal}
        className={
          isImageshow
            ? "addCartModal imageShowModal bookNowModal"
            : "addCartModal bookNowModal"
        }
      >
        <Modal.Header>
          <span title={addItem?.name}>{addItem?.name}</span>
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            {(api.isApiLoading ||
              allDataRecevied === false ||
              isIndividualProductLoader) && (
              <Dimmer active inverted>
                <Loader />
              </Dimmer>
            )}
            <Container className="productDetail">
              <Grid>
                <Grid.Column
                  computer={5}
                  tablet={5}
                  mobile={16}
                  className="noImageThumnail"
                >
                  <Swiper
                    loop={true}
                    Navigation={true}
                    spaceBetween={11}
                    navigation={true}
                    className="mySwiper2"
                  >
                    {this.state.Itemimages &&
                      this.state.Itemimages.map((product) => {
                        return (
                          <>
                            {product.ImageFile === "NullImage.png" ? null : (
                              <SwiperSlide Navigation={true}>
                                <Image
                                  src={commonFunctions.concatenateImageWithAPIUrl(
                                    product.ImageFile
                                      ? product.ImageFile
                                      : product.image
                                  )}
                                  fluid
                                />
                              </SwiperSlide>
                            )}
                          </>
                        );
                      })}
                  </Swiper>
                  <Swiper
                    loop={true}
                    spaceBetween={10}
                    slidesPerView={4}
                    freeMode={true}
                    watchSlidesProgress={true}
                    className="mySwiper"
                  >
                    {this.state.Itemimages &&
                      this.state.Itemimages.map((product) => {
                        return (
                          <>
                            {product.ImageFile === "NullImage.png" ? null : (
                              <SwiperSlide>
                                <Image
                                  src={commonFunctions.concatenateImageWithAPIUrl(
                                    product.ImageFile
                                  )}
                                  fluid
                                />
                              </SwiperSlide>
                            )}
                          </>
                        );
                      })}
                  </Swiper>
                </Grid.Column>
                <Grid.Column computer={11} tablet={11} mobile={16}>
                  <Step.Group id="productTab" className="custom-stepper">
                    {addItem?.isLocationTabVisible && (
                      <Step
                        active={activeStep === 0 ? true : false}
                        completed={activeStep > 0 ? true : false}
                      >
                        <Step.Content>
                          <Step.Title>Locations</Step.Title>
                        </Step.Content>
                        {activeStep === 0 && this.getStepContent(activeStep)}
                      </Step>
                    )}
                    <Step
                      active={activeStep === 1 ? true : false}
                      completed={activeStep > 1}
                    >
                      <Step.Content>
                        <Step.Title>Book Online</Step.Title>
                      </Step.Content>
                      {activeStep === 1 && this.getStepContent(activeStep)}
                    </Step>

                    {policy && (
                      <Step
                        active={activeStep === 2 ? true : false}
                        completed={activeStep > 2}
                      >
                        <Step.Content>
                          {/* onClick={() => this.changeStep(2)}> */}
                          <Step.Title>Policy </Step.Title>
                        </Step.Content>
                        {activeStep === 2 && this.getStepContent(activeStep)}
                      </Step>
                    )}
                    {this.state.itemInfos && (
                      <Step
                        active={activeStep === 3 ? true : false}
                        completed={activeStep > 3}
                      >
                        <Step.Content>
                          {/* onClick={() => this.changeStep(3)}> */}
                          <Step.Title>Info </Step.Title>
                        </Step.Content>
                        {activeStep === 3 && this.getStepContent(activeStep)}
                      </Step>
                    )}
                    {this.state.CustomQuestions.length > 0 && (
                      <Step
                        active={activeStep === 4 ? true : false}
                        completed={activeStep > 4}
                      >
                        <Step.Content>
                          {/* onClick={() => this.changeStep(4)}> */}

                          <Step.Title>Questions </Step.Title>
                        </Step.Content>
                        {activeStep === 4 && this.getStepContent(activeStep)}
                      </Step>
                    )}
                    {this.state.addOns.length > 0 && (
                      <Step
                        active={activeStep === 5 ? true : false}
                        completed={activeStep > 5}
                      >
                        <Step.Content>
                          <Step.Title>Add-On </Step.Title>
                        </Step.Content>
                        {activeStep === 5 && this.getStepContent(activeStep)}
                      </Step>
                    )}

                    {this.state.documentList.length > 0 && (
                      <Step
                        active={activeStep === 6 ? true : false}
                        completed={activeStep > 0 ? true : false}
                      >
                        <Step.Content>
                          <Step.Title>Waiver</Step.Title>
                        </Step.Content>
                        {activeStep === 6 && this.getStepContent(activeStep)}
                      </Step>
                    )}
                  </Step.Group>
                </Grid.Column>
              </Grid>
            </Container>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      storeItemDetail: bindActionCreators(actions.storeItemDetail, dispatch),
      storeIndividualItemDetail: bindActionCreators(
        actions.storeIndividualItemDetail,
        dispatch
      ),
      storeShowCount: bindActionCreators(actions.storeShowCount, dispatch),
      storeWordpressBacktoStop: bindActionCreators(
        actions.storeWordpressBacktoStop,
        dispatch
      ),
      storeInsideWordpress: bindActionCreators(
        actions.storeInsideWordpress,
        dispatch
      ),
      AllItemDetailToPay: bindActionCreators(
        actions.AllItemDetailToPay,
        dispatch
      ),
      DeleteAllItemDetail: bindActionCreators(
        actions.DeleteAllItemDetail,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      DeleteItemIndividualDetail: bindActionCreators(
        actions.DeleteItemIndividualDetail,
        dispatch
      ),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddCartModal);
