import React, { Component } from "react";
import { DatePicker } from "react-trip-date";

class RentalDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let r = document.querySelector(":root");
    let p = getComputedStyle(r);
    const theme = {
      primary: {
        light: p.getPropertyValue("--main-color"),
        main: p.getPropertyValue("--main-color"),
        dark: p.getPropertyValue("--main-color"),
      },

      grey: {
        700: "#707070",
        900: "#1b1b1d",
      },

      background: {
        default: "#f54952",
      },

      text: {
        disabled: "#BABABA",
      },
    };

    const {
      initialMonthAndYear,
      Day,
      fullstate,
      allFuction,
      handleResponsive,
      disabledBeforeDate,
    } = this.props;
    return (
      <>
        <DatePicker
          onRangeDateInScreen={allFuction.onRangeDateInScreen}
          onChange={allFuction.onChange}
          theme={theme}
          numberOfMonths={1}
          initialMonthAndYear={initialMonthAndYear}
          selectedDays={fullstate.firstAvailableDate}
          responsive={handleResponsive}
          disabledBeforeToday={true}
          disabledBeforeDate={disabledBeforeDate}
          numberOfSelectableDays={1}
          disabledDays={fullstate.nonAvalableDAtes}
          disabled={false}
          dayComponent={Day} 
          titleComponent="{Title}"
        />
      </>
    );
  }
}
export default RentalDatePicker;
