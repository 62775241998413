import * as types from "../types";

export const storeGlobalCodes = (data) => {
  return { type: types.STORE_GLOBAL_CODES, payload: data };
};

export const storeBussinessDetail = (data) => {
  return { type: types.STORE_BUSSINESS_DETAIL, payload: data };
};
export const storeItemDetail = (data) => {
  return { type: types.STORE_ITEM_DETAIL, payload: data };
};
export const storeIndividualItemDetail = (data) => {
  return { type: types.STORE_INDIVIDUAL_ITEM_DETAIL, payload: data };
};
export const SHOWHEADERWEBSITE = (data) => {
  return { type: types.SHOW_HEADER_WEBSITE, payload: data };
};
export const cartItemDelete = (data) => {
  return { type: types.STORE_ITEM_UPDATE, payload: data };
};
export const DeleteAllItemDetail = (data) => {
  return { type: types.STORE_DELETE_ALLITEMS, payload: data };
};
export const DeleteItemIndividualDetail = (data) => {
  return { type: types.STORE_DELETE_INDIVIDUAL_ITEMS, payload: data };
};
export const AllItemDetailToPay = (data) => {
  return { type: types.STORE_ALLITEM_DETAIL_TOPAY, payload: data };
};
export const StoreBussinessUrl = (data) => {
  return { type: types.STORE_BUSSINESS_URL, payload: data };
};

export const storeCategoryDetail = (data) => {
  return { type: types.STORE_CATEGORY_DETAIL, payload: data };
};

export const storeBussinessId = (data) => {
  return { type: types.STORE_BUSSINESS_ID, payload: data };
};
export const storeSkipOptionData = (data) => {
  return { type: types.STORE_SKIP_OPTION, payload: data };
};
export const storeThemeColor = (data) => {
  return { type: types.STORE_THEME_COLOR, payload: data };
};
export const storeParentId = (data) => {
  return { type: types.STORE_PARENT_ID, payload: data };
};
export const storeBussinessLocationId = (data) => {
  return { type: types.STORE_BUSSINESS_LOCATIONID, payload: data };
};
export const storeLocationTimeZone = (data) => {
  return { type: types.STORE_LOCATION_TIME_ZONE, payload: data };
};
export const storeShowCount = (data) => {
  return { type: types.STORE_SHOW_COUNT, payload: data };
};
export const storeInsideWordpress = (data) => {
  return { type: types.STORE_INSIDE_WORDPRESS, payload: data };
};
export const storeWordpressBacktoStop = (data) => {
  return { type: types.STORE_WORDPRESS_BACK_TO_SHOP, payload: data };
};