import React, { Component } from "react";
import {
  Button,
  Grid,
  Header,
  Container,
  Icon,
  Dimmer,
  Loader,
  Image,
  Popup,
} from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
} from "../../shared/functional/global-import";
import AddCartModal from "../../shared/components/organisms/modal/add-cart/index";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import CheckoutModal from "../../shared/components/organisms/modal/checkout-modal";
class ProductDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      directWordpressCheckout: false,
      productName: "",
      allProductImages: [],
      cancellationPolicy: "",
      price: null,
      requirements: "",
      description: [],
      quantity: null,
      itemId: null,
      addItem: [],
      customFieldData: [],
      startTime: "",
      startAm: "",
    };
  }

  componentDidMount() {
    this.getItemDetailSummary();
  }

  getItemDetailSummary = () => {
    this.props.actions.apiCall({
      urls: ["BUSINESSCATEGORYWEBSITEWIDGET"],
      method: "GET",
      data: {
        ProductId: this.props.productDetail.productId,
        BusinessCategoryType: this.props.productDetail.businessCategoryType,
      },
      onSuccess: (response) => {
        if (response[0].shopStartTime) {
          const [hours, amPM] = response[0].shopStartTime.split(":00");
          this.setState({ startTime: hours, startAm: amPM });
        }

        if (response[0].cancellationPolicy !== null) {
          this.setState({
            cancellationPolicy: JSON.parse(response[0].cancellationPolicy)[0]
              .CancellationPolicyDescription,
          });
        }
        if (response[0].requirements !== null) {
          this.setState({
            requirements: response[0].requirements,
          });
        }
        if (response[0].quantity !== null) {
          this.setState({
            quantity: response[0].quantity,
          });
        }
        if (response[0].images !== null) {
          let newImagesDetails = JSON.parse(response[0].images).map(
            (singleImage) => {
              singleImage.image = commonFunctions.concatenateImageWithAPIUrl(
                singleImage.image
              );
              return singleImage;
            }
          );
          this.setState({
            allProductImages: newImagesDetails.reverse(),
          });
        }
        if (response[0].descriptions !== null) {
          this.setState({
            customFieldData: JSON.parse(response[0].descriptions)[0]
              .Customfiels,
          });
        }
        this.setState({
          addItem: response[0],
          productName: response[0].productName,
          price: response[0].price,
          description: JSON.parse(response[0].descriptions),
          itemId: response[0].productId,
        });
      },
    });
  };

  AddCartModal = (e) => {
    this.setState({ cartStatus: !this.state.cartStatus, addItem: e });
  };

  wordpressAddToCart = () => {
     
    this.setState({
      directWordpressCheckout: true,
      cartStatus: false,
      addItem: [],
    });
  };
  onCloseWordpressDirectCheckout = () => {
                                  
    this.props.actions.DeleteItemIndividualDetail();
    this.props.closeWordpressModal();
  };
  backToWordpressModal = () => {
                         
    this.setState({ directWordpressCheckout: false });
    this.props.actions.DeleteItemIndividualDetail();
    this.props.getBusinessCategoryWebsiteWidget();
  };

  render() {
    const { api, wordpressModal } = this.props;
    const allProductImage =
      this.state.allProductImages.length === 0
        ? false
        : this.state.allProductImages;
    const {
      productName,
      description,
      quantity,
      cancellationPolicy,
      cartStatus,
      itemId,
      addItem,
      customFieldData,
      startTime,
      startAm,
      directWordpressCheckout,
    } = this.state;
    const {
      allProducts,
      productDetail,
      backButtonInWidget,
      isShowAvailableItem,
      isShowAvailabletickets,
      closeWordpressModal,
    } = this.props;

    return (
      <div>
        {api.isApiLoading && (
          <div>
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          </div>
        )}
        <Grid.Column computer={4} tablet={8} mobile={16}>
          <Popup
            content="Back to Items"
            position="right center"
            trigger={
              <Icon
                onClick={() => this.props.backtoitem()}
                name="arrow alternate circle left outline"
                className={`${
                  backButtonInWidget
                    ? "backButtonInWidget"
                    : "backButtonInWordpress"
                }`}
                size="big"
              />
            }
          />
        </Grid.Column>
        {/* } */}
        <Container className="productDetail">
          <Grid>
            <Grid.Column computer={5} tablet={5} mobile={16}>
              <Swiper
                loop={true}
                Navigation={true}
                spaceBetween={11}
                navigation={true}
                // className="mySwiper2"
              >
                {allProductImage &&
                  allProductImage.map((singleObj) => {
                     
                    return (
                      <SwiperSlide Navigation={true}>
                        {singleObj.ImageId === 0 ? null : (
                          <Image
                            id={singleObj.ImageId}
                            src={singleObj.image}
                            fluid
                          />
                        )}
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
              <Swiper
                loop={true}
                navigation={true}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                className="mySwiper3"
              >
                {allProductImage &&
                  allProductImage.map((singleObj) => {
                       
                    return (
                      <SwiperSlide Navigation={true}>
                        {singleObj.ImageId === 0 ? null : (
                          <Image src={singleObj.image} fluid />
                        )}
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </Grid.Column>
            <Grid.Column computer={11} tablet={11} mobile={16}>
              <div className="productInfo">
                <Header as="h1">{productName}</Header>
                {description &&
                  description.map((e) => {
                    return (
                      <>
                        {e.PickUpInstructions && (
                          <>
                            {e.PickUpInstructions === "" ? null : (
                              <p className="productInfoOrInstruction">
                                <b>Pickup Instructions : </b>
                                <pre className="Wraptext">
                                  {e.PickUpInstructions}
                                </pre>
                              </p>
                            )}
                          </>
                        )}
                        {e.DropOffInstructions && (
                          <>
                            {e.DropOffInstructions === "" ? null : (
                              <p className="productInfoOrInstruction">
                                <b>Drop off Instructions : </b>
                                <pre className="Wraptext">
                                  {e.DropOffInstructions}
                                </pre>
                              </p>
                            )}
                          </>
                        )}
                        {e.WhatisIncluded && (
                          <>
                            {e.WhatisIncluded === "" ? null : (
                              <p className="productInfoOrInstruction">
                                <b>What is included ? : </b>
                                <pre className="Wraptext">
                                  {e.WhatisIncluded}
                                </pre>
                              </p>
                            )}
                          </>
                        )}
                        {e.WhattoKnow && (
                          <>
                            {e.WhattoKnow === "" ? null : (
                              <p className="productInfoOrInstruction">
                                <b>What to know and Bring ? : </b>
                                <pre className="Wraptext">{e.WhattoKnow}</pre>
                              </p>
                            )}
                          </>
                        )}
                        {e.ActivityWhattoknowandbring && (
                          <>
                            {e.ActivityWhattoknowandbring === "" ? null : (
                              <p className="productInfoOrInstruction">
                                <b>What to know and Bring ? : </b>
                                <pre className="Wraptext">
                                  {e.ActivityWhattoknowandbring}
                                </pre>
                              </p>
                            )}
                          </>
                        )}
                        {e.ActivityWhatIsIncluded && (
                          <>
                            {e.ActivityWhatIsIncluded === "" ? null : (
                              <p className="productInfoOrInstruction">
                                <b>What is included ? : </b>
                                <pre className="Wraptext">
                                  {e.ActivityWhatIsIncluded}
                                </pre>
                              </p>
                            )}
                          </>
                        )}
                        {e.ItemDescription && (
                          <>
                            {e.ItemDescription === "" ? null : (
                              <p className="productInfoOrInstruction">
                                <b>Item Description : </b>
                                <pre className="Wraptext">
                                  {e.ItemDescription}
                                </pre>
                              </p>
                            )}
                          </>
                        )}
                        {e.ActivityDescription && (
                          <>
                            {e.ActivityDescription === "" ? null : (
                              <p className="productInfoOrInstruction">
                                <b>Activity Description : </b>
                                <pre className="Wraptext">
                                  {e.ActivityDescription}
                                </pre>
                              </p>
                            )}
                          </>
                        )}
                        {e.BundleDescription && (
                          <>
                            {e.BundleDescription === "" ? null : (
                              <p className="productInfoOrInstruction">
                                <b>Bundle Description : </b>
                                <pre className="Wraptext">
                                  {e.BundleDescription}
                                </pre>
                              </p>
                            )}
                          </>
                        )}
                      </>
                    );
                  })}
                {customFieldData && customFieldData.length > 0
                  ? customFieldData.map((a) => {
                      return (
                        <>
                          <p className="productInfoOrInstruction">
                            <b>{a.customField} : </b>
                            <pre className="Wraptext">
                              {a.customDescriptions}
                            </pre>
                          </p>
                        </>
                      );
                    })
                  : ""}

                {isShowAvailableItem ? (
                  <>
                    {quantity === 0 ? null : (
                      <p className="productInfoOrInstruction">
                        <>
                          <b>Quantity:</b>
                          <span>
                            <b>{quantity}</b>
                          </span>
                        </>
                      </p>
                    )}
                  </>
                ) : (
                  ""
                )}
                {cancellationPolicy && (
                  <>
                    {cancellationPolicy === "" ? null : (
                      <p className="productInfoOrInstruction">
                        <b>Cancellation Policy : </b>
                        <pre className="Wraptext"> {cancellationPolicy}</pre>
                      </p>
                    )}
                  </>
                )}
                {itemId && (
                  <div className="textRight textButtonCenter">
                    {" "}
                    <Button
                      className="primaryBtn"
                      onClick={() => this.AddCartModal(productDetail)}
                    >
                      <Icon name="cart plus" link /> Add to Cart
                    </Button>
                  </div>
                )}
              </div>
            </Grid.Column>
          </Grid>
        </Container>
        {/*  item details */}
        {cartStatus && (
          <AddCartModal
            wordpressModal={wordpressModal === undefined ? false : wordpressModal}
            wordpressAddToCart={this.wordpressAddToCart}
            history={this.props.history}
            openModal={cartStatus}
            isShowAvailableItem={isShowAvailableItem}
            isShowAvailabletickets={isShowAvailabletickets}
            closeModal={this.AddCartModal}
            addItem={addItem}
            productDetail={productDetail}
            allFuction={this.props.allFuction}
            allProducts={allProducts}
            itemId={this.state.itemId}
            startTime={startTime}
            startAm={startAm}
            insideWordpress={this.state.insideWordpress}
          />
        )}
        {directWordpressCheckout && (
          <CheckoutModal
            openModal={directWordpressCheckout}
            closeModal={this.backToWordpressModal}
            individualItem={true}
            allFuction={this.props.allFuction}
            backToWordpressModal={this.backToWordpressModal}
            closeWordpressModal={closeWordpressModal}
            insideWordpress={this.state.insideWordpress}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      storeShowCount: bindActionCreators(
        actions.storeShowCount,
        dispatch
      ),
      DeleteItemIndividualDetail: bindActionCreators(
        actions.DeleteItemIndividualDetail,
        dispatch
      ),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailPage);
