import React, { Component } from 'react';
import { Header } from '..';


class AuthenticateUserTemplate extends Component {
    render() {
        const { children } = this.props;
        return ( 
             <React.Fragment>
                    <Header />
                   {children}
            </React.Fragment>
        );
    }
}


export default AuthenticateUserTemplate;