import React, { Component } from "react";
import {
  Container,
  Grid,
  Button,
  Header,
  Image,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import {
  connect,
  commonFunctions,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../shared/functional/global-import";
import moment from "moment";
class WaiverDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isApiLoading: true,
    };
  }
  componentDidMount() {
    this.getDocumentDetails();
  }
  getDocumentDetails = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const customerid = queryParams.get("customerid");
    this.props.actions.apiCall({
      urls: ["GETREGISTEREDWAIVER"],
      method: "GET",
      data: {
        CustomerId: customerid,
      },
      onSuccess: (response) => {
        this.setState({ isApiLoading: true });
        this.setState(response);
      },
      onFinally: () => {
        this.setState({ isApiLoading: false });
      },
    });
  };

  downloadFile(url) {
    var link = document.createElement("a");
    link.href = url;
    link.download = "file.pdf";
    link.target = "_blank";
    link.dispatchEvent(new MouseEvent("click"));
  }

  render() {
    const waiverSigner =
      this.state?.waivers?.length !== undefined &&
      JSON.parse(this.state.waivers)
        .filter((a) => a.MainSigner === true)
        .map((waiver) => waiver);
    return (
      <>
        {this.state.isApiLoading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        <Container>
          <Grid className="waiver-details">
            <Grid.Row columns={1} className="document-details">
              <Grid.Column>
                <div className="Export-pdf">
                  <Button
                    type="submit"
                    onClick={() =>
                      this.downloadFile(
                        commonFunctions.concatenateImageWithAPIUrl(
                          this.state.documentPDF
                        )
                      )
                    }
                  >
                    EXPORT TO PDF
                  </Button>
                </div>
                <div className="waiverDocumentHeader">Waiver</div>
                <Header className="documentNameHeader">
                  {this.state.documentName}
                </Header>
                <Grid.Column width={16}>
                  <div
                    style={{
                      padding: "10px 0",
                      width: "100%",
                      height: "600px",
                    }}
                  >
                    <iframe
                      src={`https://docs.google.com/gview?url=${encodeURIComponent(
                        commonFunctions.concatenateImageWithAPIUrl(
                          this.state.document
                        )
                      )}&embedded=true`}
                      style={{ width: "100%", height: "100%" }}
                      frameborder="0"
                    ></iframe>
                  </div>
                </Grid.Column>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2} className="document-details">
              <Grid.Column>
                <Header>Email Address</Header>
                {this.state.email}
              </Grid.Column>
              <Grid.Column>
                <Header>Phone Number</Header>
                {this.state.phoneNumber}
              </Grid.Column>
            </Grid.Row>

            {this.state?.waivers?.length !== undefined &&
              JSON.parse(this.state.waivers).map((user, index) => {
                const customField = JSON.parse(user.CustomField);
                return (
                  <>
                    <div className="waiver-card">
                      <Header className="waiver-card-header">
                        {waiverSigner[0].FirstName}
                        &nbsp;
                        {waiverSigner[0].LastName}
                        <span>
                          &nbsp;&nbsp;&nbsp;&nbsp; Date Signed:&nbsp;&nbsp;
                          {user.SignedDate}
                        </span>
                      </Header>
                      <div className="waiver-card-body">
                        <Grid>
                          <Grid.Column width={8}>
                            <Grid.Row>
                              <Grid.Column width={16}>
                                <div className="waiver-card-content">
                                  <Header>First Name</Header>
                                  {user.FirstName}
                                </div>
                              </Grid.Column>
                              <Grid.Column width={16}>
                                <div className="waiver-card-content">
                                  <Header>Birth Date</Header>
                                  {moment(user.DOB).format("YYYY-MM-DD") ===
                                  "Invalid date"
                                    ? null
                                    : moment(user.DOB).format("YYYY/MM/DD")}
                                </div>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid.Column>
                          <Grid.Column width={8}>
                            <div className="waiver-card-content">
                              <Header>Last Name</Header>
                              {user.LastName}
                            </div>
                          </Grid.Column>
                          <Grid.Column width={16}>
                            <div className="jsonDataField">
                              {customField &&
                                customField.map((customData, index) => {
                                  return (
                                    <>
                                      <div className="waiver-card-content">
                                        {" "}
                                        <Header>
                                          {customData.CustomField}
                                        </Header>
                                        {customData.CustomAnswer}
                                      </div>
                                    </>
                                  );
                                })}
                            </div>
                          </Grid.Column>
                          <Grid.Column width={16}>
                            <div className="waiver-card-content">
                              <Header className="bold">Signature</Header>
                              <div className="Signature">
                                <Image src={user.SignatureImage}></Image>
                              </div>
                            </div>
                          </Grid.Column>
                        </Grid>
                      </div>
                    </div>
                  </>
                );
              })}
          </Grid>
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WaiverDetails);
