import React, { Component } from "react";
import { Button, Modal, Grid, Icon, Header } from "semantic-ui-react";

class OrderErrorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      time: "",
      dateTime: "",
      datesRange: "",
      closeModal: false,
    };
  }

  handleChange = () => {
    this.setState({ closeModal: !this.state.closeModal });
  };
  render() {
    return (
      <Modal
        open={this.props.openModal}
        onClose={this.props.closeModal}
        closeOnDimmerClick={false}
        size={"mini"}
      >
        <Modal.Content scrolling className="orderPlaceErrorPopup">
          <Modal.Description className="orderPlaced">
            <Grid textAlign="center">
              <Grid.Column width={16}>
                <div className="orderPlaceError">
                  <Icon name="close" />
                </div>
                <Header as="h2">Error Placing Order</Header>
                <p>
                  An error occured while placing order, please verify credit
                  card info and try again
                </p>
              </Grid.Column>
              <Grid.Column width={16}>
                <Button className="primaryBtn" onClick={this.props.onclose}>
                  Ok
                </Button>
              </Grid.Column>
            </Grid>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default OrderErrorModal;
