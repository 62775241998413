import React, { Component } from "react";
import { RangePicker } from "react-trip-date";

class RentalRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let r = document.querySelector(":root");
    let p = getComputedStyle(r);
    const theme = {
      primary: {
        light: p.getPropertyValue("--main-color"),
        main: p.getPropertyValue("--main-color"),
        dark: p.getPropertyValue("--main-color"),
      },

      grey: {
        700: "#707070",
        900: "#1b1b1d",
      },

      background: {
        default: "#f54952",
      },

      text: {
        disabled: "#BABABA",
      },
    };

    const {
      initialMonthAndYear,
      today,
      Day,
      fullstate,
      allFuction,
      handleResponsive,
      selctedDateshow,
    } = this.props;
    return (
      <>
        <RangePicker
          onRangeDateInScreen={allFuction.onRangeDateInScreen}
          onChange={allFuction.onChange}
          numberOfMonths={1}
          theme={theme}
          initialMonthAndYear={initialMonthAndYear}
          allowDisabledDaysSpan={true}
          disabledBeforeDate={today}
          numberOfSelectableDays={10}
          selectedDays={{
            from: selctedDateshow,
            to: fullstate.rangeEndDate,
          }}
          responsive={handleResponsive}
          disabledBeforeToday={true}
          disabledDays={fullstate.nonAvalableDAtes}
          disabled={false}
          dayComponent={Day} 
          titleComponent="{Title}"
        />
      </>
    );
  }
}
export default RentalRangePicker;
