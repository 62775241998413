import React, { Component } from "react";
import ContactPage  from './contact';

class Contact extends Component {
		
      render(){
          return(
              <ContactPage />
          )
      }
    }
export default Contact;