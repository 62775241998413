import React, { Component } from "react";
import { Table, Image, Form, Icon, Header, Divider } from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  Notifications,
  actions,
} from "../../shared/functional/global-import";

class StepSix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalprice: "",
      amount1: 0,
      deposit: 0,
    };
  }

  componentDidMount() {
    this.addQuantity();
    this.showTotalAmount();
  }

  showTotalAmount = () => {
    const aa =
      this.props.allValue &&
      this.props.allValue.reduce(
        (previousScore, currentScore, index) =>
          previousScore + JSON.parse(currentScore.addOnTotalPrice),
        0
      );
    const ak = aa === undefined ? 0 : aa;
    const totalprice = JSON.parse(
      (this.props.allFuction.state.totalPriceToShow + ak).toFixed(2)
    );

    const addToCArtOrNot =
      this.props.allFuction.state.tickets.reduce(
        (total, currentValue) => (total = total + currentValue.numberOfTickets),
        0
      ) === 0
        ? false
        : true;
    this.props.allFuction.setState({ addToCArtOrNot });
    this.props.allFuction.setState({ totalprice });
    if (this.props.allFuction.state.addItem.businessCategoryType === 2) {
      if (this.props.allFuction.state.count > 0) {
        this.props.allFuction.setState({ addToCArtOrNot: true });
      }
    }
    if (this.props.allFuction.state.addItem.businessCategoryType === 1) {
      if (this.props.allFuction.state.count > 0) {
        this.props.allFuction.setState({ addToCArtOrNot: true });
      }
    }

    const allGlobalCodes = JSON.parse(localStorage.getItem("allGlobalCodes"));
    if (
      this.props.allFuction.state.addItem.businessCategoryType ===
      commonFunctions.getGlobalCodeDetails(
        allGlobalCodes,
        "BusinessCategoryType",
        "Season Pass"
      ).globalCodeId
    ) {
      if (this.props.allFuction.state.count > 0) {
        this.props.allFuction.setState({ addToCArtOrNot: true });
      }
    }
  };

  addQuantity = () => {
    const { allValue, addOn, allFuction } = this.props;
    const totalQuantity = allValue?.reduce(
      (total, item) => total + item.quantity,
      0
    );
    if (totalQuantity === undefined) {
      const newAllValue = addOn?.map((addOns) => ({
        ...addOns,
        quantity: 0,
        addOnTotalPrice: 0,
      }));
      allFuction.setState({ allValue: newAllValue });
    }
  };

  onHandleMinus = (index) => {
    const allValue = this.props.allValue;

    if (allValue[index]["quantity"] >= 1) {
      if (allValue[index]["quantity"] === allValue[index].minValue) {
        allValue[index]["quantity"] = 1;
      }
      allValue[index]["quantity"] = allValue[index]["quantity"] - 1;
      this.props.allFuction.setState({ allValue: [...allValue] });
    }
    if (allValue[index].quantity >= 0 || allValue[index].quantity !== 0) {
      allValue[index].addOnTotalPrice =
        allValue[index].quantity * allValue[index].Price;
      this.props.allFuction.setState({
        addOnTotalPrice: allValue[index].addOnTotalPrice,
      });
      this.props.allFuction.setState({ allValue: [...allValue] });
    }
    this.showTotalAmount();
  };
  onHandlePlus = (index) => {
    const allValue = this.props.allValue;

    if (allValue[index]["maxAddOns"] !== null) {
      if (allValue[index]["maxAddOns"] <= allValue[index]["quantity"]) {
        this.props.actions.showNotification(
          {
            title: "Warning",
            message: `You can't add more Addons for this.`,
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
        return false;
      }
    }
    if (allValue[index]["minAddOns"] !== null) {
      if (allValue[index]["quantity"] === 0) {
        allValue[index]["quantity"] = allValue[index]["minAddOns"];
        this.props.actions.showNotification(
          {
            title: "success",
            message: `This Addons required min. is ${allValue[index]["minAddOns"]}.`,
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "success"
        );
      } else {
        allValue[index]["quantity"] = allValue[index]["quantity"] + 1;
      }
    } else if (allValue[index]["minAddOns"] == null) {
      allValue[index]["quantity"] = allValue[index]["quantity"] + 1;
    }
    this.props.allFuction.setState({ allValue: [...allValue] });
    if (allValue[index].quantity > 0 || allValue[index].quantity === 0) {
      allValue[index].addOnTotalPrice =
        allValue[index].quantity * allValue[index].Price;
      this.props.allFuction.setState({
        addOnTotalPrice: allValue[index].addOnTotalPrice,
      });
      this.props.allFuction.setState({ allValue: [...allValue] });
    }
    this.showTotalAmount();
  };
  render() {
    return (
      <>
        <div className="commonTable">
          <Table unstackable singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell scope="col">Image</Table.HeaderCell>
                <Table.HeaderCell scope="col">Qty</Table.HeaderCell>
                <Table.HeaderCell scope="col">Price Per</Table.HeaderCell>
                <Table.HeaderCell scope="col">Name</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body className="customerDetailsScreen">
              {this.props.allValue && (
                <>
                  {this.props.allValue.map((number, index) => {
                     
                    return (
                      <Table.Row>
                          <Table.Cell data-label="Image">
                            <Image
                              src={commonFunctions.concatenateImageWithAPIUrl(
                                number.Image
                              )}
                              className="img-add-ons"
                            />
                          </Table.Cell>
                      
                        <Table.Cell data-label="Qty">
                          <Form className="quantity-form">
                            <Form.Field className="add-numbers">
                              <Icon
                                name="minus"
                                onClick={() => this.onHandleMinus(index)}
                                rowId={index}
                                className="orange-button"
                              ></Icon>
                              <Form.Input
                                readOnly
                                placeholder="Quantity"
                                value={number.quantity}
                                className="input-align"
                              />
                              <Icon
                                name="plus"
                                className="orange-button plus-btn"
                                rowId={index}
                                onClick={() => this.onHandlePlus(index)}
                              ></Icon>
                            </Form.Field>
                          </Form>
                        </Table.Cell>
                        <Table.Cell data-label="Price Per">
                          <span className="detailContent">
                            {commonFunctions.isNumberInteger(number.Price)}
                          </span>
                        </Table.Cell>
                        <Table.Cell data-label="Name">
                          <span className="detailContent">
                            {number.AddOnTitle}
                          </span>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </>
              )}
            </Table.Body>
          </Table>
        </div>
        <Divider hidden />
        <div className="totalAmountAddOn">
          {commonFunctions.isNumberInteger(
            this.props.allFuction.state.totalprice
          ) ? (
            <Header as="h4" className="durationSubCaption">
              Total :{" "}
              <span className="itemValue">
                {commonFunctions.isNumberInteger(
                  this.props.allFuction.state.totalprice
                )}
              </span>
            </Header>
          ) : (
            ""
          )}
        </div>
        <Divider hidden />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StepSix);
