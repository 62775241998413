import SimpleReactValidator from "simple-react-validator";
import { Label } from "semantic-ui-react";
import { env } from "../functional/global-import";
import moment from "moment";

const onFormChange = (allData) => {
  let warnUserState = allData.props.warnUserState;
  if (warnUserState !== undefined) {
    warnUserState.isFormChanged = true;
    allData.props.actions.storeWarnUser(warnUserState);
  }
};
const onValidateMinMaxPerItem = (productCount, min, max) => {
  const isMaxHaveValue = max === null ? false : true;
  const isMaxConditionFulfiled = isMaxHaveValue ? productCount <= max : true;
  if (productCount >= min && isMaxConditionFulfiled) {
    return true;
  } else {
    return false;
  }
};
// This function is used to handle common onchange in all the forms in the application.
const onHandleChange = (
  e,
  { name, value, type, checked, data },
  obj,
  allData
) => {
  if (allData) {
    onFormChange(allData);
  }
  const path = data.split(".");
  const depth = path.length;
  const state = { ...obj };
  let ref = state;
  for (let i = 0; i < depth; i += 1) {
    if (i === depth - 1) {
      if (
        type === "text" ||
        type === "radio" ||
        type === "password" ||
        type === "select" ||
        type === "textarea" ||
        type === "multiple-select" ||
        type === "phoneNumber" ||
        type === "number"
      ) {
        ref[path[i]] = value;
      } else if (type === "select-int") {
        ref[path[i]] = parseInt(value);
      } else if (type === "checkbox") {
        ref[path[i]] = checked;
      }
    } else {
      ref = ref[path[i]];
    }
  }
  return state;
};

// This function is used to handle common form submit in all the forms in the application.
const onHandleFormSubmit = (e, formObj) => {
  e.preventDefault();
  if (formObj.validator.allValid() === false) {
    formObj.validator.showMessages();
    formObj.forceUpdate();
    return false;
  } else {
    return true;
  }
};

const isNumberInteger = (value) => {
  if (value !== null && value !== undefined) {
    let a = value;
    if (typeof value === "number") {
      a = JSON.stringify(value);
    }
    let b = a.split(".");
    if (b[0].length === 0) {
      return (
        "$" +
        (b[1] !== undefined
          ? b[1].length === 1
            ? "0" + value + "0"
            : "0" + value
          : "0" + value + ".00")
      );
    } else {
      return (
        "$" +
        (b[1] !== undefined
          ? b[1].length === 1
            ? value + "0"
            : value
          : value + ".00")
      );
    }
  } else {
    return "-";
  }
};
const initializeSimpleValidator = () => {
  return new SimpleReactValidator({
    element: (message) => (
      <Label basic color="red" pointing="above">
        {message}
      </Label>
    ),
    validators: {
      // For Custom error Message in confirm Passcode field
      validConfirmPasscode: {
        message: "The 'Passcode' and 'Confirm Passcode' doesn't match'",
        rule: (val, params, validator) => {
          if (params[0] !== "undefined" && params[0].length > 1) {
            return val === params[0];
          }
        },
        required: true, // optional
      },
      // For Custom error message in confirm Password field
      validConfirmPassword: {
        message: "The 'Password' and 'Confirm Password' doesn't match'",
        rule: (val, params, validator) => {
          if (params[0] !== "undefined" && params[0].length > 1) {
            return val === params[0];
          }
        },
        required: true, // optional
      },
      validRoles: {
        message: "Must be Select One Roles",
        rule: (val, params, validator) => {
          if (params[0].length > 1) {
            return val === params[0];
          }
        },
        required: true,
      },
    },
  });
};

const initializeSimpleValidatorCommon = () => {
  return new SimpleReactValidator({
    element: (message) => (
      <Label basic color="red" pointing="above">
        {message}
      </Label>
    ),
  });
};

// This function is used to handle common form submit in all the forms in the application.
const onHandleFormSubmitCommon = (e, formObj) => {
  e.preventDefault();
  if (formObj.validator.allValid() === false) {
    formObj.validator.showMessages();
    formObj.forceUpdate();
    return false;
  } else {
    return true;
  }
};

// This function is used for image URL concatnation
const concatenateImageWithAPIUrl = (Image) => {
  if (Image !== "" && typeof Image === "string") {
    return `${env.IMG_URL}${Image}`}
  // else {
  // return `${noImage}`;
  // return `${env.API_URL?.replace("/api", "")}${Image}`;
  // }
};

const getFormData = (data) => {
  var formData = new FormData();
  Object.entries(data).map(function ([key, val]) {
    if (typeof val !== "undefined") {
      // if (key === "image" && val !== null) {
      //   formData.append(key, val[0].file);
      // }
      if (key === "image" && val !== null && data.image.length > 0) {
        let file = val[0].file === undefined ? val[0] : val[0].file;
        formData.append(key, file);
      } else if (
        key === "businessLocationCategories" ||
        key === "businessCategories" ||
        key === "userLocations" ||
        key === "documentBusinessCategoryType" ||
        key === "categoryTag"
      ) {
        for (var i = 0; i < val.length; i++) {
          formData.append(key, val[i]);
        }
      } else if (key === "images") {
        for (var index = 0; i < val.length; i++) {
          let file = val[i].file === undefined ? val[index] : val[index].file;
          formData.append(key, file);
        }
      } else {
        formData.append(key, val);
      }
    }
    return null;
  });
  return formData;
};

const formattedDate = (date) => {
  return moment(date).format("MMM DD, YYYY");
};

const saveFormattedDate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};
// const showLoader = () => {
//   return (
//     <Dimmer active inverted>
//       <Loader />
//     </Dimmer>
//   )
// };

const getSingleValue = (globalCodeData, categoryType, codeName) => {
  const singlevalue = globalCodeData.filter(
    (code) => code.categoryName === categoryType && code.codeName === codeName
  );
  return singlevalue[0].categoryId;
};

const getGlobalCodeDetails = (globalCodeData, categoryType, codeName) => {
  const singlevalue = globalCodeData.filter((code) => {
    return code.categoryName === categoryType && code.codeName === codeName;
  });
  return singlevalue[0];
};

const notificationTime = () => {
  let newNotificationTime = 8;
  return newNotificationTime;
};
const onCheckDifferentLocationItem = (
  allProductDetail,
  businessLocationIdOfItem,
  showNotification
) => {
  const BusinessLocationId = allProductDetail.products.map(function (el) {
    var o = el.addItem.businessLocationId;
    return o;
  });
  // return BusinessLocationId.every((x) => x === businessLocationIdOfItem);
  const isItemLocationIsSame = BusinessLocationId.every(
    (x) => x === businessLocationIdOfItem
  );
  if (isItemLocationIsSame) {
    return isItemLocationIsSame;
  } else {
    showNotification(
      {
        title: "Warning",
        message:
          "There are items in cart from other location , please remove them to add this item.",
        position: "br",
        autoDismiss: notificationTime(),
      },
      "error"
    );
    return isItemLocationIsSame;
  }
};



///
const findFirstMissingDate = (nonAvailableDates) => {
  const today = new Date();
  const firstDate = nonAvailableDates[0];
  const year = parseInt(firstDate.substring(0, 4), 10);
  const month = parseInt(firstDate.substring(5, 7), 10) - 1; // Subtract 1 to convert to zero-based index
  if (today.getFullYear() === year && today.getMonth() === month) {
    today.setFullYear(year, month, today.getDate());
} else {
    today.setFullYear(year, month, 1);
}
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  for (let day = today.getDate(); day <= daysInMonth; day++) {
    const currentDate = new Date(year, month, day);

    const formattedDate = `${currentDate.getFullYear()}-${String(
      currentDate.getMonth() + 1
    ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

    if (!nonAvailableDates.includes(formattedDate)) {
      return formattedDate;
    }
  }

  return null;
};



// const findFirstMissingDate = (nonAvailableDates) => {
//   const today = new Date();
//   const firstDate = nonAvailableDates[0];
//   const year = parseInt(firstDate.substring(0, 4), 10);
//   const month = parseInt(firstDate.substring(5, 7), 10) - 1; // Subtract 1 to convert to zero-based index
//   today.setFullYear(year, month, 1); // Set today to the beginning of the month

//   const daysInMonth = new Date(year, month + 1, 0).getDate();

//   for (let day = 1; day <= daysInMonth; day++) {
//     const currentDate = new Date(year, month, day);

//     const formattedDate = `${currentDate.getFullYear()}-${String(
//       currentDate.getMonth() + 1
//     ).padStart(2, "0")}-${String(currentDate.getDate()).padStart(2, "0")}`;

//     if (!nonAvailableDates.includes(formattedDate)) {
//       return formattedDate;
//     }
//   }

//   return null;
// };















export const commonFunctions = {
  findFirstMissingDate,
  onHandleChange,
  onHandleFormSubmit,
  initializeSimpleValidator,
  onHandleFormSubmitCommon,
  initializeSimpleValidatorCommon,
  concatenateImageWithAPIUrl,
  getFormData,
  formattedDate,
  saveFormattedDate,
  getSingleValue,
  getGlobalCodeDetails,
  isNumberInteger,
  onFormChange,
  notificationTime,
  onValidateMinMaxPerItem,
  onCheckDifferentLocationItem,
  // showLoader
};
