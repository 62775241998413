import React, { Component } from "react";
import {
  Grid,
  Table,
  Divider,
  Header,
  Icon,
  Button,
  Form,
  Select,
  GridColumn,
  Dimmer,
} from "semantic-ui-react";
import { DatePicker } from "react-trip-date";
import moment from "moment";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../shared/functional/global-import";
import { ActivityBookTicket } from "../../shared/components";
const handleResponsive = (setNumberOfMonth) => {
  let width = document.querySelector(".tp-calendar").clientWidth;
  if (width > 900) {
    setNumberOfMonth(3);
  } else if (width < 900 && width > 580) {
    setNumberOfMonth(2);
  } else if (width < 580) {
    setNumberOfMonth(1);
  }
};
const Day = ({ day }) => {
  return (
    <> 
    <p className="date">{day.format("DD")}</p>
    <p className="date">7</p>
    </>
  );
};

class StepTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      time: "",
      selectTime: false,
      dateTime: "",
      datesRange: "",
      count: 0,
      hideBundle: false,
      bundleTotalprice: "",
      name: [],
      hasRendered: false,
      nonAvalableDAtes: [],
      tickets: [],
      timeslotsforproduct: [],
      timeslotsforproducts: [],
      increament: 0,
      isFetching: false,
      activityPricingId: {
        ActivityPricingId: -1,
        ActivityId: this.props.addItem.productId,
        BusinessId: 1,
        BusinessLocationId: this.props.businessLocationId,
        PageNo: 1,
        PageSize: 100,
      },
      showSelectedBlackout: null,
      defaultSelectedDate: "",
      selectedDate: "",
      firstMissingDate: "",
      bookingSlot: {
        startTimeHour: "7",
        startTimeMinute: "00",
        startTimeAm: "am",
        endTimeHour: "9",
        endTimeMinute: "00",
        endTimeAm: "am"},
      isCalculate: true,
      ticketBooked: 0,
      currentTime: "",
      currentValueTime: null,
      firstAvailableDate: null,
    };
  }
  onChange = (date) => {
    const { allFuction } = this.props;
    const selectedTime = allFuction.state.selectedTime;
           
    this.setState({
      selectedTime: allFuction.state.selectedTime,
      selectedDate: date[0],
      currentTime: date[0],
    });
    this.props.getNewActivityTimeSlots(selectedTime, date && date[0]);
    if (this.props.allFuction.state.addItem.businessCategoryType === 3) {
      if (date.length === 0) {
        this.props.allFuction.setState({
          currentTime: "",
          selectedTime: "",
          addToCArtOrNot: false,
        });
      } else {
        this.props.allFuction.setState({
          currentTime: date[0],
          selectedTime: "",
          ticketsTable: false,
          ticketsDetails: "",
        });
        this.setState({ ticketBooked: 0, currentValueTime: date[0] });
      }
      this.props.allFuction.setState({ tickets: [], addToCArtOrNot: false });
    } else {
      if (date.length === 0) {
        this.props.allFuction.setState({
          currentTime: "",
          addToCArtOrNot: false,
          count: 0,
          totalprice: 0,
          totalPriceToShow: 0,
        });
        this.setState({ hideBundle: false });
        this.props.allFuction.setState({ bundleProduct: false });
      } else {
        this.props.allFuction.setState({
          ...allFuction.state,
          currentTime: date[0],
          bundleProduct: false,
          addToCArtOrNot: false,
          count: 0,
          totalprice: 0,
          totalPriceToShow: 0,
        });
        // this.setState({ hideBundle: true });
        // this.props.allFuction.setState({ bundleProduct : true });
      }
    }
    // this.setState({ currentDate: allFuction.state.currentTime });
  };
  // getAvailibilityForBundle = () => {
  //   const { allFuction } = this.props;
  //   const { currentTime } = this.state;
  //   const selectedTime = allFuction.state.selectedTime;
  //   const { availableTime } = this.props;
  //   // this.props.allFuction.setState({ selectedTime: startTime });
  //   if (currentTime !== "" && this.props.BundleTimeSlots === null) {
  //     let startTime =
  //       availableTime.startTimeHour +
  //       ":" +
  //       availableTime.startTimeMinute +
  //       " " +
  //       availableTime.startTimeAm;
  //     this.props.actions.apiCall({
  //       urls: ["GETAVAILABILITYFORBUNDLE"],
  //       method: "GET",
  //       data: {
  //         InWidget: true,
  //         bundleId: this.props.addItem.productId,
  //         orderDateTime: currentTime + " " + startTime,
  //       },
  //       onSuccess: (response) => {
  //         this.setState({ hideBundle: true, bundlePrice: response.price });
  //         this.props.allFuction.setState({
  //           bundleProduct: true,
  //           selectedTime: startTime,
  //           singlebundlePrice: response.price,
  //         });
  //       },
  //       showNotification: true,
  //     });
  //   } else if (selectedTime !== "" && this.props.BundleTimeSlots !== null) {
  //     this.props.actions.apiCall({
  //       urls: ["GETAVAILABILITYFORBUNDLE"],
  //       method: "GET",
  //       data: {
  //         InWidget: true,
  //         bundleId: this.props.addItem.productId,
  //         orderDateTime: currentTime + " " + selectedTime,
  //       },
  //       onSuccess: (response) => {
  //         this.setState({ hideBundle: true, bundlePrice: response.price });
  //         this.props.allFuction.setState({ bundleProduct: true });
  //       },
  //       showNotification: true,
  //     });
  //   } else {
  //     this.props.actions.showNotification(
  //       {
  //         title: "Warning",
  //         message: "Please select a Date/time",
  //         position: "br",
  //         autoDismiss: commonFunctions.notificationTime(),
  //       },
  //       "error"
  //     );
  //   }
  // };

  onRangeDateInScreen = (window) => {
    const initialMonth = this.props.allFuction.state.availabilityStartDate;
    let initialMoment = moment(initialMonth).format("YYYY-MM-DD");
    this.props.actions.apiCall({
      urls: ["GETPRODUCTAVAILABILTY"],
      method: "GET",
      data: {
        StartDate: window.start ? window.start : initialMoment,
        ProductId: this.props.addItem.productId,
        BusinessCategoryType: this.props.addItem.businessCategoryType,
      },
      onSuccess: (response) => {
        let nonAvalableDAte =
          response &&
          response[0] &&
          response[0].notAvailableDays &&
          JSON.parse(response[0].notAvailableDays).map((post) => {
            return post.dates;
          });
        if (nonAvalableDAte) {
          let currentdate =
            this.props.currentDateFromApi === undefined
              ? new Date()
              : this.props.currentDateFromApi;
          let aa = moment(currentdate).format("YYYY-MM-DD");
          const { currentTime } = this.props.allFuction.state;
          if (currentTime !== aa) {
            this.props.allFuction.setState({ currentTime });
          } else if (nonAvalableDAte.indexOf(aa) > -1) {
            this.props.allFuction.setState({ currentTime: "" });
          } else {
            let currentdate =
              this.props.currentDateFromApi === undefined
                ? new Date()
                : this.props.currentDateFromApi;
            let aa = [];
            aa.push(moment(currentdate).format("YYYY-MM-DD"));
          }
          this.setState({
            nonAvalableDAtes: nonAvalableDAte,
          });
        } else {
          this.setState({
            nonAvalableDAtes: [],
          });
        }
        this.setState({ isFetching: true });

        const today = moment().format("YYYY-MM-DD");
        if (nonAvalableDAte === null && !this.state.hasRendered) {
          this.setState({
            firstAvailableDate: window.start < today ? today : window.start,
            hasRendered: true,
          });
          this.props.getNewActivityTimeSlots("", this.state.firstAvailableDate);
        }
        if (!this.state.hasRendered) {
          let firstAvailableDate =
            commonFunctions.findFirstMissingDate(nonAvalableDAte);
          if (firstAvailableDate) {
            this.setState({
              firstAvailableDate:
                firstAvailableDate <= initialMoment
                  ? initialMoment
                  : firstAvailableDate,
              hasRendered: true,
            });
          }
          this.props.getNewActivityTimeSlots("", this.state.firstAvailableDate);
        }
      },
    });
  };
  openCustomTimeModal = () => {
    this.setState({
      customTimeStatus: !this.state.customTimeStatus,
    });
  };

  onStartEndTimeContinue = () => {
    if (this.props.currentTime !== undefined) {
      const { bookingSlot } = this.state;
      let endTime =
        bookingSlot.endTimeHour +
        ":" +
        bookingSlot.endTimeMinute +
        bookingSlot.endTimeAm;
      let startTime =
        bookingSlot.startTimeHour +
        ":" +
        bookingSlot.startTimeMinute +
        bookingSlot.startTimeAm;
      if (endTime > startTime) {
        let data = {
          timeFrameRange: this.props.activityTimeSlots.timeRange,
          startDate: this.props.currentTime,
          timeRangeFrom: startTime,
          timeRangeTo: endTime,
        };
        this.props.showActivityTicket(data);
      } else {
        this.onShowingerror();
      }
    } else {
      this.onShowingerror();
    }
  };

  countHandleChangeMinusBundle = () => {
    const count = this.props.allFuction.state.count;
    if (count >= 1) {
      let number = count - 1;
      let bundleTotalprice = JSON.parse(
        (this.state.bundlePrice * number).toFixed(2)
      );
      this.setState({ count: number, bundleTotalprice: bundleTotalprice });
      this.props.allFuction.setState({
        count: number,
        totalprice: bundleTotalprice,
        totalPriceToShow: bundleTotalprice,
      });
      if (number <= 0) {
        this.props.allFuction.setState({ addToCArtOrNot: false });
      }
    }
  };

  countHandleChangebBndle = () => {
    const count = this.props.allFuction.state.count;
    let number = count + 1;
    let bundleTotalprice = JSON.parse(
      (this.state.bundlePrice * number).toFixed(2)
    );
    this.setState({ count: number, bundleTotalprice: bundleTotalprice });
    this.props.allFuction.setState({
      count: number,
      totalprice: bundleTotalprice,
      totalPriceToShow: bundleTotalprice,
    });
    if (number > 0) {
      this.props.allFuction.setState({ addToCArtOrNot: true });
    }
  };
  countHandleChangeMinus = (index) => {
    const { allFuction } = this.props;
    const tickets = allFuction.state.tickets;
    if (tickets[index]["numberOfTickets"] >= 1) {
      tickets[index]["numberOfTickets"] = tickets[index]["numberOfTickets"] - 1;
      if (
        tickets[index]["customField"].length > 0 &&
        tickets[index]["numberOfTickets"] > 0
      ) {
        tickets[index]["customFields"] = tickets[index]["customFields"].slice(
          0,
          tickets[index]["customFields"].length -
            tickets[index]["customField"].length
        );
        tickets[index]["customAnswers"] = tickets[index]["customAnswers"].slice(
          0,
          tickets[index]["customAnswers"].length -
            tickets[index]["customField"].length
        );
      }
      if (
        tickets[index]["customField"].length > 0 &&
        tickets[index]["numberOfTickets"] === 0
      ) {
        tickets[index]["customAnswers"] = [];
      }
      let totalPrice = 0;
      tickets.map((singleTicket) => {
        totalPrice =
          totalPrice + singleTicket.price * singleTicket.numberOfTickets;
        return singleTicket;
      });
      if (tickets[index]["numberOfTickets"] <= 0) {
        this.props.allFuction.setState({
          ...allFuction.state,
          tickets: [...tickets],
          totalPriceToShow: totalPrice,
          addToCArtOrNot: false,
        });
      } else {
        this.props.allFuction.setState({
          ...allFuction.state,
          tickets: [...tickets],
          totalPriceToShow: totalPrice,
        });
      }
    }
  };

  countHandleChange = (index) => {
    const { allFuction } = this.props;
    const tickets = allFuction.state.tickets;
    tickets[index]["numberOfTickets"] = tickets[index]["numberOfTickets"] + 1;
    if (
      tickets[index]["numberOfTickets"] > 0 &&
      tickets[index]["customField"].length > 0
    ) {
      const clonedCustomFiled = JSON.parse(
        JSON.stringify(tickets[index]["customField"])
      );
      if (tickets[index]["numberOfTickets"] > 1) {
        tickets[index]["customFields"] = [
          ...tickets[index]["customFields"],
          ...clonedCustomFiled,
        ];
      } else {
        tickets[index]["customFields"] = clonedCustomFiled;
      }
    }
    if (
      tickets[index]["customField"].length > 0 &&
      tickets[index]["numberOfTickets"] > 0
    ) {
      let customNew = tickets[index]["customField"].map((detailCustom) => {
        return {
          customFieldDetailId: detailCustom.customFieldDetailId,
          customAnswer: "",
        };
      });
      tickets[index]["customAnswers"] =
        tickets[index]["customAnswers"].concat(customNew);
    }
    let totalPrice = 0;
    tickets.map((singleTicket) => {
      totalPrice =
        totalPrice + singleTicket.price * singleTicket.numberOfTickets;
      return singleTicket;
    });

    this.props.allFuction.setState({
      ...allFuction.state,
      tickets: [...tickets],
      totalPriceToShow: totalPrice,
      addToCArtOrNot: true,
      totalprice: totalPrice,
    });
    const addToCArtOrNot =
      this.props.allFuction.state.tickets.reduce(
        (total, currentValue) => (total = total + currentValue.numberOfTickets),
        0
      ) === 0
        ? false
        : true;
    this.props.allFuction.setState({ addToCArtOrNot });
  };
  // For tickets Without Slots booking
  countHandlePlus = (index, caseItem, type) => {
    const { allFuction } = this.props;
    var { ticketBooked } = this.state;
    const tickets = allFuction.state.tickets;
    let totalticketMax = allFuction.state.tickets.reduce(
      (previousValue, currentValue, index) =>
        previousValue + currentValue.numberOfTickets,
      0
    );

    if (this.props.ticketsDetails[0].availableTickets < tickets[index]["min"]) {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: `Total ${this.props.ticketsDetails[0].availableTickets} tickets available `,
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );

      return false;
    }
    if (this.props.ticketsDetails[0].availableTickets <= totalticketMax) {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "Booking tickets should not be more than available tickets",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
      return false;
    }

    if (tickets[index]["max"] !== null) {
      if (tickets[index]["max"] <= tickets[index]["numberOfTickets"]) {
        this.props.actions.showNotification(
          {
            title: "Warning",
            message: `You can't book more tickets.`,
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
        return false;
      }
    }
    if (tickets[index]["min"] === null) {
      tickets[index]["numberOfTickets"] = tickets[index]["numberOfTickets"] + 1;
    }
    if (tickets[index]["min"] === 0) {
      tickets[index]["numberOfTickets"] = tickets[index]["numberOfTickets"] + 1;
    } else if (tickets[index]["min"] !== null) {
      if (tickets[index]["numberOfTickets"] === 0) {
        tickets[index]["numberOfTickets"] = tickets[index]["min"];
        this.props.actions.showNotification(
          {
            title: "Success",
            message: `Minimum tickets required is ${tickets[index]["min"]}.`,
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "success"
        );
      } else {
        tickets[index]["numberOfTickets"] =
          tickets[index]["numberOfTickets"] + 1;
      }
    }
    if (
      tickets[index]["numberOfTickets"] > 0 &&
      tickets[index]["customField"].length > 0
    ) {
      const cutstomFieldClone = JSON.parse(
        JSON.stringify(tickets[index]["customField"])
      );
      if (
        tickets[index]["customFields"].length === 0 &&
        tickets[index]["min"] > 1
      ) {
        let minCustomField = [];
        for (let i = 0; i < tickets[index]["min"]; i++) {
          minCustomField.push(...cutstomFieldClone);
        }
        tickets[index]["customFields"] = [
          ...tickets[index]["customFields"],
          ...minCustomField,
        ];
      } else if (tickets[index]["numberOfTickets"] > 1) {
        tickets[index]["customFields"] = [
          ...tickets[index]["customFields"],
          ...cutstomFieldClone,
        ];
      } else {
        tickets[index]["customFields"] = cutstomFieldClone;
      }
    }
    if (
      tickets[index]["customField"].length > 0 &&
      tickets[index]["numberOfTickets"] > 0
    ) {
      let customNew = tickets[index]["customFields"].map((detailCustom) => {
        return {
          customFieldDetailId: detailCustom.customFieldDetailId,
          customAnswer: detailCustom.customAnswer
            ? detailCustom.customAnswer
            : "",
        };
      });
      tickets[index]["customAnswers"] = customNew;
    }
    let totalPrice = 0;
    tickets.map((singleTicket) => {
      totalPrice = JSON.parse(
        (
          totalPrice +
          singleTicket.price * singleTicket.numberOfTickets
        ).toFixed(2)
      );
      return singleTicket;
    });
    this.props.allFuction.setState({
      ...allFuction.state,
      tickets: [...tickets],
      totalPriceToShow: totalPrice,
      addToCArtOrNot: true,
      totalprice: totalPrice,
      sumofticketscount: ticketBooked,
    });
  };

  // For tickets Without Slots booking
  countHandleMinus = (index, caseItem) => {
    const { allFuction } = this.props;
    var { ticketBooked } = this.state;
    const tickets = allFuction.state.tickets;
    if (tickets[index]["numberOfTickets"] >= 1) {
      if (tickets[index].numberOfTickets === tickets[index].minvalue) {
        tickets[index]["numberOfTickets"] = 1;
      }
      tickets[index]["numberOfTickets"] = tickets[index]["numberOfTickets"] - 1;
      if (
        tickets[index]["customField"].length > 0 &&
        tickets[index]["numberOfTickets"] > 0
      ) {
        tickets[index]["customFields"] = tickets[index]["customFields"].slice(
          0,
          tickets[index]["customFields"].length -
            tickets[index]["customField"].length
        );
        tickets[index]["customAnswers"] = tickets[index]["customAnswers"].slice(
          0,
          tickets[index]["customAnswers"].length -
            tickets[index]["customField"].length
        );
      }
      if (
        tickets[index]["customField"].length > 0 &&
        tickets[index]["numberOfTickets"] === 0
      ) {
        tickets[index]["customAnswers"] = [];
        tickets[index]["customFields"] = [];
      }
      let totalPrice = 0;
      tickets.map((singleTicket) => {
        totalPrice = JSON.parse(
          (
            totalPrice +
            singleTicket.price * singleTicket.numberOfTickets
          ).toFixed(2)
        );
        return singleTicket;
      });
      ticketBooked = tickets.reduce(
        (prevValue, currentValue) => prevValue + currentValue.numberOfTickets,
        0
      );

      this.props.allFuction.setState({
        ...allFuction.state,
        tickets: [...tickets],
        totalPriceToShow: totalPrice,
        addToCArtOrNot: ticketBooked > 0 ? true : false,
        totalprice: totalPrice,
        sumofticketscount: ticketBooked,
      });
    }
  };

  onTimeHandleChange = (e, { value, name }) => {
    let { bookingSlot } = this.state;
    this.setState({
      bookingSlot: { ...this.state.bookingSlot, [name]: value },
    });
    this.props.allFuction.setState({
      startTimeRange:
        bookingSlot.startTimeHour +
        ":" +
        bookingSlot.startTimeMinute +
        bookingSlot.startTimeAm,
      endTimeRange:
        bookingSlot.endTimeHour +
        ":" +
        bookingSlot.endTimeMinute +
        bookingSlot.endTimeAm,
    });
  };

  state = { activeItem: "account" };
  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const {
      allFuction,
      activityTimeSlots,
      ticketsTable,
      ticketsDetails,
      currentTime,
      addToCArtOrNot,
      CustomTimefirst,
      CustomTimetwo,
      CustomTimethree,
      availableTime,
      selectedTime,
      bundleRate,
      BundleTimeSlots,
      isShowAvailabletickets,
      availabilityStartDate,
    } = this.props;
    const bundleData =
      this.props.allFuction.state.BundleDescription === undefined
        ? false
        : this.props.allFuction.state.BundleDescription;
    const bundleCustomFields =
      this.props.allFuction.state.BundleCustomFields === undefined
        ? false
        : this.props.allFuction.state.BundleCustomFields;
    const hideSection =
      this.props.allFuction.state.bundleProduct === false ? false : true;
    const selectedTimeslot = this.props.selectedTime === "" ? false : true;
    const tickets = allFuction.state.tickets;
    let r = document.querySelector(":root");
    let p = getComputedStyle(r);
    const theme = {
      primary: {
        light: p.getPropertyValue("--main-color"),
        main: p.getPropertyValue("--main-color"),
        dark: p.getPropertyValue("--main-color"),
      },
      grey: {
        700: "#707070",
        900: "#1b1b1d",
      },
      background: {
        default: "#f54952",
      },
      text: {
        disabled: "#BABABA",
      },
    };

    const startTime =
      availableTime.startTimeHour +
      ":" +
      availableTime.startTimeMinute +
      availableTime.startTimeAm;

    const initialMonth = allFuction.state.availabilityStartDate;
    const today = moment().format("YYYY-MM-DD");
    let initialMoment = moment(initialMonth).format("YYYY-MM-DD");
    let initialLoader = initialMoment === "Invalid date" ? true : false;
    const SelectDate =
      this.state.currentTime === ""
        ? this.state.firstAvailableDate
        : currentTime;
    const reservationDate = moment(SelectDate).format("MM-DD-YYYY");
    return (
      <>
        <Grid>
          {bundleRate && bundleRate.length > 0 && availabilityStartDate && (
            <Grid.Column width={16}>
              <Grid>
                {/* <Grid.Column width={4}></Grid.Column> */}
                <Grid.Column width={16}>
                  <Header as="h5">Bundle price</Header>
                  <div className="commonTable">
                    <Table unstackable>
                      <Table.Header>
                        <Table.HeaderCell>Date Range</Table.HeaderCell>
                        <Table.HeaderCell>Available Timings</Table.HeaderCell>
                        <Table.HeaderCell>Price</Table.HeaderCell>
                      </Table.Header>
                      <Table.Body>
                        {bundleRate &&
                          bundleRate.map((fee) => {
                            return (
                              <Table.Row>
                                <Table.Cell>{fee.DateRange}</Table.Cell>
                                <Table.Cell>{fee.AvailableTimings} </Table.Cell>
                                <Table.Cell className="noWrap text-right ">
                                  {" "}
                                  {commonFunctions.isNumberInteger(fee.Price)}
                                </Table.Cell>
                              </Table.Row>
                            );
                          })}
                      </Table.Body>
                    </Table>
                  </div>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          )}
          <Grid.Column width={16}>
            <Header as="h5">Select Date</Header>
          </Grid.Column>
          <Grid.Column width={16} className="activityDate">
            {/* Select date from Month */}
            {initialLoader ? (
              <Dimmer active inverted />
            ) : (
              <DatePicker
                onRangeDateInScreen={this.onRangeDateInScreen}
                onChange={this.onChange}
                theme={theme}
                numberOfMonths={1}
                initialMonthAndYear={initialMoment < today ? today : initialMoment
                }
                // numberOfSelectableDays={10} // number of days you need
                selectedDays={this.state.firstAvailableDate}
                responsive={handleResponsive} // custom responsive, when using it, `numberOfMonths` props not working
                disabledBeforeToday={true}
                disabledBeforeDate={moment(
                  this.props.currentDateFromApi
                ).format("YYYY-MM-DD")}
                numberOfSelectableDays={1}
                disabledDays={this.state.nonAvalableDAtes}
                disabled={false} // disable calendar
                dayComponent={Day} //custom day component
                titleComponent="{Title}" // custom title of days
              />
            )}
            {!initialLoader && (
              <>
                {/* Timeslots Comming from API */}
                <Grid>
                  {activityTimeSlots &&
                    this.state.firstAvailableDate &&
                    currentTime !== undefined && (
                      <Grid.Column width={16}>
                        <Divider hidden />
                        <Header as="h5">
                          {(activityTimeSlots.timeForProduct === null) |
                          (activityTimeSlots.timeForProduct === "")
                            ? null
                            : " Available Time Slots"}
                        </Header>
                      </Grid.Column>
                    )}
                </Grid>
                {activityTimeSlots &&
                  this.state.firstAvailableDate &&
                  currentTime !== undefined &&
                  activityTimeSlots?.timeForProduct !== null &&
                  !this.props.allFuction.state?.bundleItems?.length &&
                  Object.keys(activityTimeSlots?.timeForProduct).length !== 0 &&
                  activityTimeSlots?.timeForProduct.split(",").map((item) => {
                    return (
                      <>
                        <Button
                          name={item}
                          basic
                          className="select-btn"
                          active={
                            selectedTime.indexOf(item) > -1 ? true : false
                          }
                          onClick={(e) => {
                            allFuction.handleSelectTime(
                              e,
                              this.state.currentTime === ""
                                ? this.state.firstAvailableDate
                                : currentTime
                            );
                          }}
                        >
                          {item}
                        </Button>
                      </>
                    );
                  })}
              </>
            )}
            {!initialLoader && (
              <>
                {/* start time and end time */}
                {(BundleTimeSlots === null ||
                  BundleTimeSlots === undefined) && (
                  <>
                    {activityTimeSlots?.slotsForProduct !== null ||
                    this.props.allFuction.state?.bundleItems?.length > 0 ||
                    activityTimeSlots.shopHours !== null ||
                    activityTimeSlots.timeSlotsForProduct !== null ? (
                      <>
                        <Divider hidden />
                        {
                          <Grid>
                            <Grid.Column width={16}>
                              {this.props.allFuction.state.bundleItems?.length >
                              0 ? (
                                <Header as="h5" className="maroon-color">
                                  Bundle Start Time
                                </Header>
                              ) : (
                                <Header as="h5" className="maroon-color">
                                  Reservation Time
                                </Header>
                              )}
                            </Grid.Column>
                            <Grid.Column computer={4} tablet={5} mobile={16}>
                              <Select
                                options={CustomTimefirst}
                                value={availableTime.startTimeHour}
                                name="startTimeHour"
                                fluid
                                onChange={allFuction.onTimeHandleChange}
                              />
                            </Grid.Column>
                            <Grid.Column computer={4} tablet={5} mobile={16}>
                              <Select
                                options={CustomTimetwo}
                                value={availableTime.startTimeMinute}
                                defaultValue="m00"
                                name="startTimeMinute"
                                fluid
                                onChange={allFuction.onTimeHandleChange}
                              />
                            </Grid.Column>
                            <Grid.Column computer={4} tablet={5} mobile={16}>
                              <Select
                                options={CustomTimethree}
                                value={availableTime.startTimeAm}
                                name="startTimeAm"
                                defaultValue="am"
                                fluid
                                onChange={allFuction.onTimeHandleChange}
                              />
                            </Grid.Column>
                          </Grid>
                        }
                      </>
                    ) : null}
                  </>
                )}
                {BundleTimeSlots === null ||
                  BundleTimeSlots === undefined ||
                  (Object.keys(BundleTimeSlots).length !== 0 &&
                    BundleTimeSlots.split(",").map((item) => {
                      return (
                        <>
                          <Button
                            name={item}
                            basic
                            className="select-btn"
                            active={
                              selectedTime.indexOf(item) > -1 ? true : false
                            }
                            onClick={(e) =>
                              allFuction.handleBundleSelectTime(e)
                            }
                          >
                            {item}
                          </Button>
                        </>
                      );
                    }))}

                {this.props.allFuction.state?.bundleItems?.length > 0 && (
                  <GridColumn computer={12} mobile={16} textAlign="center">
                    <Divider hidden />
                    <Button
                      className="primaryBtn"
                      onClick={this.getAvailibilityForBundle}
                    >
                      Choose Bundle Count
                    </Button>
                  </GridColumn>
                )}
                {/* {!activityTimeSlots.timeForProduct && */}
                {activityTimeSlots?.timeForProduct === null && (
                  <GridColumn computer={12} mobile={16} textAlign="center">
                    <Divider hidden />
                    <Button
                      onClick={(e) =>
                        allFuction.getActivityTickets(
                          startTime,
                          this.state.selectedDate
                            ? this.state.selectedDate
                            : this.state.firstAvailableDate
                        )
                      }
                      className="primaryBtn"
                    >
                      Check Availability
                    </Button>
                  </GridColumn>
                )}
              </>
            )}
            {/* Available tickets in activity */}
            {isShowAvailabletickets && (
              <>
                {activityTimeSlots?.timeForProduct !== null &&
                ticketsTable &&
                currentTime !== undefined ? (
                  <>
                    {selectedTime === "" ? null : (
                      <>
                        {tickets?.length > 0 && (
                          <>
                            <Divider hidden />
                            <Header as="h4">No. of Available Tickets</Header>
                            <div className="commonTable">
                              <Table
                                className="flatfee showAvailable"
                                unstackable
                                singleLine
                              >
                                <Table.Header>
                                  <Table.Row>
                                    <Table.HeaderCell textAlign="center">
                                      Total Tickets
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">
                                      Open Tickets
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign="center">
                                      Purchased Tickets{" "}
                                    </Table.HeaderCell>
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                  {ticketsDetails?.length > 0 &&
                                    ticketsDetails.map((ticketObj) => {
                                      return (
                                        <Table.Row>
                                          <Table.Cell textAlign="center">
                                            {ticketObj.totalTickets}
                                          </Table.Cell>
                                          <Table.Cell textAlign="center">
                                            {ticketObj.availableTickets}
                                          </Table.Cell>
                                          <Table.Cell textAlign="center">
                                            {ticketObj.ticketBooked}
                                          </Table.Cell>
                                        </Table.Row>
                                      );
                                    })}
                                </Table.Body>
                              </Table>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            )}
            <div className="selectTickets">
              {/* Book tickets from tickets counter when Slots available */}
              {selectedTimeslot &&
                activityTimeSlots?.timeForProduct !== null &&
                currentTime !== undefined &&
                tickets?.length > 0 && (
                  <>
                    <ActivityBookTicket
                      loaderActivityData={this.props.loaderActivityData}
                      tickets={tickets}
                      countHandleMinus={this.countHandleMinus}
                      countHandlePlus={this.countHandlePlus}
                      activityValidations={this.props.activityValidations}
                      handleChangeInput={allFuction.handleChangeInput}
                    />
                  </>
                )}

              {/* Book tickets from tickets counter when Slots are not available */}
              {activityTimeSlots?.timeForProduct === null &&
                currentTime !== undefined &&
                tickets?.length > 0 && (
                  <>
                    <ActivityBookTicket
                      tickets={tickets}
                      loaderActivityData={this.props.loaderActivityData}
                      countHandleMinus={this.countHandleMinus}
                      countHandlePlus={this.countHandlePlus}
                      activityValidations={this.props.activityValidations}
                      handleChangeInput={allFuction.handleChangeInput}
                    />
                  </>
                )}
            </div>
            {hideSection && currentTime !== undefined && (
              <Grid>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={5}
                  verticalAlign="middle"
                >
                  <Header as="h4">Choose Total</Header>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={6}>
                  <Form.Field className="add-numbers">
                    <Icon
                      name="minus"
                      onClick={this.countHandleChangeMinusBundle}
                      className="orange-button"
                    />
                    <Form.Input value={this.props.count} fluid />
                    <Icon
                      name="plus"
                      className="orange-button plus-btn"
                      onClick={this.countHandleChangebBndle}
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={6}>
                  <Header as="h5"> Single bundle contains </Header>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={5}>
                  <div className="bundleNumber">
                    <div>
                      {this.props.allFuction.state.bundleItems?.length > 0 &&
                        this.props.allFuction.state.bundleItems.map((item) => {
                          return (
                            <p>
                              {" "}
                              {item.quantity} X{" "}
                              <span className="orange-color ">
                                {" "}
                                <b>{item.ProductName}</b>
                              </span>
                            </p>
                          );
                        })}
                    </div>
                  </div>
                </Grid.Column>
                <Divider hidden />
              </Grid>
            )}

            {currentTime !== undefined && addToCArtOrNot === true && (
              <Header as="h4" className="mb-3 durationSubCaption">
                  Total :{" "}
                  <span className="itemValue">
                    {commonFunctions.isNumberInteger(
                      allFuction.state.totalPriceToShow
                    )}

                  </span>
              </Header>
            )}
            
            {currentTime !== undefined && addToCArtOrNot === true && (
              <p>
                {activityTimeSlots?.slotsForProduct !== null ? (
                  <b>
                    Selected Time & Date : <span>{startTime}</span>{" "}
                    <span>{reservationDate}</span>{" "}
                  </b>
                ) : (
                  <b>
                    Selected Time & Date : <span>{selectedTime}</span>{" "}
                    <span>{reservationDate}</span>{" "}
                  </b>
                )}
              </p>
            )}
            

            {/* Bundle Custom Field */}
            {bundleCustomFields && (
              <>
                <Grid.Column width={16}>
                  <Header as="h5">Custom Fields :</Header>
                </Grid.Column>
                {/* <Divider hidden /> */}
              </>
            )}
            {bundleCustomFields &&
              bundleCustomFields.map((data) => {
                return (
                  <>
                    {data.customField && (
                      <p>
                        <b>Title : </b>
                        <span>{data.customField}</span>
                      </p>
                    )}
                    {data.customDescriptions && (
                      <p className="customFieldsData">
                        <b>Description : </b>
                        <span>{data.customDescriptions}</span>
                      </p>
                    )}
                  </>
                );
              })}

            {/* Bundle Description */}
            {bundleData && (
              <>
                {" "}
                <Grid.Column width={16}>
                  <Header as="h5">Bundle Description :</Header>
                  <Header.Subheader>{bundleData}</Header.Subheader>
                </Grid.Column>
              </>
            )}
          </Grid.Column>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
