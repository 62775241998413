import mycart from "../../pages/my-cart";
import contact from "../../pages/contact";
import faq from "../../pages/faq";
import about from "../../pages/about";
import policy from "../../pages/policy";
import home from "../../pages/home";
import Waiver from "../../pages/wavier";
import WaiverDetails from "../../pages/wavier/waiver-detail";
// Configure all routes of the application that contain authenticate template
const AuthenticateTemplateRoutesConfing = [
  {
    exact: true,
    private: false,
    path: `/my-cart`,
    component: mycart,
  },
  {
    exact: true,
    private: false,
    path: `/waiver`,
    component: Waiver,
  },
  {
    exact: true,
    private: false,
    path: `/waiver-details`,
    component: WaiverDetails,
  },
  {
    exact: true,
    private: false,
    path: `/faq`,
    component: faq,
  },
  {
    exact: true,
    private: false,
    path: `/contact`,
    component: contact,
  },
  {
    exact: true,
    private: false,
    path: `/about`,
    component: about,
  },
  {
    exact: true,
    private: false,
    path: `/policy`,
    component: policy,
  },
  {
    exact: false,
    private: false,
    path: `/`,
    component: home,
  },
];

export default AuthenticateTemplateRoutesConfing;
