import React,{ Component } from 'react';
import { Grid,GridColumn,Header,Loader,Dimmer,Divider } from 'semantic-ui-react';
import { connect,bindActionCreators,actions } from "../../shared/functional/global-import";
import { Editor } from "react-draft-wysiwyg";
import { EditorState,convertFromRaw } from 'draft-js';

class AboutPage extends Component {
  get initialState() {
    return {
      businessId: this.props.global.addBussinessId,
      description: "",
      editorState: EditorState.createEmpty(),
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      ...this.initialState,
      businessId: this.props.global.addBussinessId,
      IntegrationType: "A"
    }
  }

  componentDidMount() {
    this.getAboutUs();
    let themeColor = this.props.global.themeColor
    const root = document.documentElement;
    root?.style.setProperty("--main-color",themeColor);
  }

  // Get API for About Us
  getAboutUs = () => {
    this.props.actions.apiCall({
      urls: ["GETDETAILSFORWIDGET"],
      method: "GET",
      data: {
        "businessId": this.state.businessId,
        "IntegrationType": this.state.IntegrationType
      },onSuccess: (response) => {
        this.setState({
          editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(response[0].description))),
          IntegrationType: response[0].integrationType
        })
      }
    })
  }

  render() {
    const { editorState } = this.state;
    const { api } = this.props;
    return (
      <div className="landingPage">
        <Grid>
          {api.isApiLoading && (
            <Dimmer active inverted>
              <Loader />
            </Dimmer>)}
          <GridColumn width={3}>
          </GridColumn>
          <GridColumn width={10}>
            <Header as="h2" textAlign="center" className="commonHeader">About Us</Header>
            <div>
              <Divider hidden />
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                toolbar={{
                  options: [],
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              />
            </div>
          </GridColumn>
          <GridColumn width={3}>
          </GridColumn>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall,dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes,dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId,dispatch),
      storeBussinessDetail: bindActionCreators(actions.storeBussinessDetail,dispatch),
    },
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(AboutPage);