import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../../../shared/functional/global-import";
import MyCartPage from "../../../../../pages/my-cart";

class AddToCartModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.validator = commonFunctions.initializeSimpleValidator();
  }
  render() {
    const {backToWordpressModal, closeWordpressModal,allFunction } = this.props;                     
    return (
      <Modal
        closeOnDimmerClick={false}
        open={this.props.openModal}
        closeIcon
        onClose={this.props.closeModal}
        size={"fullscreen"}
        className="bookNowModal">
        <Modal.Content scrolling>
          <Modal.Description className="orderPlaced">
            <MyCartPage
              bookNow={true}
              closeModalAddtocart={this.props.closeModal}
              backToHomePage={backToWordpressModal}
              insideWordpress={this.props.insideWordpress}
              closeWordpressModal={closeWordpressModal}
              allFunction={allFunction}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      storeItemDetail: bindActionCreators(actions.storeItemDetail, dispatch),
      storeIndividualItemDetail: bindActionCreators(
        actions.storeIndividualItemDetail,
        dispatch
      ),
      AllItemDetailToPay: bindActionCreators(
        actions.AllItemDetailToPay,
        dispatch
      ),
      DeleteAllItemDetail: bindActionCreators(
        actions.DeleteAllItemDetail,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      DeleteItemIndividualDetail: bindActionCreators(
        actions.DeleteItemIndividualDetail,
        dispatch
      ),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddToCartModal);
