export const STORE_GLOBAL_CODES = "STORE_GLOBAL_CODES";
export const STORE_BUSSINESS_DETAIL = "STORE_BUSSINESS_DETAIL";
export const STORE_ITEM_DETAIL = "STORE_ITEM_DETAIL";
export const STORE_INDIVIDUAL_ITEM_DETAIL = "STORE_INDIVIDUAL_ITEM_DETAIL";
export const STORE_ITEM_UPDATE = "STORE_ITEM_UPDATE";
export const STORE_DELETE_ALLITEMS = "STORE_DELETE_ALLITEMS";
export const STORE_DELETE_INDIVIDUAL_ITEMS = "STORE_DELETE_INDIVIDUAL_ITEMS";
export const SHOW_HEADER_WEBSITE = "SHOW_HEADER_WEBSITE";
export const STORE_CATEGORY_DETAIL = "STORE_CATEGORY_DETAIL";
export const STORE_ALLITEM_DETAIL_TOPAY = "STORE_ALLITEM_DETAIL_TOPAY";
export const STORE_BUSSINESS_ID = "STORE_BUSSINESS_ID";
export const STORE_BUSSINESS_URL = "STORE_BUSSINESS_URL";
export const STORE_THEME_COLOR = "STORE_THEME_COLOR";
export const STORE_PARENT_ID = "STORE_PARENT_ID";
export const STORE_BUSSINESS_LOCATIONID = "STORE_BUSSINESS_LOCATIONID";
export const STORE_SKIP_OPTION = "STORE_SKIP_OPTION";
export const STORE_LOCATION_TIME_ZONE = "STORE_LOCATION_TIME_ZONE";
export const STORE_SHOW_COUNT = "STORE_SHOW_COUNT";
export const STORE_INSIDE_WORDPRESS = "STORE_INSIDE_WORDPRESS";
export const STORE_WORDPRESS_BACK_TO_SHOP = "STORE_WORDPRESS_BACK_TO_SHOP";