import React, { Component } from "react";
import {
  Button,
  Form,
  Container,
  Icon,
  Grid,
  Dimmer,
  Loader,
  Input,
} from "semantic-ui-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ThankYouPage from "./thank-you-page";
import { env } from "../../shared/functional/global-import";
import {
  connect,
  bindActionCreators,
  commonFunctions,
  actions,
  Notifications,
} from "../../shared/functional/global-import";
import Sign from "./sign";

import ChangeSignerModal from "../../shared/components/organisms/modal/change-signer/change-signer";

class Waiver extends Component {
  constructor(props) {
    super(props);

    this.state = {
      businessId: this.props.businessId,
      number: 1,
      documentList: [],
      ImageFile: null,
      openModal: false,
      showThankYouPage: false,
      signerPage: false,
      documentName: null,
      actionPerformedBy: this.props.auth.loggedIn.emailId,
      isApiLoading: false,
      isSigned: true,
      IsPolicy: false,
      signatureImage: null,
      existingWaiver: [],
      optionYearSelect: null,
      optionMonthSelect: null,
      optionDaySelect: [],
      optionSelect: [],
      isSignLoading: false,
      customField: [],
      isContinueFetching: false,
      waiver: [
        {
          customerId: null,
          email: "",
          phoneNumber: "",
          firstName: "",
          lastName: "",
          year: "",
          month: "",
          date: "",
          minor: false,
          MainSigner: true,
          IsSigned: true,
          customField: [],
        },
      ],
    };
    this.validator = commonFunctions.initializeSimpleValidator();
    this.customeValidations = commonFunctions.initializeSimpleValidator();
  }
  componentDidMount() {
    this.props.actions.SHOWHEADERWEBSITE(false);
    if (this.props.inSideWidget) {
      this.getItemDocument();
    } else {
      this.getDocument();
    }
    this.getYears();
    this.getMonth();
    var date = new Date();
    this.getDay("31", 0);
  }

  getItemDocument = () => {
    const dataObj = {
      Products: JSON.stringify([
        {
          BusinessCategoryType:
            this.props.onFinish.props.addItem.businessCategoryType,
          ProductId: this.props.onFinish.props.addItem.productId,
        },
      ]),
    };

    this.props.actions.apiCall({
      urls: ["GETORDERPRODUCTDOCUMENT"],
      method: "GET",
      data: dataObj,
      onSuccess: (response) => {
        const filteredResponse = response.filter((item) => item.customField);
        const customFields = filteredResponse.map((item) =>
          JSON.parse(item.customField)
        );

        const updatedCustomFields = customFields.reduce(
          (acc, curr) => acc.concat(curr),
          []
        );

        const encounteredCustomRefIds = {};

        const updatedCustomFieldsWithDocNames = updatedCustomFields.map(
          (customField) => {
            if (
              encounteredCustomRefIds[customField.customRefrenceId] ===
              undefined
            ) {
              // If this is the first occurrence of this customRefrenceId, assign the document name
              encounteredCustomRefIds[customField.customRefrenceId] = true;

              const correspondingDocument = response.find(
                (doc) => doc.documentId === customField.customRefrenceId
              );
              if (correspondingDocument) {
                return {
                  ...customField,
                  documentName: correspondingDocument.documentName,
                };
              }
            }
            // If not the first occurrence or no corresponding document found, set documentName as null
            return {
              ...customField,
              documentName: null,
            };
          }
        );

        const updatedWaiver = [...this.state.waiver];
        updatedWaiver[0].customField = updatedCustomFieldsWithDocNames;

        this.setState({ waiver: updatedWaiver });
        this.setState({
          documentList: response,
          documentName: response[0].documentName,
          ImageFile: response[0].image,
          customField: updatedCustomFieldsWithDocNames,
        });
      },
    });
  };

  getDay = (lastDay, index) => {
    var ddlday = [];
    let dayOptionData = [...this.state.optionDaySelect];
    let dayOption = [...this.state.optionSelect];
    for (let i = 1; i <= lastDay; i++) {
      let option = {
        text: JSON.stringify(i),
        value: JSON.stringify(i),
        id: JSON.stringify(i),
      };
      ddlday.push(option);
    }

    let arryDayObject = { item: ddlday };
    dayOptionData[index] = arryDayObject;
    this.setState({
      optionDaySelect: dayOptionData,
    });
    if (lastDay === "31") {
      let arryDayObject = { item: ddlday };
      dayOption[index] = arryDayObject;
      this.setState({
        optionSelect: dayOption,
      });
    }

    if (
      this.state.waiver[index].date != "" &&
      arryDayObject.item.slice(-1)[0].id < this.state.waiver[index].date
    ) {
      let data = [...this.state.waiver];
      data[index]["date"] = "";
      this.setState({ data });
    }
  };
  getMonth = () => {
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let ddlMonths = [];
    for (let i = 0; i < months.length; i++) {
      let montthId = i + 1;
      let option = {
        text: months[i],
        value: JSON.stringify(montthId),
        id: JSON.stringify(montthId),
      };
      ddlMonths.push(option);
    }
    this.setState({
      optionMonthSelect: ddlMonths,
    });
  };
  getYears = () => {
    var currentYear = new Date().getFullYear();
    let ddlYears = [];
    for (var i = currentYear; i >= 1930; i--) {
      let option = {
        text: JSON.stringify(i),
        value: JSON.stringify(i),
        id: JSON.stringify(i),
      };
      ddlYears.push(option);
    }
    this.setState({
      optionYearSelect: ddlYears,
    });
  };

  getDocument = () => {
    this.setState({ isSignLoading: true });
    const queryParams = new URLSearchParams(window.location.search);
    const documentid = queryParams.get("documentid");
    this.props.actions.apiCall({
      urls: ["GETWAIVERDOCUMENT"],
      method: "GET",
      data: {
        DocumentId: documentid,
      },
      onSuccess: (response) => {
        const updatedWaiver = [...this.state.waiver]; // Copying the existing state
        updatedWaiver[0].customField = JSON.parse(response.customField); // Updating the customField property
        this.setState({ waiver: updatedWaiver });
        this.setState({
          ImageFile: response.documentFile,
          documentName: response.documentName,
          businessId: response.businessId,
          customField: JSON.parse(response.customField),
        });
      },
      onFinally: () => {
        this.setState({ isSignLoading: false });
      },
    });
  };
  render() {
    const addAdultMinorwaiver = (e) => {
      this.validator.hideMessages();
      const AdultMinorInfo = {
        MainSigner: false,
        customerId: null,
        firstName: "",
        lastName: "",
        IsSigned: true,
        year: "",
        month: "",
        date: "",
        customField: this.state.customField,
        minor: e.target.className === "ui button Adult" ? false : true,
      };
      this.setState({
        waiver: [...this.state.waiver, AdultMinorInfo],
      });
      this.validator.purgeFields();
    };

    const deleteAdultMinorWaiver = (index) => {
      this.validator.purgeFields();
      const filterdWaiver = this.state.waiver.filter((_, i) => i !== index);
      this.setState({
        waiver: filterdWaiver,
      });
      // this.setState({
      //   optionDaySelect: this.state.optionDaySelect.filter(
      //     (_, i) => i !== index
      //   ),
      // });
    };
    let data = [...this.state.waiver];
    const onChangeProductDetails = (index, event) => {
      data[index][event.target.name] = event.target.value;
      this.setState({ data });
    };
    const setPhonNumbervalue = (e, { value }) => {
      data[0]["phoneNumber"] = value;
      this.setState({ data });
    };
    const onHandleChange = (e, { name, value, index }) => {
      data[index][name] = value;
      this.setState({ data });
      if (name === "year") {
        data[index]["date"] = "";
        data[index]["month"] = "";
        this.setState({ data });
      }
      if (name === "month") {
        let m = value;
        var y = this.state.waiver[index].year
          ? this.state.waiver[index].year
          : new Date().getFullYear();
        var d = new Date(y, m, 0).getDate();
        this.getDay(d, index);
      }
      this.setState({ reload: true }, () => this.setState({ reload: false }));
    };
    const openSignerPage = () => {
      this.setState({ isContinueFetching: true });
      if (this.validator.allValid() === true) {
        let IsAllDateCorrect = this.state.waiver.every((obj) => {
          const today = new Date();
          var date = new Date(obj.year, obj.month - 1, obj.date);
          if (date <= today) {
            return true;
          }
          return false;
        });
        if (IsAllDateCorrect) {
          let data = [...this.state.waiver];
          let data2 = data.map((obj) => {
            return {
              ...obj,
              email: this.state.waiver[0].email,
              phoneNumber: this.state.waiver[0].phoneNumber,
            };
          });

          this.setState({
            isApiLoading: !this.state.isApiLoading,
            waiver: data2,
          });
          if (!this.props.inSideWidget) {
          }
          setTimeout(() => {
            this.setState({ signerPage: !this.state.signerPage });
          }, 500);
        } else {
          this.props.actions.showNotification(
            {
              title: "Warning",
              message: "Selected date should not greater then current date",
              position: "br",
              autoDismiss: commonFunctions.notificationTime(),
            },
            "error"
          );
        }
        setTimeout(() => {
          this.setState({ isContinueFetching: false });
        }, 500);
      } else {
        this.setState({ signerPage: false });
        this.validator.showMessages();
        setTimeout(() => {
          this.setState({ isContinueFetching: false });
        }, 500);
      }
    };
    const onBlurGetWaiver = (event) => {
      const { businessId } = this.props;
      if (event.target.value !== "") {
        this.props.actions.apiCall({
          urls: ["GETEXISTINGWAIVERCUSTOMERSONWIDGET"],
          method: "GET",
          data: {
            email: event.target.value,
            businessId: this.state.businessId || businessId,
          },
          onSuccess: (response) => {
            if (response.waivers) {
              let existingWaiverData = JSON.parse(response.waivers);
              let finalArr = existingWaiverData.map((existingWaiver, index) => {
                return index === 0
                  ? { ...existingWaiver, MainSigner: true, IsSigned: true }
                  : existingWaiver;
              });
              let selectNoneObj = {
                IsSigned: false,
                MainSigner: false,
                customerId: null,
                date: "",
                email: "",
                firstName: "None",
                lastName: "",
                minor: false,
                month: "",
                phoneNumber: "",
                year: "",
              };
              finalArr.unshift(selectNoneObj);
              this.setState({
                waiver: finalArr,
                openModal: !this.state.openModal,
              });
            }
          },
          showNotification: false,
        });
      }
    };
    const openSignerModal = () => {
      this.validator.purgeFields();
      this.setState({ openModal: !this.state.openModal });
    };
    const clearAdultMinorWaiverData = () => {
      this.validator.hideMessages();
      this.state.waiver = [];
      const data = [];
      let ob = {
        customerId: null,
        email: "",
        phoneNumber: "1",
        firstName: "",
        IsSigned: false,
        lastName: "",
        year: "",
        month: "",
        date: "",
        minor: false,
        MainSigner: true,
      };
      data.push(ob);
      this.setState({
        waiver: this.state.waiver.concat(data),
      });
      if (this.props.inSideWidget) {
        this.getItemDocument();
      } else {
        this.getDocument();
      }
    };
    this.onHandleChange = (e, { value, index, subindex }) => {
      const data = this.state.waiver.map((item, dataIndex) => {
        if (dataIndex === index) {
          const customFields = item.customField.map((field, fieldIndex) => {
            if (fieldIndex === subindex) {
              return {
                ...field,
                customAnswer: value,
              };
            } else {
              return field;
            }
          });
          return {
            ...item,
            customField: customFields,
          };
        } else {
          return item;
        }
      });
      this.setState({ waiver: data });
      e.preventDefault();
    };

    const backToWaiverScreen = () => {
      this.validator.hideMessages();
      this.state.waiver = [];
      const data = [];
      let ob = {
        customerId: null,
        email: "",
        phoneNumber: "1",
        firstName: "",
        IsSigned: false,
        lastName: "",
        year: "",
        month: "",
        date: "",
        minor: false,
        MainSigner: true,
      };
      data.push(ob);
      this.setState({
        waiver: this.state.waiver.concat(data),
      });
      this.setState({
        showThankYouPage: !this.state.showThankYouPage,
        signerPage: !this.state.signerPage,
      });
      this.getDocument();
    };

    const waiverArr = this.state.waiver;
    const showThankYouPage = this.state.showThankYouPage;
    const showDocumnetData = this.props.showtheDocumnetData;

    return (
      <>
        <div className="top-header-strip"></div>
        {showThankYouPage && (
          <ThankYouPage backToWaiverScreen={backToWaiverScreen} />
        )}
        {!showThankYouPage && (
          <>
            {this.state.signerPage ? (
              <>
                {!this.state.isApiLoading && commonFunctions.showLoader}
                <Sign
                  openSignerPage={openSignerPage}
                  // onSubmitDetails={onSubmitDetails}
                  allFuction={this}
                  waiverProps={this.props}
                  waiver={this.state.waiver}
                  IsPolicy={this.state.IsPolicy}
                  signatureImage={this.state.signatureImage}
                  ImageFile={this.state.ImageFile}
                  inSideWidget={this.props.inSideWidget}
                  documentList={this.state.documentList}
                  number={this.state.number}
                />
              </>
            ) : (
              <Container>
                {/* {this.state.isApiLoading && commonFunctions.showLoader} */}
                {this.state.isApiLoading ||
                  (this.state.isSignLoading && (
                    <Dimmer active inverted>
                      <Loader />
                    </Dimmer>
                  ))}

                <Form className="waiverForm">
                  {showDocumnetData === undefined ? (
                    <div className="doumentName">{this.state.documentName}</div>
                  ) : null}
                  <>
                    {waiverArr?.map((user, index) => {
                      const customFields = user.customField;
                      return (
                        <>
                          {index <= 0 && (
                            <label className="dateOfBirthLable">
                              Main Adult Signer
                            </label>
                          )}

                          <Form.Group widths="equal">
                            {index <= 0 && (
                              <>
                                <Form.Field>
                                  <Form.Input
                                    placeholder="Email"
                                    value={user.email}
                                    onBlur={(event) => onBlurGetWaiver(event)}
                                    onChange={(event) =>
                                      onChangeProductDetails(index, event)
                                    }
                                    name="email"
                                    error={this.validator.message(
                                      "Email",
                                      user.email,
                                      `required|email`
                                    )}
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <PhoneInput
                                    className={"waiverPhonNumber"}
                                    placeholder={"Phone Number"}
                                    country={env.DEFAULT_COUNTRY}
                                    onlyCountries={["us"]}
                                    inputProps={{
                                      name: "phoneNumber",
                                    }}
                                    preferredCountries={["us"]}
                                    countryCodeEditable={false}
                                    value={user.phoneNumber || ""}
                                    onChange={(
                                      value,
                                      country,
                                      e,
                                      formattedValue
                                    ) => {
                                      setPhonNumbervalue(e, {
                                        value: formattedValue,
                                      });
                                    }}
                                  />
                                  {this.validator.message(
                                    "PhoneNumber",
                                    user.phoneNumber,
                                    `required|phone`
                                  )}
                                </Form.Field>
                              </>
                            )}
                          </Form.Group>

                          <div className={`${index > 0 ? "form-wrapper" : ""}`}>
                            <>
                              {index > 0 && (
                                <Icon
                                  className="cancel-icon"
                                  name="delete"
                                  onClick={() => deleteAdultMinorWaiver(index)}
                                />
                              )}
                            </>
                            {index > 0 && (
                              <>
                                {user.minor ? (
                                  <label className="dateOfBirthLable">
                                    Minor
                                  </label>
                                ) : (
                                  <label className="dateOfBirthLable">
                                    Adult
                                  </label>
                                )}
                              </>
                            )}

                            <Form.Group widths="equal">
                              <Form.Field>
                                <Form.Input
                                  placeholder="First Name"
                                  value={user.firstName}
                                  onChange={(event) =>
                                    onChangeProductDetails(index, event)
                                  }
                                  name="firstName"
                                  error={this.validator.message(
                                    `FirstName`,
                                    user.firstName,
                                    `required`
                                  )}
                                />
                              </Form.Field>
                              <Form.Field>
                                <Form.Input
                                  placeholder="Last Name"
                                  value={user.lastName}
                                  onChange={(event) =>
                                    onChangeProductDetails(index, event)
                                  }
                                  name="lastName"
                                  error={this.validator.message(
                                    `LastName`,
                                    user.lastName,
                                    `required`
                                  )}
                                />
                              </Form.Field>
                            </Form.Group>

                            <label className="dateOfBirthLable">
                              Birth Date <span className="astrickSign">*</span>
                            </label>

                            <Grid>
                              <Grid.Column mobile={16} computer={5}>
                                <Form.Field>
                                  <Form.Dropdown
                                    placeholder="Select Year"
                                    name="year"
                                    search
                                    selection
                                    options={this.state.optionYearSelect}
                                    value={user.year}
                                    onChange={(event, { name, value }) =>
                                      onHandleChange(event, {
                                        name,
                                        value,
                                        index,
                                      })
                                    }
                                    error={this.validator.message(
                                      "Select Year",
                                      user.year,
                                      `required`
                                    )}
                                  ></Form.Dropdown>
                                </Form.Field>
                              </Grid.Column>
                              <Grid.Column mobile={16} computer={5}>
                                <Form.Field>
                                  <Form.Dropdown
                                    placeholder="Select Month"
                                    name="month"
                                    search
                                    selection
                                    value={user.month}
                                    options={this.state.optionMonthSelect}
                                    onChange={(event, { name, value }) =>
                                      onHandleChange(event, {
                                        name,
                                        value,
                                        index,
                                      })
                                    }
                                    error={this.validator.message(
                                      "Select Month",
                                      user.month,
                                      `required`
                                    )}
                                  ></Form.Dropdown>
                                </Form.Field>
                              </Grid.Column>
                              <Grid.Column mobile={16} computer={5}>
                                <Form.Field>
                                  <Form.Dropdown
                                    placeholder="Select Day"
                                    name="date"
                                    search
                                    selection
                                    value={user.date}
                                    options={
                                      this.state.optionDaySelect[index]?.item
                                        ? this.state.optionDaySelect[index]
                                            ?.item
                                        : this.state.optionSelect[0]?.item
                                    }
                                    onChange={(event, { name, value }) =>
                                      onHandleChange(event, {
                                        name,
                                        value,
                                        index,
                                      })
                                    }
                                    error={this.validator.message(
                                      `Select Day `,
                                      user.date,
                                      `required`
                                    )}
                                  ></Form.Dropdown>
                                </Form.Field>
                              </Grid.Column>
                              {/* </Grid.Row> */}
                              {/* Add the new function in waveir */}
                            </Grid>
                            {customFields && customFields.length > 0 && (
                              <div className="jsonDataField">
                                {customFields.map((itemValue, subindex) => {
                                  const fieldValue = itemValue.customAnswer
                                    ? itemValue.customAnswer
                                    : "";
                                  return (
                                    <div className="dataField" key={subindex}>
                                      <div className="doumentName">
                                        {itemValue.documentName !== null
                                          ? itemValue.documentName
                                          : ""}
                                      </div>
                                      <Form.Field class="ui input">
                                        {
                                          <div
                                            style={{ marginBottom: "4px" }}
                                            className="mt-1"
                                          >
                                            <span className="fontEllipsis">
                                              {itemValue.customField ||
                                                itemValue.customField}
                                            </span>
                                            {itemValue.customRequired && (
                                              <span className="estrickSignwavier">
                                                *
                                              </span>
                                            )}
                                          </div>
                                        }

                                        <Form.Input
                                          type="text"
                                          name={itemValue.customField[subindex]}
                                          placeholder={
                                            itemValue.customDescriptions ||
                                            itemValue.customDescription
                                          }
                                          value={fieldValue}
                                          onChange={(event, { value }) =>
                                            this.onHandleChange(event, {
                                              value,
                                              index,
                                              subindex,
                                            })
                                          }
                                          error={
                                            itemValue.customRequired &&
                                            this.validator.message(
                                              "text",
                                              itemValue.customAnswer,
                                              "required"
                                            )
                                          }
                                        />
                                        {/* <div>{itemValue?.customAnswer}</div> */}
                                      </Form.Field>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                          {!user.minor && (
                            <>
                              <br />
                              <label>(Age must be at least 18 years old)</label>
                            </>
                          )}
                        </>
                      );
                    })}
                  </>
                  <div className="WiverFotter">
                    For main signer to sign for multiple guest, add below. For
                    <span className="astrickSign"> adults</span> who want to
                    sign individually, complete process one-at-a-time.
                  </div>
                </Form>
                <Form.Group widths="equal">
                  <Form.Field>
                    <p>&nbsp;</p>
                    <Button
                      id={"Adult"}
                      className="Adult"
                      onClick={addAdultMinorwaiver}
                    >
                      +Adult
                    </Button>

                    <Button
                      id={"Minor"}
                      className="Minor"
                      onClick={addAdultMinorwaiver}
                    >
                      +Minor
                    </Button>
                  </Form.Field>

                  <Form.Field>
                    <p>&nbsp;</p>
                    <div className="text-right mb-3">
                      <Button
                        type="submit"
                        className="clear-Waiver-Data"
                        onClick={clearAdultMinorWaiverData}
                      >
                        Clear
                      </Button>

                      <Button
                        type="submit"
                        className="continue-Waiver-Data"
                        onClick={openSignerPage}
                        disabled={this.state.isContinueFetching}
                      >
                        Continue
                      </Button>
                    </div>
                  </Form.Field>
                </Form.Group>
              </Container>
            )}
            <div id="section-1"></div>
            {this.state.openModal && (
              <ChangeSignerModal
                waiver={this.state.waiver}
                documentCustomFields={this.state.customField}
                isSignedWidget={this.props.inSideWidget}
                openModal={this.state.openModal}
                allFuction={this}
                closeModal={openSignerModal}
                getDocument={this.getDocument}
                getItemDocument={this.getItemDocument}
                onclose={() => this.setState({ openModal: false })}
              />
            )}
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      SHOWHEADERWEBSITE: bindActionCreators(
        actions.SHOWHEADERWEBSITE,
        dispatch
      ),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Waiver);
