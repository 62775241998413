import React, { Component } from "react";
import { Grid, Header } from "semantic-ui-react";
class StepFour extends Component {
  render() {
    const { itemInfos, customField } = this.props;
                     
    return (
      <>
        {this.props.seassonFalse ? null : (
          <>
            {customField &&
              customField.map((item, index) => {
                return (
                  <>
                    <Header className="removeMargin" as="h5">
                      {item.customField}{" "}
                    </Header>

                    <pre className="Wraptext">{item.customDescriptions}</pre>
                  </>
                );
              })}
          </>
        )}
        {itemInfos &&
          itemInfos.map((itemInfos) => {
                                 
            let ActivityWhattoknowandbring =
              itemInfos.ActivityWhattoknowandbring === ""
                ? false
                : itemInfos.ActivityWhattoknowandbring &&
                  itemInfos.ActivityWhattoknowandbring === undefined
                ? false
                : itemInfos.ActivityWhattoknowandbring;
            let ActivityWhatIsIncluded =
              itemInfos.ActivityWhatIsIncluded === ""
                ? false
                : itemInfos.ActivityWhatIsIncluded &&
                  itemInfos.ActivityWhatIsIncluded === undefined
                ? false
                : itemInfos.ActivityWhatIsIncluded;
            let ActivityDescription =
              itemInfos.ActivityDescription === ""
                ? false
                : itemInfos.ActivityDescription &&
                  itemInfos.ActivityDescription === undefined
                ? false
                : itemInfos.ActivityDescription;
            let PickUpInstructions =
              itemInfos.PickUpInstructions === ""
                ? false
                : itemInfos.PickUpInstructions; // done
            let DropOffInstructions =
              itemInfos.DropOffInstructions === ""
                ? false
                : itemInfos.DropOffInstructions; // done
            let WhatToKnow =
              itemInfos.WhatToKnow === "" ? false : itemInfos.WhatToKnow;
            let Description =
              itemInfos.Description === "" ? false : itemInfos.Description;
            let ItemDescription =
              itemInfos.ItemDescription === ""
                ? false
                : itemInfos.ItemDescription;
            let WhatIsIncluded =
              itemInfos.WhatIsIncluded === ""
                ? false
                : itemInfos.WhatIsIncluded;
            let WhatisIncluded =
              itemInfos.WhatisIncluded === ""
                ? false
                : itemInfos.WhatisIncluded;
            let WhattoKnow =
              itemInfos.WhattoKnow === "" ? false : itemInfos.WhattoKnow;
            

            return (
              <Grid>
                <Grid.Column width={16}>
                  {PickUpInstructions && (
                    <>
                      <Header className="removeMargin" as="h5">
                        Pick up instruction
                      </Header>
                      <pre className="Wraptext">
                        {itemInfos.PickUpInstructions}
                      </pre>
                    </>
                  )}
                  {DropOffInstructions && (
                    <>
                      <Header className="removeMargin" as="h5">
                        Drop off instruction{" "}
                      </Header>
                      <pre className="Wraptext">
                        {itemInfos.DropOffInstructions}
                      </pre>
                    </>
                  )}

                  {WhattoKnow && (
                    <>
                      <Header className="removeMargin" as="h5">
                        What to Know ?
                      </Header>
                      <pre className="Wraptext">{itemInfos.WhattoKnow}</pre>
                    </>
                  )}

                  {WhatisIncluded && (
                    <>
                      <Header className="removeMargin" as="h5">
                        What is included ?
                      </Header>

                      <pre className="Wraptext">{itemInfos.WhatisIncluded}</pre>
                    </>
                  )}
                  {ItemDescription && (
                    <>
                      <Header className="removeMargin" as="h5">
                        Item Description{" "}
                      </Header>
                      <pre className="Wraptext">
                        {itemInfos.ItemDescription}
                      </pre>
                    </>
                  )}
            

                  {ActivityDescription && (
                    <>
                      <Header as="h5">Activity Description </Header>
                      <pre className="Wraptext">
                        {itemInfos.ActivityDescription}
                      </pre>
                    </>
                  )}
                  {ActivityWhatIsIncluded && (
                    <>
                      <Header as="h5">What is included ?</Header>
                      <pre className="Wraptext">
                        {itemInfos.ActivityWhatIsIncluded}
                      </pre>
                    </>
                  )}
                  {ActivityWhattoknowandbring && (
                    <>
                      <Header className="removeMargin" as="h5">
                        What to know and Bring ?
                      </Header>
                      <pre className="Wraptext">
                        {itemInfos.ActivityWhattoknowandbring}
                      </pre>
                    </>
                  )}
                  {this.props.seassonFalse ? (
                    <>
                      {itemInfos.Customfiels
                        ? itemInfos.Customfiels.map((item, index) => {
                            return (
                              <>
                                <Header as="h5">
                                  <Header.Content>
                                    {item.customField}
                                    <Header.Subheader>
                                      {item.customDescriptions}
                                    </Header.Subheader>
                                  </Header.Content>
                                </Header>
                              </>
                            );
                          })
                        : ""}
                    </>
                  ) : null}

                  {WhatToKnow && (
                    <>
                      <Header.Content>
                        <Header className="removeMargin" as="h5">
                          What to know ?
                        </Header>
                      </Header.Content>
                      <pre className="Wraptext">{itemInfos.WhatToKnow}</pre>
                    </>
                  )}
                  {WhatIsIncluded && (
                    <>
                      <Header className="removeMargin" as="h5">
                        What is included ?
                      </Header>

                      <pre className="Wraptext">{itemInfos.WhatIsIncluded}</pre>
                    </>
                  )}
                  {Description && (
                    <>
                      <Header className="removeMargin" as="h5">
                        What to know ?
                      </Header>
                      <pre className="Wraptext">{itemInfos.Description}</pre>
                    </>
                  )}
                </Grid.Column>
              </Grid>
            );
          })}
      </>
    );
  }
}

export default StepFour;
