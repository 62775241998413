import React, { Component } from "react";
import FaqPage  from './faq';

class Faq extends Component {
		
      render(){
          return(
              <FaqPage />
          )
      }
    }
export default Faq;