import React, { Component } from "react";
import {
  Grid,
  Header,
  Divider,
  Form,
  Button,
  Icon,
  Image,
  Feed,
} from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
} from "../../shared/functional/global-import";
import { reservation } from "../../shared/functional/global-image-import";
import moment from "moment";

class OrderDetailData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reservationModal: false,
    };
  }
  onhandleOpenFunction = () => {
    this.setState({ reservationModal: !this.state.reservationModal });
  };
  render() {
    const {
      allFuction,
      fullstate,
      ProductIndividualDetail,
    } = this.props;
    const { reservationModal } = this.state;
    const totalAmount = fullstate.newAmount.toFixed(2);              
    return (
      <>
        <Grid.Column computer={6} tablet={16} mobile={16}>
          <div className="myCartLeft">
            {/*  for wordpress */}
            {this.props.global.insideWordpress ? (
              <Button
                onClick={allFuction.onHandleBacktoShopCatergory}
                className="secondaryBtn backtoshop"
              >
                <Icon name="chevron left" />
                Back to shop
              </Button>
            ) : (
              //  widget scenario
              <Button
                onClick={() => allFuction.onHandleBackToShop(true)}
                
                className="secondaryBtn backtoshop"
              >
                <Icon name="chevron left" />
                Back to shop
              </Button>
            )}
            <div className="header">
              <Header as="h5">My Order </Header>
              <div className="viewOrderDetails">
                <Image
                  className="reservationClass"
                  src={reservation}
                  fluid
                  onClick={this.onhandleOpenFunction}
                />
                <p className="reservationDetail">
                  <strong>Details</strong>
                </p>

                {reservationModal && (
                  <div className="myOrder productOrder-screen">
                    <div className="myOrderHeader">
                      <Header as="h5" sub>
                        Order Details
                      </Header>
                      <Icon
                        className="close"
                        name="close"
                        onClick={this.onhandleOpenFunction}
                      />
                    </div>

                    <div className="myOrderBodyDetails">
                      {ProductIndividualDetail &&
                        ProductIndividualDetail.map(
                          (locationbusines, index) => {
                            const AssociateAddOns =
                              this.props.global.addBussinessId ===
                                locationbusines.addItem.businessId &&
                              locationbusines.addItem.CustomQuestions.filter(
                                (AssociateAddOns) =>
                                  AssociateAddOns.AssociateAddOns !== null &&
                                  AssociateAddOns.customQuestionOptionValue &&
                                  AssociateAddOns.customQuestionOptionValue[0]
                                    .IsChecked === true
                              ).map((AssociateAddOn) => AssociateAddOn);
                            let startDates = moment(
                              locationbusines.addItem.startDate,
                              "YYYY-MM-DD hh:mmA"
                            ).format("MMM D YYYY, hh:mm A");
                            let startDate =
                              startDates === "Invalid date"
                                ? false
                                : startDates;
                            let endDates = moment(
                              locationbusines.addItem.endDate,
                              "YYYY-MM-DD hh:mmA"
                            ).format("MMM D YYYY, hh:mm A");
                            let endDate =
                              endDates === "Invalid date" ? false : endDates;
                            let currentTim = moment(
                              locationbusines.addItem.currentTime,
                              "YYYY-MM-DD hh:mmA"
                            ).format("MMM D YYYY");
                            let currentTime =
                              currentTim === "Invalid date"
                                ? false
                                : currentTim;

                            return (
                              <Feed>
                                <Feed.Event>
                                  {locationbusines.addItem.image ===
                                  "NullImage.png" ? null : (
                                    <Feed.Label>
                                      <Image
                                        src={commonFunctions.concatenateImageWithAPIUrl(
                                          locationbusines.addItem.image
                                        )}
                                        fluid
                                      />
                                    </Feed.Label>
                                  )}
                                  <Feed.Content>
                                    <Feed.Summary>
                                      <span>
                                        {currentTime}{" "}
                                        {currentTime === false
                                          ? false
                                          : locationbusines.addItem
                                              .selectedTime}
                                      </span>
                                      <span>{startDate}</span>
                                      <span>{endDate}</span>
                                    </Feed.Summary>
                                    <Feed.Summary>
                                      <span>
                                        {locationbusines.addItem.name}
                                      </span>
                                    </Feed.Summary>
                                    {locationbusines.addItem.tickets?.length >
                                      0 &&
                                      locationbusines.addItem.tickets.map(
                                        (tickets) => {
                                          if (tickets.numberOfTickets !== 0) {
                                            return (
                                              <p>
                                                {" "}
                                                {tickets.numberOfTickets} x{" "}
                                                {tickets.title}
                                              </p>
                                            );
                                          } else return null;
                                        }
                                      )}
                                    <Feed.Summary>
                                      {locationbusines.addItem.addOns?.length >
                                        0 && (
                                        <div>
                                          <p>Addons</p>
                                        </div>
                                      )}
                                    </Feed.Summary>
                                    <>
                                      {locationbusines.addItem.addOns?.length >
                                        0 &&
                                        locationbusines.addItem.addOns.map(
                                          (addons, addOnIndex) => {
                                            if (addons.quantity !== 0) {
                                              return (
                                                <>
                                                  <div className="addOns">
                                                    {addons.Image ===
                                                    null ? null : (
                                                      <>
                                                        <Image
                                                          src={commonFunctions.concatenateImageWithAPIUrl(
                                                            addons.Image
                                                          )}
                                                          fluid
                                                        />
                                                      </>
                                                    )}
                                                    <span>
                                                      {addons.quantity} x{" "}
                                                      {addons.AddOnTitle}
                                                    </span>
                                                  </div>
                                                </>
                                              );
                                            }
                                            return null;
                                          }
                                        )}

                                      {AssociateAddOns?.length > 0 &&
                                        AssociateAddOns.map(
                                          (addons, addOnIndex) => {
                                            return (
                                              <>
                                                <div>
                                                  {addons.AssociateAddOns.map(
                                                    (iteminside) => {
                                                      return (
                                                        <>
                                                          <div className="addOns">
                                                            {iteminside.Image ===
                                                            "" ? null : (
                                                              <Image
                                                                src={commonFunctions.concatenateImageWithAPIUrl(
                                                                  iteminside.Image
                                                                )}
                                                                fluid
                                                              />
                                                            )}
                                                            <span>
                                                              {
                                                                iteminside.quantity
                                                              }{" "}
                                                              x{" "}
                                                              {
                                                                iteminside.AddOnTitle
                                                              }
                                                            </span>
                                                          </div>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </>
                                            );
                                          }
                                        )}
                                    </>
                                  </Feed.Content>
                                </Feed.Event>
                              </Feed>
                            );
                          }
                        )}
                    </div>
                    <div className="fotterClassName">
                      <p>
                        <span className="astrickSigned">*</span>
                        <strong>Click 'Back to Shop' to edit cart</strong>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="body">
              <Divider />
              <div className="price">
                <span>
                  <b>Subtotal</b>
                </span>
                <span>{commonFunctions.isNumberInteger(totalAmount)}</span>
              </div>
              <>
                {fullstate.showMultipleDiscounts.length > 0 &&
                  fullstate.showMultipleDiscounts.map((s, index) => (
                    <div className="price">
                      <span style={{ position: "relative" }}>
                        <b>Discount</b> ({s.discountTittle})
                        <Icon
                          disabled={
                            fullstate.showMultipleDiscounts?.length > 1
                              ? fullstate.showMultipleDiscounts?.length - 1 !==
                                index
                              : false
                          }
                          onClick={(e) => {
                            allFuction.removeDiscountAndcuopons(
                              e,
                              s.keyId,
                              index
                            );
                          }}
                          name="close"
                          style={{
                            color: "#c00",
                            position: "absolute",
                            top: "-4px",
                            right: "-20px",
                            cursor: "pointer",
                          }}
                        />
                      </span>
                      <span
                        style={{
                          color: "#c00",
                        }}
                      >
                        {commonFunctions.isNumberInteger(s.discountAmount)}
                      </span>
                    </div>
                  ))}
              </>
              {fullstate.newSubAmount != "" && fullstate.newSubAmount > 0 && (
                <div className="price">
                  <span>
                    <b>New Subtotal</b>
                  </span>
                  <span>
                    {commonFunctions.isNumberInteger(fullstate.newSubAmount)}
                  </span>
                </div>
              )}
              <div className="price">
                <span>
                  <b>Taxes & Fees</b>
                </span>
                <span>
                  {commonFunctions.isNumberInteger(fullstate.showTaxes)}
                </span>
              </div>
              <Divider />
              <div className="price">
                <span>
                  <b>Order Total</b>
                </span>
                <span>
                  {commonFunctions.isNumberInteger(fullstate.orderTotal)}
                </span>
              </div>
              <Divider hidden />
              <div className="coupon">
                <Form.Input
                  placeholder="Code"
     value={fullstate.discountCouponName}
                  onChange={allFuction.onCouponChange}
                  data="discountCouponName"
                  name="discountCouponName"
                  fluid
                  className="dicountApply"
                />
                <Button
                  disabled={fullstate.orderTotal === 0}
                  onClick={allFuction.applyDiscountOnItems}
                  className="primaryBtn"
                >
                  Apply
                </Button>
              </div>
              <Divider hidden />
            </div>
          </div>
        </Grid.Column>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      storeItemDetail: bindActionCreators(actions.storeItemDetail, dispatch),
      cartItemDelete: bindActionCreators(actions.cartItemDelete, dispatch),
      storeInsideWordpress: bindActionCreators(
        actions.storeInsideWordpress,
        dispatch
      ),
      storeWordpressBacktoStop: bindActionCreators(
        actions.storeWordpressBacktoStop,
        dispatch
      ),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailData);
