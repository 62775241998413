import React, { Component } from "react";
import { Button, Modal, Grid, Icon, Header } from "semantic-ui-react";

class OrderProcessingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closeModal: false,
    };
  }

  handleChange = () => {
    this.setState({ closeModal: !this.state.closeModal });
  };
  render() {
    return (
      <Modal
        open={this.props.openModal}
        onClose={this.props.closeModal}
        closeOnDimmerClick={false}
        size={"mini"}>  
        <Modal.Content scrolling className="orderPlaceErrorPopup">
          <Modal.Description className="orderPlaced">
            <Grid textAlign="center">
              <Grid.Column width={16}>
                <div className="orderProcessing">
                  <Icon loading name="spinner" className="processIcon"/>
                </div>
                <Header as="h2">Payment processing in progress.</Header>
              </Grid.Column>
            </Grid>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default OrderProcessingModal;
