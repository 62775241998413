import { Component } from "react";
import { Switch,Route,BrowserRouter as Router } from "react-router-dom";
import { AuthenticateTemplateRoutesConfing } from "./config/routes";
import PrivateRoute from "./shared/functional/private-route";
import "./assets/scss/app.scss";
// import "semantic-ui-css/semantic.min.css";
import { NotificationAlert,AuthenticateUserTemplate } from "./shared/components";
import AppContainer from "./app-container";
import { connect,bindActionCreators,actions } from "./shared/functional/global-import";
class App extends Component {
    render() {
    return (
      <div
        className={`app ${this.props.global.ShowHeaderOfWebsite === true ? "" : "hideHeader"
          }`}>
        <Router>
          <AppContainer>
            {/* <Suspense fallback={<></>}> */}
            <Switch>
              <AuthenticateUserTemplate>
                {/* <Suspense fallback={<Loader active />}> */}
                <Switch>
                  {AuthenticateTemplateRoutesConfing.map((route,i) => {
                    const Component = route.component;
                    return route.private ? (
                      // handle private routes of the application   userTypes={route.userTypes}
                      <PrivateRoute
                        key={i}
                        exact={route.exact}
                        path={route.path}
                        render={(props) => <Component {...props} />}
                      />
                    ) : (
                      // handle public routes of the application
                      <Route
                        key={i}
                        exact={route.exact}
                        path={route.path}
                        render={(props) => <Component {...props} />}
                      />
                    );
                  })}
                </Switch>
                {/* </Suspense> */}
              </AuthenticateUserTemplate>
              {/* </Route> */}
            </Switch>
            {/* </Suspense> */}
          </AppContainer>
        </Router>
        <NotificationAlert></NotificationAlert>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall,dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes,dispatch),
    },
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(App);
