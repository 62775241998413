import React, { Component } from "react";
import { Accordion, Grid, Dimmer, Loader, Header } from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
} from "../../shared/functional/global-import";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";

class faqPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      allFAQ: [],
      faqCategory: [],
      globalCodeId: "",
      globalCodes: false,
    };
  }

  componentDidMount() {
    this.getAllFAQCategory();
    let themeColor = this.props.global.themeColor;
    const root = document.documentElement;
    root?.style.setProperty("--main-color", themeColor);
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  // GET API for all FAQ Category
  getAllFAQCategory = () => {
    // const { faqCategory } = this.state;
    this.props.actions.apiCall({
      urls: ["ALLFAQCATEGORY"],
      method: "GET",
      data: {
        CategoryName: "FAQCategory",
        GlobalCodeId: -1,
      },
      onSuccess: (response) => {
        this.setState(
          { faqCategory: response, globalCodeId: response.globalCodeId },
          () => this.getAllQuestions()
        );
      },
    });
  };

  // GET API for all FAQ

  getAllQuestions = () => {
    this.props.actions.apiCall({
      urls: ["GETALLFAQ"],
      method: "GET",
      data: {
        businessId: this.props.global.addBussinessId,
        FaqCategoryId: -1,
      },
      onSuccess: (response) => {
        this.setState({ allFAQ: response });
      },
    });
  };

  render() {
    // const { activeIndex } = this.state;
    const { api } = this.props;
    return (
      <div className="landingPage">
        {api.isApiLoading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        <Grid>
          <Grid.Column width={16}>
            <Header as="h2" textAlign="center" className="commonHeader">
              FAQ
            </Header>
            {this.state.allFAQ.length > 0 && (
              <Accordion
                className="faqAccordin"
                defaultActiveIndex={0}
                panels={this.state.allFAQ.map((faq, index) => {
                  let keyName = "panel-" + index;
                  let questions = JSON.parse(faq.question);
                  let questionsPane = questions.map((question, index) => {
                    let subKeyName = "subpanel-" + index;
                    let answer = EditorState.createWithContent(
                      convertFromRaw(JSON.parse(question.Answer))
                    );
                    let document =
                      question.Document &&
                      JSON.parse(question.Document)[0].DocumentPath;
                    let DocumentName = JSON.parse(question.Document)[0]
                      .DocumentName;

                    return {
                      key: subKeyName,
                      title: `Question: ${question.Question}`,
                      content: {
                        content: (
                          <>
                            <p>
                              Answer :{" "}
                              <Editor
                                editorState={answer}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                toolbar={{
                                  options: [],
                                  inline: { inDropdown: true },
                                  list: { inDropdown: true },
                                  textAlign: { inDropdown: true },
                                  link: { inDropdown: true },
                                  history: { inDropdown: true },
                                }}

                                // onEditorStateChange={this.onEditorStateChange}
                              />
                            </p>
                            {document && (
                              <p>
                                Document :{" "}
                                <a
                                  target="blank"
                                  className="orange-color"
                                  href={commonFunctions.concatenateImageWithAPIUrl(
                                    document
                                  )}
                                >
                                  {DocumentName}
                                </a>
                              </p>
                            )}
                          </>
                        ),
                      },
                    };
                  });
                  return {
                    key: keyName,
                    title: faq.categoryName,
                    content: {
                      content: (
                        <div>
                          {questionsPane.length > 0 && (
                            <Accordion
                              className="answerList"
                              panels={questionsPane}
                            />
                          )}
                        </div>
                      ),
                    },
                  };
                })}
                styled
                fluid
              />
            )}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(faqPage);
