import React, { Component } from "react";
import { Grid, Header, Form, Divider} from "semantic-ui-react";


class StepThree extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	handleChange = (e, { value }) => this.setState({ value })

	Requirements = (e, { index, value, }) => {
		let Requirements = this.props.Requirements
		this.props.Requirements[index].IsChecked = !this.props.Requirements[index].IsChecked
		let aa = Requirements.every(v => v.IsChecked === true);
		this.props.allFuction.setState({ Requirements, policyDefaultValue: aa });
	}

	render() {
		const { Requirements, CancellationRequirement,customField } = this.props;
		
		return (
			<Grid>
				<Grid.Column width={16}>
					{CancellationRequirement &&
					<>
						<Header as="h5">Cancellation Policy</Header>
						<pre className="Wraptext">{CancellationRequirement}</pre>
					</>
	}
					<>
						{Requirements?.length>0 &&
							<>
								<Header as="h5">Requirements</Header>
								{Requirements.map((item, index) => {
									return (
										<>
											<Divider hidden />
											<Form.Checkbox  className="custom-checkbox"
												label={item.RequirementTitle}
												checked={item.IsChecked}
												data={item.IsChecked}
												value={item}
												onChange={(e, { value }) => this.Requirements(e, { index, value, })}
											/>
										</>
									);
								})}
							</>}

						{customField && <>
						
							{customField.map((item, index) => {
								return (
									<>
										<Header as='h5'>
											<Header.Content>
												{item.customField}
												<Header.Subheader>{item.customDescriptions}</Header.Subheader>
											</Header.Content>
										</Header>
									</>
								)
							})
							}
						</>}
					</>
				</Grid.Column>
			</Grid>
		);
	}
}

export default StepThree;
