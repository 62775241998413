import React, { Component } from "react";
import {
  Grid,
  Form,
  Header,
  Checkbox,
  Radio,
  TextArea,
} from "semantic-ui-react";
import { commonFunctions } from "../../shared/functional/global-import";

class StepFive extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toChekallCheckboxes = (multipleCheckBoxObj, parentindex) => {
    let CustomQuestions = this.props.CustomQuestions;
    const multipleCheckBoxValue = multipleCheckBoxObj.map((singleObj) => {
      return singleObj.IsChecked;
    });
    const isSameId = multipleCheckBoxValue.every(
      (currentValue) => currentValue === false
    );
    CustomQuestions[parentindex].isQuestionAnswered = isSameId
      ? ""
      : "answered";
    this.props.allFuction.setState({
      CustomQuestions,
    });
  };

  customQustion = (
    e,
    { data2, index2, index, value, customQuestionOptionValue, type }
  ) => {
    if (type === "Single Choice") {
      let CustomQuestions = this.props.CustomQuestions;
      CustomQuestions[index2].isQuestionAnswered = "answered";
      CustomQuestions[index2].customQuestionOptionValue.map((number, index) => {
        if (number.CustomQuestionOptionId === value.CustomQuestionOptionId) {
          let CustomQuestions = this.props.CustomQuestions;
          CustomQuestions[index2].customQuestionOptionValue[index].IsChecked =
            !CustomQuestions[index2].customQuestionOptionValue[index].IsChecked;
          this.props.allFuction.setState({
            CustomQuestions,
          });
        } else {
          let CustomQuestions = this.props.CustomQuestions;
          CustomQuestions[index2].customQuestionOptionValue[
            index
          ].IsChecked = false;
          this.props.allFuction.setState({
            CustomQuestions,
          });
        }
      });
    } else if (type === "custom-checkbox") {
    } else if (type === "Checkbox") {
      let CustomQuestions = this.props.CustomQuestions;
      CustomQuestions[index2].isQuestionAnswered = CustomQuestions[index2]
        .customQuestionOptionValue[0].IsChecked
        ? ""
        : "answered";
      CustomQuestions[index2].customQuestionOptionValue[0].IsChecked =
        !CustomQuestions[index2].customQuestionOptionValue[0].IsChecked;
      CustomQuestions[
        index2
      ].customQuestionOptionValue[0].CustomQuestionOptionValue = "true";
      this.props.allFuction.setState({
        CustomQuestions,
      });
    } else if (type === "Text Field") {
      let CustomQuestions = this.props.CustomQuestions;
      CustomQuestions[
        index2
      ].customQuestionOptionValue[0].CustomQuestionOptionValue = value;
      CustomQuestions[index2].isQuestionAnswered = value;
      if (value.length > 0) {
        CustomQuestions[index2].customQuestionOptionValue[0].IsChecked = true;
        this.props.allFuction.setState({
          CustomQuestions,
        });
      } else {
        CustomQuestions[index2].customQuestionOptionValue[0].IsChecked = false;
        this.props.allFuction.setState({
          CustomQuestions,
        });
      }
      this.props.allFuction.setState({
        CustomQuestions,
      });
    } else if (type === "Multiple Choice") {
      let CustomQuestions = this.props.CustomQuestions;
      CustomQuestions[index2].customQuestionOptionValue.map((number, index) => {
        if (
          number.CustomQuestionOptionId ===
          customQuestionOptionValue.CustomQuestionOptionId
        ) {
          let CustomQuestions = this.props.CustomQuestions;
          CustomQuestions[index2].customQuestionOptionValue[index].IsChecked =
            !CustomQuestions[index2].customQuestionOptionValue[index].IsChecked;
          this.props.allFuction.setState(
            {
              CustomQuestions,
            },
            this.toChekallCheckboxes(
              CustomQuestions[index2].customQuestionOptionValue,
              index2
            )
          );
        }
      });
    } else if (type === "Single Choice AddOns (Yes/No)") {
      let CustomQuestions = this.props.CustomQuestions;
      CustomQuestions[index2].isQuestionAnswered = "answered";
      CustomQuestions[index2].customQuestionOptionValue.map((number, index) => {
        if (number.CustomQuestionOptionId === value.CustomQuestionOptionId) {
          let CustomQuestions = this.props.CustomQuestions;
          CustomQuestions[index2].customQuestionOptionValue[index].IsChecked =
            !CustomQuestions[index2].customQuestionOptionValue[index].IsChecked;
          if (
            CustomQuestions[index2].customQuestionOptionValue[1].IsChecked &&
            CustomQuestions[index2].AssociateAddOnsPrice
          ) {
            let AssociateAddOnsPrice = CustomQuestions[index2].AssociateAddOns;
            CustomQuestions[index2].AssociateAddOnsPrice =
              !CustomQuestions[index2].AssociateAddOnsPrice;
            let AssociateAddOns = AssociateAddOnsPrice.reduce(
              (previousScore, currentScore, index) =>
                previousScore - JSON.parse(currentScore.Price),
              0
            );
            let totalPrice = this.props.allFuction.state.totalPriceToShow;
            this.props.allFuction.setState({
              AssociateAddOnsPrice: false,
              totalPriceToShow: JSON.parse(
                (AssociateAddOns + totalPrice).toFixed(2)
              ),
              totalprice: JSON.parse((AssociateAddOns + totalPrice).toFixed(2)),
              CustomQuestions,
            });
          }
        } else {
          let CustomQuestions = this.props.CustomQuestions;
          CustomQuestions[index2].customQuestionOptionValue[
            index
          ].IsChecked = false;

          if (CustomQuestions[index2].customQuestionOptionValue[0].IsChecked) {
            let AssociateAddOnsPrice = CustomQuestions[index2].AssociateAddOns;
            CustomQuestions[index2].AssociateAddOnsPrice =
              !CustomQuestions[index2].AssociateAddOnsPrice;
            let AssociateAddOns = AssociateAddOnsPrice.reduce(
              (previousScore, currentScore, index) =>
                previousScore + JSON.parse(currentScore.Price),
              0
            );
            let totalPrice = this.props.allFuction.state.totalPriceToShow;

            this.props.allFuction.setState({
              AssociateAddOnsPrice: AssociateAddOns,
              totalPriceToShow: JSON.parse(
                (AssociateAddOns + totalPrice).toFixed(2)
              ),
              totalprice: JSON.parse((AssociateAddOns + totalPrice).toFixed(2)),
              CustomQuestions,
            });
          } else {
            CustomQuestions[index2].customQuestionOptionValue[
              index
            ].IsChecked = false;
            this.props.allFuction.setState({
              AssociateAddOnsPrice: false,
              CustomQuestions,
            });
          }
        }
      });
    }
  };
  handleChange = (e, { value }) => {
    this.setState({ value });
  };

  render() {
    const { wordsleft } = this.state;
    const { CustomQuestions, Requirements, allFuction } = this.props;
    return (
      <>
        <Grid>
          {CustomQuestions?.length > 0 &&
            CustomQuestions.map((CustomQuestion, index2) => {
              const type = CustomQuestion.customQuestionType;
              switch (CustomQuestion.customQuestionType) {
                case "Multiple Choice":
                  return (
                    <Grid.Column width={16}>
                      <div className="MultipleChoice">
                        <Header as="h4">
                          {" "}
                          {CustomQuestion.customQuestion}
                        </Header>

                        {CustomQuestion.customQuestionOptionValue &&
                          CustomQuestion.customQuestionOptionValue.map(
                            (customQuestionOptionValue, index1) => {
                 
                              return (
                                <div className="insideMultipleChoice">
                                  {
                                    <>
                                      <Checkbox
                                        className="custom-checkbox"
                                        label={
                                          customQuestionOptionValue.CustomQuestionOptionValue
                                        }
                                        value={
                                          customQuestionOptionValue.IsChecked
                                        }
                                        customQuestionOptionValue={
                                          customQuestionOptionValue
                                        }
                                        checked={
                                          customQuestionOptionValue.IsChecked
                                        }
                                        data={
                                          customQuestionOptionValue.IsChecked
                                        }
                                        data2={CustomQuestion}
                                        onChange={(e, { data2, value }) =>
                                          this.customQustion(e, {
                                            data2,
                                            index2,
                                            value,
                                            customQuestionOptionValue,
                                            type,
                                          })
                                        }
                                      />
                                      &nbsp;
                                    </>
                                  }
                                </div>
                              );
                            }
                          )}

                        <div>
                          {CustomQuestion.required
                            ? allFuction.validator.message(
                                "multiple choice",
                                CustomQuestion.isQuestionAnswered,
                                "required"
                              )
                            : false}
                        </div>
                      </div>
                    </Grid.Column>
                  );
                case "Checkbox":
                     
                  return (
                    <>
                      <Grid.Column width={16}>
                        <div className="Checkbox">
                          <Checkbox
                            className="question-checkbox"
                            value={
                              CustomQuestion.customQuestionOptionValue &&
                              CustomQuestion.customQuestionOptionValue[0]
                                .IsChecked
                            }
                            customQuestionOptionValue={CustomQuestion}
                            checked={
                              CustomQuestion.customQuestionOptionValue &&
                              CustomQuestion.customQuestionOptionValue[0].IsChecked
                            }
                            data={
                              CustomQuestion.customQuestionOptionValue &&
                              CustomQuestion.customQuestionOptionValue[0]
                                .IsChecked
                            }
                            data2={CustomQuestion}
                            onChange={(
                              e,
                              { data2, value, customQuestionOptionValue }
                            ) =>
                              this.customQustion(e, {
                                data2,
                                index2,
                                value,
                                customQuestionOptionValue,
                                type,
                              })
                            }
                          />
                          &nbsp;
                          <span>
                            <h4>{CustomQuestion.customQuestion}</h4>
                          </span>
                        </div>
                        <span>
                          {CustomQuestion.required
                            ? allFuction.validator.message(
                                "checkbox",
                                CustomQuestion.isQuestionAnswered,
                                "required"
                              )
                            : false}
                        </span>
                      </Grid.Column>
                    </>
                  );
                case "Single Choice":
                     
                  return (
                    <Grid.Column width={16}>
                      <div className="singleChoice">
                        <Header className="spacebetweenAnserQuestion" as="h4">
                          {CustomQuestion.customQuestion}
                        </Header>
                        {CustomQuestion.customQuestionOptionValue &&
                          CustomQuestion.customQuestionOptionValue.map(
                            (customQuestionOptionValue, index1) => {
                              return (
                                <div className="forAddspacing">
                                  {
                                    <Radio
                                      className="custom-radio"
                                      name={`radio ${customQuestionOptionValue.CustomQuestionOptionId}`}
                                      checked={
                                        customQuestionOptionValue.IsChecked
                                      }
                                      label={
                                        customQuestionOptionValue.CustomQuestionOptionValue
                                      }
                                      value={customQuestionOptionValue}
                                      data2={CustomQuestion}
                                      onChange={(e, { data2, value }) =>
                                        this.customQustion(e, {
                                          data2,
                                          index2,
                                          value,
                                          type,
                                        })
                                      }
                                    />
                                  }
                                  &nbsp;
                                </div>
                              );
                            }
                          )}
                        <div>
                          {CustomQuestion.required
                            ? allFuction.validator.message(
                                "single Choice",
                                CustomQuestion.isQuestionAnswered,
                                "required"
                              )
                            : false}
                        </div>
                      </div>
                    </Grid.Column>
                  );
                case "Text Field":
                     
                  return (
                    <Grid.Column width={16}>
                      <div className="textField">
                        {CustomQuestion.customQuestionOptionValue &&
                          CustomQuestion.customQuestionOptionValue.map(
                            (customQuestionOptionValue, index1) => {
                              return (
                                <>
                                  <div>
                                    <h4 className="spacebetweenAnserQuestion">
                                      {CustomQuestion.customQuestion}
                                    </h4>
                                  </div>
                                  {wordsleft}
                                  <div>
                                    {
                                      <TextArea
                                        placeholder="Type Here..."
                                        style={{ width: 300 }}
                                        value={
                                          customQuestionOptionValue.CustomQuestionOptionValue
                                        }
                                        data2={CustomQuestion}
                                        onChange={(e, { data2, value }) =>
                                          this.customQustion(e, {
                                            data2,
                                            index2,
                                            value,
                                            type,
                                          })
                                        }
                                        maxLength="200"
                                      />
                                    }
                                  </div>
                                  <div>
                                    {CustomQuestion.required
                                      ? allFuction.validator.message(
                                          "text",
                                          CustomQuestion.isQuestionAnswered,
                                          "required"
                                        )
                                      : false}
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                    </Grid.Column>
                  );
                case "Single Choice AddOns (Yes/No)":
                  const addons =
                    typeof CustomQuestion.AssociateAddOns === "string"
                      ? JSON.parse(CustomQuestion.AssociateAddOns)
                      : CustomQuestion.AssociateAddOns;
   
                  return (
                    <Grid.Column width={16}>
                      <div className="AddOns">
                        <Header className="spacebetweenAnserQuestion" as="h4">
                          {CustomQuestion.customQuestion}
                        </Header>
                        <div className="radioBtnWithText">
                          {CustomQuestion.customQuestionOptionValue &&
                            CustomQuestion.customQuestionOptionValue.map(
                              (customQuestionOptionValue, index) => {
                                return (
                                  <>
                                    <div className="forAddspacing">
                                      <span className="insideMultipleChoice">
                                        {
                                          <Radio
                                            className="custom-radio pr-10"
                                            name={`radioGroup-${CustomQuestion.productcustomquestionId}`}
                                            checked={
                                              customQuestionOptionValue.IsChecked
                                            }
                                            label={
                                              customQuestionOptionValue.CustomQuestionOptionValue
                                            }
                                            value={customQuestionOptionValue}
                                            data2={CustomQuestion}
                                            onChange={(
                                              e,
                                              { data2, value, index }
                                            ) =>
                                              this.customQustion(e, {
                                                data2,
                                                index2,
                                                index,
                                                value,
                                                type,
                                              })
                                            }
                                          />
                                        }
                                      </span>
                                    </div>
                                  </>
                                );
                              }
                            )}
                        </div>

                        <div className="AsociateAddons">
                          (
                          {addons &&
                            addons.map((customQuestionOptionValue, index1) => {
                                 
                              return (
                                <>
                                  <span>
                                    {customQuestionOptionValue.AddOnTitle},
                                    &nbsp;
                                  </span>
                                </>
                              );
                            })}
                          )
                        </div>
                        <div>
                          {CustomQuestion.required
                            ? allFuction.validator.message(
                                "Single Choice AddOns",
                                CustomQuestion.isQuestionAnswered,
                                "required"
                              )
                            : false}
                        </div>
                      </div>
                    </Grid.Column>
                  );
                default:
                  return null;
              }
            })}

          <div className="totalAmountAddOn">
            {commonFunctions.isNumberInteger(
              this.props.allFuction.state.totalPriceToShow
            ) ? (
              <Header as="h4" className="durationSubCaption secondary">
                Total :{" "}
                <span className="itemValue">
                    {commonFunctions.isNumberInteger(
                      this.props.allFuction.state.totalPriceToShow
                    )}                
                  </span>
              </Header>
            ) : (
              ""
            )}
          </div>

          {CustomQuestions?.length === 0 && (
            <Grid.Column width={16}>
              <Form>
                {Requirements && (
                  <>
                    <Header className="spacebetweenAnserQuestion" as="h5">
                      Requirements
                    </Header>
                    {Requirements.map((item) => {
                      return (
                        <Form.Checkbox
                          label={item}
                          className="custom-checkbox"
                        />
                      );
                    })}
                  </>
                )}
              </Form>
            </Grid.Column>
          )}
        </Grid>
      </>
    );
  }
}
export default StepFive;
