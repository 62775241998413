import React, { Component } from "react";
import {
  Container,
  Grid,
  Loader,
  Dimmer,
  List,
  Icon,
  Button,
} from "semantic-ui-react";

class ThankYouPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Container className="ThankYouPage">
          {this.state.isApiLoading && (
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          )}
          <Grid className="thankYouPageContaner">
            <List className="listThankyouPage">
              <List.Item className="thankyou">Thank you!</List.Item>
              <List.Item className="thankyou-document">
                Your document (s) have been successfully signed for and
                submitted!
              </List.Item>
              <List.Item className="thankyou-signed">
                A copy of your signed document has been sent to your email
                inbox. <Icon name="paper plane" />
              </List.Item>
            </List>
          </Grid>
          <div className="signer-text-right">
            <Button
              type="submit"
              className="new-signer"
              onClick={this.props.backToWaiverScreen}
            >
              Sign with new signer
            </Button>
          </div>
        </Container>
      </>
    );
  }
}
export default ThankYouPage;
