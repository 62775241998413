import React, { Component } from "react";
import {
  Grid,
  Icon,
  Input,
  Header,
  Divider,
} from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../shared/functional/global-import";

class RetailBulkOne extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 0,
      retailTotalprice: "",
    };
  }

  // this function is used for decrease item quantity.
  countHandleChangeMinusRetail = () => {
    const { minReatilBulkAble } = this.props.allFuction.state;
    const count = this.props.allFuction.state.count;
    if (count >= 1) {
      let number =
        minReatilBulkAble === count ? count - minReatilBulkAble : count - 1;
      let retailTotalprice = JSON.parse(
        (this.props.addItem.price * number).toFixed(2)
      );
      this.setState({ count: number, retailTotalprice: retailTotalprice });
      this.props.allFuction.setState({
        count: number,
        totalprice: retailTotalprice,
        totalPriceToShow: retailTotalprice,
      });
      if (number <= 0) {
        this.props.allFuction.setState({ addToCArtOrNot: false });
      }
    }
  };

  // this function is used for increase item quantity.
  countHandleChangePlusRetail = () => {
    const count = this.props.allFuction.state.count;
    const { maxReatilBulkAble, minReatilBulkAble } =
      this.props.allFuction.state;
    if (this.props.itemCount > count) {
      if (
        count < (maxReatilBulkAble ? maxReatilBulkAble : this.props.itemCount)
      ) {
        let number =
          minReatilBulkAble > 0 && count === 0 ? minReatilBulkAble : count + 1;
        let retailTotalprice = JSON.parse(
          (this.props.addItem.price * number).toFixed(2)
        );
        this.setState({ count: number, retailTotalprice: retailTotalprice });
        this.props.allFuction.setState({
          count: number,
          totalprice: retailTotalprice,
          totalPriceToShow: retailTotalprice,
        });
        if (number > 0) {
          this.props.allFuction.setState({ addToCArtOrNot: true });
        }
        if (minReatilBulkAble > 0 && count === 0) {
          this.props.actions.showNotification(
            {
              title: "success",
              message: `This retail required min ${minReatilBulkAble} items.`,
              position: "br",
              autoDismiss: commonFunctions.notificationTime(),
            },
            "success"
          );
        }
      } else {
        this.props.actions.showNotification(
          {
            title: "Warning",
            message: `You can't add more retail bulk item for this.`,
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      }
    } else {
      this.props.actions.showNotification(
        {
          title: "Warning",
          message: "There are no more available items that can be added. ",
          position: "br",
          autoDismiss: commonFunctions.notificationTime(),
        },
        "error"
      );
    }
  };
  render() {
    const { ItemDescription, customField, isShowAvailableItem } = this.props;

    return (
      <Grid>
        <Divider hidden />
        {/* This Section is used for Add Item Quantity For Selected Item */}
        <Grid.Row>
          {isShowAvailableItem ? (
            <>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <h4 className="availableItemsList">
                  Available Items                   
                  {this.props.itemCount && (
                        <span>{this.props.itemCount}</span>                  
                    )}
                </h4>
              </Grid.Column>
              
            </>
          ) : null}
          <Divider hidden />

          <Grid.Column width={5}>
            <div className="add-numbers">
              <Icon
                name="minus"
                className="orange-button"
                onClick={this.countHandleChangeMinusRetail}
              ></Icon>
              <Input
                value={this.props.allFuction.state.count}
                name="numberOfTickets"
                readOnly
                fluid
              />
              <Icon
                name="plus"
                className="orange-button plus-btn"
                onClick={this.countHandleChangePlusRetail}
              ></Icon>
            </div>
          </Grid.Column>
        </Grid.Row>
        {this.props.allFuction.state.totalprice ? (
          <Grid.Column width={16}>
            <Header as="h4" className="durationSubCaption secondary">
                Total :
                <span className="itemValue">
                    {commonFunctions.isNumberInteger(
                    this.props.allFuction.state.totalprice
                  )}
                </span>
            </Header>
          </Grid.Column>
        ) : (
          ""
        )}
        <>
          {" "}
          {ItemDescription && (
            <>
              {" "}
              <Grid.Column width={16}>
                <Header as="h5">Item Description : </Header>
                <Header.Subheader>{ItemDescription}</Header.Subheader>
              </Grid.Column>
            </>
          )}
          {customField &&
            customField.map((item, index) => {
              return (
                <>
                  <Header as="h5">
                    <Header.Content>
                      {item.customField}
                      <Header.Subheader>
                        {item.customDescriptions}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </>
              );
            })}
        </>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RetailBulkOne);
