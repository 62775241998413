import React, { Component } from "react";
import { Grid, Image, Feed, Header, Icon, Button } from "semantic-ui-react";
import { Link } from "../../shared/functional/global-import";
import RentalPeriodModal from "../../shared/components/organisms/modal/rental-period/index";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
} from "../../shared/functional/global-import";
import { empty } from "../../shared/functional/global-image-import";
import moment from "moment";

class AddCartPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AddCartPopUp: false,
      todayDate: "",
      isShowPopup: true,
    };
  }

  componentDidMount = () => {
    this.onGetBusinesdate();
  };

  onGetBusinesdate = () => {
    this.props.actions.apiCall({
      urls: ["CURRENTDATEFORBUSINESS"],
      method: "GET",
      data: {
        BusinessLocationId: this.props.global.bussinessLocationId,
      },
      onSuccess: (response) => {
        let aa =
          response &&
          response[0] &&
          response[0].startTime &&
          new Date(response[0].startTime);
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        var today = aa;
        var dd = String(today.getDate()).padStart(2, "0");
        var mmmm = monthNames[today.getMonth()];
        var yyyy = today.getFullYear();
        today = `${mmmm} ${dd}, ${yyyy}`;
        this.setState({ todayDate: today });
      },
    });
  };

  AddRentalModal = () => {
    this.setState({ rentalStatus: !this.state.rentalStatus });
  };

  showItemInCart = () => {
    this.onGetBusinesdate();
    this.setState({ AddCartPopUp: !this.state.AddCartPopUp });
  };

  onDeleteItem = (e) => {
    const newList = this.props.global.allProductDetail.products.filter(
      (item) => this.props.global.allProductDetail.products.indexOf(item) !== e
    );
    this.props.actions.cartItemDelete(newList);
  };

  onDeleteAddons = (productIndex, addOnIndex) => {
    const productToUpdate =
      this.props.global.allProductDetail.products[productIndex];
    const updatedAddOns = productToUpdate.addItem.addOns.filter(
      (item, addOnsIndex) => addOnIndex !== addOnsIndex
    );
    const count = productToUpdate.addItem.addOns.filter(
      (item, addOnsIndex) => addOnIndex === addOnsIndex
    )[0].quantity;
    const price = productToUpdate.addItem.addOns.filter(
      (item, addOnsIndex) => addOnIndex === addOnsIndex
    )[0].Price;

    const FinalPrice = JSON.parse((price * count).toFixed(2));
    const finalProducts = this.props.global.allProductDetail.products.map(
      (item, i) => {
        if (productIndex === i) {
          item.addItem.addOns = updatedAddOns;

          item.addItem.totalprice = JSON.parse(
            (item.addItem.totalprice - FinalPrice).toFixed(2)
          );
        }
        return item;
      }
    );
    this.props.actions.cartItemDelete(finalProducts);
  };
  onDeleteAssociateAddOns = (
    productIndex,
    addOnIndex,
    iteminside,
    productcustomquestionId
  ) => {
    const updateAssociateAddOns =
      this.props.global.allProductDetail.products[productIndex];
    var updateAssociate = updateAssociateAddOns.addItem.CustomQuestions.map(
      function (el) {
        var o = Object.assign({}, el);
        if (
          o.AssociateAddOns !== null &&
          o.productcustomquestionId === productcustomquestionId
        ) {
          o.AssociateAddOns = o.AssociateAddOns.filter((obj) => {
            return obj.AddOnId !== iteminside.AddOnId;
          });
        }
        return o;
      }
    );
    const finalProducts = this.props.global.allProductDetail.products.map(
      (item, i) => {
        if (productIndex === i) {
          item.addItem.CustomQuestions = updateAssociate;
          item.addItem.totalprice = JSON.parse(
            (item.addItem.totalprice - iteminside.Price).toFixed(2)
          );
        }
        return item;
      }
    );

    this.props.actions.cartItemDelete(finalProducts);
  };

  render() {
    const itemCount =
      this.props.global?.allProductDetail?.products?.length <= 0
        ? false
        : this.props.global?.allProductDetail?.products?.length;
    const emptyShow =
      this.props.global?.allProductDetail?.products?.length < 1 ? true : false;
    const totalPrice = this.props.global?.allProductDetail?.products.reduce(
      (previousScore, currentScore, index) =>
        previousScore + JSON.parse(currentScore.addItem.totalprice),
      0
    );

    const subTotalAmount = totalPrice?.toFixed(2);
    return (
      <>
        {this.props.global.showCount && (
          <>
            {itemCount && (
              <>
                {/* {showIcon && ( */}
                <div
                  className={itemCount && "cartIcon"}
                  onClick={this.showItemInCart}
                >
                  {itemCount && (
                    <p>
                      {" "}
                      <span className="itemCount">{itemCount}</span>
                    </p>
                  )}
                  <Icon name="cart plus" />
                  {emptyShow && <span>Your cart is empty</span>}
                  {!emptyShow && <span>Your item is in cart</span>}
                </div>
                {/* )} */}
              </>
            )}
        
        

        {this.state.AddCartPopUp && (
          <div className="myOrder">
            {/* <div>
						<Button className="secondaryBtn" floated="left" as={Link} to={`${env.PUBLIC_URL}${this.props.global.bussinessUrl}`}>Back to Shop</Button>
						</div> */}
            <image></image>
            <div className="myOrderHeader">
              <Header as="h5" sub>
                My order
              </Header>
              <Icon
                className="close"
                name="close"
                onClick={this.showItemInCart}
              />
            </div>
            <div className="header">
              <p>{this.state.todayDate}</p>
            </div>
            <div className="myOrderBody">
              {!itemCount && (
                <Grid.Column width={16} className="categoryErrorOuter">
                  <div className="categoryError">
                    <h3>No item in your cart </h3>
                    <Image className="emptyError" src={empty}></Image>
                  </div>
                </Grid.Column>
              )}
              {this.props.global.allProductDetail.products &&
                this.props.global.allProductDetail.products.length > 0 &&
                this.props.global.allProductDetail.products.map(
                  (locationbusines, index) => {
                    const AssociateAddOns =
                      this.props.global.addBussinessId ===
                        locationbusines.addItem.businessId &&
                      locationbusines.addItem.CustomQuestions.filter(
                        (AssociateAddOns) =>
                          AssociateAddOns.AssociateAddOns !== null &&
                          AssociateAddOns.customQuestionOptionValue &&
                          AssociateAddOns.customQuestionOptionValue[0]
                            .IsChecked === true
                      ).map((AssociateAddOn) => AssociateAddOn);
                    let aa = moment(
                      locationbusines.addItem.startDate,
                      "YYYY-MM-DD hh:mmA"
                    ).format("MMM D YYYY, hh:mm A");
                    let startDate = aa === "Invalid date" ? false : aa;
                    let bb = moment(
                      locationbusines.addItem.endDate,
                      "YYYY-MM-DD hh:mmA"
                    ).format("MMM D YYYY, hh:mm A");
                    let endDate = bb === "Invalid date" ? false : bb;
                    let cc = moment(
                      locationbusines.addItem.currentTime,
                      "YYYY-MM-DD hh:mmA"
                    ).format("MMM D YYYY");
                    let currentTime = cc === "Invalid date" ? false : cc;

                    return (
                      <Feed>
                        <Feed.Event>
                          {locationbusines.addItem.image ===
                          "NullImage.png" ? null : (
                            <Feed.Label>
                              <Image
                                src={commonFunctions.concatenateImageWithAPIUrl(
                                  locationbusines.addItem.image
                                )}
                                fluid
                              />
                            </Feed.Label>
                          )}
                          <Feed.Content>
                            <Feed.Summary>
                              <span>
                                {currentTime}{" "}
                                {currentTime === false
                                  ? false
                                  : locationbusines.addItem.selectedTime}
                              </span>
                              <span>{startDate}</span>
                              <span>{endDate}</span>
                            </Feed.Summary>
                            <Feed.Summary>
                              <span>{locationbusines.addItem.name}</span>
                              <Icon
                                name="close"
                                color="grey"
                                onClick={() => this.onDeleteItem(index)}
                              />
                            </Feed.Summary>
                            {locationbusines.addItem.tickets?.length > 0 &&
                              locationbusines.addItem.tickets.map((tickets) => {
                                if (tickets.numberOfTickets !== 0) {
                                  return (
                                    <p>
                                      {" "}
                                      {tickets.numberOfTickets} x{" "}
                                      {tickets.title}
                                    </p>
                                  );
                                } else return null;
                              })}
                            <Feed.Summary>
                              {locationbusines.addItem.addOns?.length > 0 && (
                                <div>
                                  <p>Addons</p>
                                </div>
                              )}
                            </Feed.Summary>
                            <>
                              {locationbusines.addItem.addOns?.length > 0 &&
                                locationbusines.addItem.addOns.map(
                                  (addons, addOnIndex) => {
                                    if (addons.quantity !== 0) {
                                      return (
                                        <>
                                          <div className="addOns">
                                            {addons.Image === null ? null : (
                                              <>
                                                <Image
                                                  src={commonFunctions.concatenateImageWithAPIUrl(
                                                    addons.Image
                                                  )}
                                                  fluid
                                                />
                                              </>
                                            )}
                                            <span>
                                              {addons.quantity} x{" "}
                                              {addons.AddOnTitle}
                                            </span>
                                            <Icon
                                              name="close"
                                              color="grey"
                                              onClick={() =>
                                                this.onDeleteAddons(
                                                  index,
                                                  addOnIndex
                                                )
                                              }
                                            />
                                          </div>
                                        </>
                                      );
                                    }
                                    return null;
                                  }
                                )}

                              {AssociateAddOns?.length > 0 &&
                                AssociateAddOns.map((addons, addOnIndex) => {
                                  let productcustomquestionId =
                                    AssociateAddOns[addOnIndex]
                                      .productcustomquestionId;
                                  return (
                                    <>
                                      <div>
                                        {addons.AssociateAddOns.map(
                                          (iteminside) => {
                                            return (
                                              <>
                                                <div className="addOns">
                                                  {iteminside.Image ===
                                                  "" ? null : (
                                                    <Image
                                                      src={commonFunctions.concatenateImageWithAPIUrl(
                                                        iteminside.Image
                                                      )}
                                                      fluid
                                                    />
                                                  )}
                                                  <span>
                                                    {iteminside.quantity} x{" "}
                                                    {iteminside.AddOnTitle}
                                                  </span>
                                                  <Icon
                                                    name="close"
                                                    color="grey"
                                                    onClick={() =>
                                                      this.onDeleteAssociateAddOns(
                                                        index,
                                                        addOnIndex,
                                                        iteminside,
                                                        productcustomquestionId
                                                      )
                                                    }
                                                    link
                                                  />
                                                </div>
                                              </>
                                            );
                                          }
                                        )}
                                      </div>
                                    </>
                                  );
                                })}
                            </>
                            {totalPrice && (
                              <Feed.Summary>
                                <span>
                                  Total amount :
                                  {commonFunctions.isNumberInteger(
                                    locationbusines.addItem.totalprice
                                  )}
                                </span>
                              </Feed.Summary>
                            )}
                          </Feed.Content>
                        </Feed.Event>
                      </Feed>
                    );
                  }
                )}
            </div>

            <div className="myOrderFooter">
              <Grid>
                <Grid.Column width="8" className="rentit-Subtotal">
                  <p className="rantit-Subtotal">Subtotal</p>
                </Grid.Column>
                <Grid.Column
                  width="8"
                  textAlign="right"
                  className="rentit-Subtotal"
                >
                  <p className="rantit-totalPrice">
                    {commonFunctions.isNumberInteger(subTotalAmount)}
                  </p>
                </Grid.Column>

                {this.props.bookNow !== true && (
                  <Grid.Column className="rentit-Checkout" width="16">
                    <Button
                      disabled={emptyShow}
                      className="primaryBtn"
                      as={Link}
                      to="/my-cart"
                    >
                      Checkout
                    </Button>
                  </Grid.Column>
                )}
                {this.props.bookNow === true && (
                  <Grid.Column className="rentit-Checkout" width="16">
                    <Button
                      disabled={emptyShow}
                      className="primaryBtn"
                      //  as={Link}
                      // to="/my-cart"
                      onClick={() => {
                        this.props.openaddtocart();
                      }}
                    >
                      Checkout
                    </Button>
                  </Grid.Column>
                )}
              </Grid>
            </div>
          </div>
        )}
  </>
        )}
        <RentalPeriodModal
          openModal={this.state.rentalStatus}
          closeModal={this.AddRentalModal}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      storeItemDetail: bindActionCreators(actions.storeItemDetail, dispatch),
      cartItemDelete: bindActionCreators(actions.cartItemDelete, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddCartPopUp);
