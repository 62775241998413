import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { Image, Header, Button, Container, Modal } from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  withRouter,
  Notifications,
} from "../../shared/functional/global-import";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
class LocationViewWordpressFolder extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { multipltLocationData, allFuction, bussinessPathName } = this.props;
                    
    return (
      <div>
        <Modal
          open={this.props.openModal}
          onClose={this.props.closeModal}
          closeIcon
          size={"fullscreen"}
          className="locationDescriptionModal"
        >
          <Modal.Content scrolling>
            <Modal.Description className="locationDescriptionWrapper">
              <div
                className={
                  multipltLocationData.length > 3
                    ? " locationViewModal "
                    : "locationViewModal  locationWordpress"
                }
              >
                <Container>
                  <>
                    <Swiper
                      spaceBetween={15}
                      navigation={
                        multipltLocationData.length > 3 ? true : false
                      }
                      autoplay
                      onSwiper={(swiper) => console.log(swiper)}
                      onSlideChange={() => console.log("slide change")}
                      breakpoints={{
                        768: {
                          slidesPerView: 1,
                        },
                        1199: {
                          slidesPerView: 3,
                        },
                      }}
                    >
                      {multipltLocationData &&
                        multipltLocationData.map(
                          (multipleLocationView, index) => {
                            const imageShowValue =
                              multipleLocationView.image === "NullImage.png";
                                            
                            return (
                              <SwiperSlide className="noImageThumbnail">
                                <>
                                  <div className="orderPlacedItem">
                                    <div className="cmn-shad">
                                      {multipleLocationView.image ===
                                      "NullImage.png" ? null : (
                                        <Image
                                          src={commonFunctions.concatenateImageWithAPIUrl(
                                            multipleLocationView.image
                                          )}
                                          fluid
                                        />
                                      )}

                                      <div
                                        className={
                                          imageShowValue
                                            ? "locatioshowTheText locationActionBtn"
                                            : "locationActionBtn"
                                        }
                                      >
                                        <Header as="h5">
                                          {multipleLocationView.displayName}
                                        </Header>
                                        <Button
                                          className="primaryBtn"
                                          fluid
                                          onClick={() =>
                                            allFuction.onClickLocationView(
                                              multipleLocationView,
                                              bussinessPathName
                                            )
                                          }
                                        >
                                          View
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              </SwiperSlide>
                            );
                          }
                        )}
                    </Swiper>

                    <div className="mobLocationWrapper">
                      {multipltLocationData &&
                        multipltLocationData.map(
                          (multipleLocationView, index) => {
                                            
                            return (
                              <div className="orderPlacedItem">
                                <div className="cmn-shad">
                                  {multipleLocationView.image ===
                                  "NullImage.png" ? null : (
                                    <Image
                                      src={commonFunctions.concatenateImageWithAPIUrl(
                                        multipleLocationView.image
                                      )}
                                      fluid
                                    />
                                  )}

                                  <div className="locationActionBtn">
                                    <Header as="h5">
                                      {multipleLocationView.displayName}
                                    </Header>
                                    <Button
                                      className="primaryBtn"
                                      fluid
                                      onClick={() =>
                                        allFuction.onClickLocationView(
                                          multipleLocationView,
                                          bussinessPathName
                                        )
                                      }
                                    >
                                      View
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </>
                </Container>
              </div>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      StoreBussinessUrl: bindActionCreators(
        actions.StoreBussinessUrl,
        dispatch
      ),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      DeleteAllItemDetail: bindActionCreators(
        actions.DeleteAllItemDetail,
        dispatch
      ),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      storeLocationTimeZone: bindActionCreators(
        actions.storeLocationTimeZone,
        dispatch
      ),
      storeThemeColor: bindActionCreators(actions.storeThemeColor, dispatch),
      storeParentId: bindActionCreators(actions.storeParentId, dispatch),
      SHOWHEADERWEBSITE: bindActionCreators(
        actions.SHOWHEADERWEBSITE,
        dispatch
      ),
      storeBussinessLocationId: bindActionCreators(
        actions.storeBussinessLocationId,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LocationViewWordpressFolder)
);
