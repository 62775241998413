import * as env from "./env.config";

// Read api url from env file
// const API_URL = "https://rehntitapiapp.azurewebsites.net/api";
// need to comment i.e. for staging

// const API_URL = "https://rehntitapistaging.azurewebsites.net/api";

//  Dynamic code now need to change in environment file
const API_URL = env.API_URL;

// API end points
const API_ENDPOINTS = {
  GETBUSINESSCATEGORYWEBSIYTE:
    "/BusinessCategoryWebsiteWidget/GetBusinessCategoryWebsiteWidget",
  GETLOCATIONFORBUSINESS:
    "/BusinessCategoryWebsiteWidget/GetLocationForBusiness",
  GETCATEGORIESFORBUSINESS:
    "/BusinessCategoryWebsiteWidget/GetCategoriesForBusiness", // business category API
  GETALLFAQ: "/BusinessCategoryWebsiteWidget/GetFAQForBusiness",
  ALLFAQCATEGORY: "/GlobalCode",
  GETDETAILSFORWIDGET: "/BusinessCategoryWebsiteWidget/GetDetailForWebsite",
  BUSINESSCATEGORYWEBSITEWIDGET:
    "/BusinessCategoryWebsiteWidget/GetItemDetailSummaryWidget",
  GETBUSINESSLOCATION: "/BusinessLocation/GetBusinessLocation",
  GETPRODUCTAVAILABILTY:
    "/BusinessCategoryWebsiteWIdget/GetProductAvailabiltyForWebsiteWidget",
  GETLOCATIONBYITEMTYPE: "/BusinessLocationByItem/BusinessLocationByItem",
  GETACTIVITYITIMESLOTES: "/Order/GetActivityTimeSlots",
  GETACTIVITYPRICE: "/PricingAndtax/GetActivityPrice",
  GETTIMESLOTESFORPRODUCTS:
    "/BusinessCategoryWebsiteWidget/GetTimeSlotsforProductWebSiteWidgetAsync",
  GETDETAILFROMEMAIL:
    "/BusinessCategoryWebsiteWidget/GetCustomerDetailtWebsiteWidgetAsync",
  APPLYDISCOUNTONITEMS: "/BusinessCategoryWebsiteWidget/AddDiscountAndCoupons",
  ADDPRODUCTTOCART: "/BusinessCategoryWebsiteWidget/AddProductIntoCart",
  GETORDERBUNDLEPRICE: "/Order/GetOrderBundlePrice",
  APPLYTAXONITEMS: "/BusinessCategoryWebsiteWidget/GetSelectedProductPrice",
  GETRETAILDETAIL: "/Order/GetOrderRetailDetail",
  GETORDERRENTALPRICE: "/Order/GetOrderRentalPrice",
  ORDERSUMMARYPRINTANDEMAIL: "/Order/GetOrderSummaryPrintAndEmail",
  GLOBALCODE: "/GlobalCode",
  CURRENTDATEFORBUSINESS: "/Order/CurrentDateForBusinessLocationGet",
  GETACTIVITYTICKETDETAILS: "/Order/GetOrderActivityDetailTickets",
  // GETPRODUCTAVAILAVILITY: "/BusinessCategoryWebsiteWidget/GetProductAvailabilityForWebsiteWidget",
  GETSLOTSAVAILABILITY:
    "/BusinessCategoryWebsiteWidget/GetTimeSlotsforProductWebsiteWidgetAsync",
  GETRENTALAVAILABILITY:
    "/BusinessCategoryWebsitewidget/GetAvailableProductCount",
  GETAVAILABLEACTIVITYTICKETS: "/Order/GetOrderAvailableActivityTickets",
  GETAVAILABILITYFORBUNDLE:
    "/BusinessCategoryWebsiteWidget/GetBundleAvailabilityForWebsiteWidgetAsync",
  GETPRODUCTDURATIONSLOTS: "/Product/GetProductCustomDurationSlots",
  ADDWAIVERCUSTOMERS: "/WavierCustomer/AddWaiverCustomers",
  GETDOCUMENT: "/Document/GetDocument",
  GETREGISTEREDWAIVER: "/WavierCustomer/GetRegisteredWaiver",
  GETWAIVERDOCUMENT: "/WavierCustomer/GetWaiverDocument",
  GETEXISTINGWAIVERCUSTOMERS: "/WavierCustomer/GetExistingWaiverCustomers",
  GETORDERPRODUCTDOCUMENT: "/Order/GetOrderProductDocument",
  GETORDERSEASONPASSDETAIL: "/Order/GetOrderSeasonPassDetail",
  GETEXISTINGWAIVERCUSTOMERSONWIDGET:
    "/WavierCustomer/GetExistingWaiverCustomersOnWidget",
  DELETESEASONPASSDISCOUNTCOUPON:
    "/BusinessCategoryWebsiteWidget/DeleteSeasonPassDiscountCoupon",
  ADDGOOGLECALENDERDETAIL: "/GoogleCalendar/AddGoogleCalendarDetail",
  GETGOOGLECALENDERDETAIL: "/GoogleCalendar/GetGoogleCalendarDetail",
  GETGOOGLECALENDEREVENT:"/GoogleCalendar/GetGoogleCalendarEventOrderDetails",
  ADDGOOGLECALENDEREVENTLOGS :"/GoogleCalendar/AddActionLogs",
};
export const getApiUrl = (key) => {
  return API_URL + API_ENDPOINTS[key];
};
