import React, { Component } from "react";
import MyCartPage from "./my-cart";
import {

  connect,
  bindActionCreators,
  actions,
} from "../../shared/functional/global-import";

class MyCart extends Component {
  render() {
    const {
      individualItem,
      backToHomePage,
      closeWordpressModal,
      history,
      allFunction,
      closeModalAddtocart,
    } = this.props;                                
    return (
      <>
        <MyCartPage
          wordpressBacktoStop={this.props.global.wordpressBacktoStop}
          individualItem={individualItem}
          bookNow={false}
          closeModalAddtocart={closeModalAddtocart}
          backToHomePage={backToHomePage}
          closeWordpressModal={closeWordpressModal}
          allFunction={allFunction}
          history={history}
        />


      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeInsideWordpress: bindActionCreators(
        actions.storeInsideWordpress,
        dispatch
      ),
      storeWordpressBacktoStop: bindActionCreators(
        actions.storeWordpressBacktoStop,
        dispatch
      ),
   
  
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyCart);
