import React,{ Component } from "react";
import {
  Grid,
  Icon,
  Input,
  Form,
  Divider,
  Header,
  Popup,
} from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../shared/functional/global-import";
class SessonPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      sessionPassTotalprice: "",
    };
  }

  countHandleChangeMinusSessionPass = (index) => {
    const count = this.props.allFuction.state.count;
    if (count >= 1) {
      let number = count - 1;
      let sessionPassTotalPrice = JSON.parse(
        (this.props.addItem.price * number).toFixed(2)
      );
      this.setState({
        count: number,
        sessionPassTotalPrice: sessionPassTotalPrice,
      });
      this.props.allFuction.setState({
        count: number < this.props.allFuction.state.minSesionPass ? 0 : number,
        totalprice: sessionPassTotalPrice,
        totalPriceToShow: sessionPassTotalPrice,
      });

      let { sessoinPass } = this.props;
      // Check quantity less than min ticket
      if (number < this.props.allFuction.state.minSesionPass) {
        this.props.allFuction.setState({ sessoinPass: [] });
      } else if (typeof index === "number") {
        sessoinPass.splice(index,1);
        this.props.allFuction.setState({ sessoinPass: sessoinPass });
      } else {
        sessoinPass.splice(-1);
        this.props.allFuction.setState({ sessoinPass: sessoinPass });
      }
      if (number <= 0 || number < this.props.allFuction.state.minSesionPass) {
        this.props.allFuction.setState({ addToCArtOrNot: false });
      }
    }
  };
  countHandleChangePlusSissionPass = () => {
    if (this.props?.seasonPassCustomFields?.length > 0) {
      const count = this.props.allFuction.state.count;
      if (
        count < this.props.allFuction.state.maxSesionPass ||
        this.props.allFuction.state.maxSesionPass === null
      ) {
        let number =
          this.props.allFuction.state.minSesionPass > 0 && count === 0
            ? this.props.allFuction.state.minSesionPass
            : count + 1;
        let sessionPassTotalprice = JSON.parse(
          (this.props.addItem.price * number).toFixed(2)
        );
        this.setState({
          count: number,
          sessionPassTotalprice: sessionPassTotalprice,
        });
        this.props.allFuction.setState({
          count: number,
          totalprice: sessionPassTotalprice,
          totalPriceToShow: sessionPassTotalprice,
        });
        if (number > 0) {
          this.props.allFuction.setState({ addToCArtOrNot: true });
        }
        let { sessoinPass } = this.props;
        if (
          this.props.allFuction.state.sessoinPass.length === 0 &&
          this.props.allFuction.state.minSesionPass > 0
        ) {
          for (let i = 0; i < this.props.allFuction.state.minSesionPass; i++) {
            sessoinPass.push({
              field: i + 1,
              customFields: this.props.seasonPassCustomFields,
            });
            this.props.allFuction.setState({ sessoinPass: sessoinPass });
          }
        } else {
          sessoinPass.push({
            field: number,
            customFields: this.props.seasonPassCustomFields,
          });
          this.props.allFuction.setState({ sessoinPass: sessoinPass });
        }
      } else {
        this.props.actions.showNotification(
          {
            title: "Warning",
            message: `Please select minimum ${this.props.allFuction.state.minSesionPass} or maximum ${this.props.allFuction.state.maxSesionPass} item per-order`,
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      }
    }
  };
  onHandleChange = (e,{ value,index,subindex }) => {
    const data = this.props.allFuction.state.sessoinPass;
    const clonedData = JSON.parse(JSON.stringify(data));
    clonedData[index].customFields[subindex].value = value;
    this.props.allFuction.setState({ sessoinPass: clonedData });
  };

  render() {
    return (
      <>
        <Grid>
          <Divider hidden />
          <Grid.Row>
            <Divider hidden />
            <Grid.Column width={6}>
              <h4>Choose Total</h4>
            </Grid.Column>
            <Grid.Column width={5}>
              <div className="add-numbers">
                <Icon
                  disabled={!this.props?.seasonPassCustomFields?.length > 0}
                  name="minus"
                  className="orange-button"
                  onClick={this.countHandleChangeMinusSessionPass}
                ></Icon>
                <Input
                  value={this.props.allFuction.state.count}
                  name="numberOfTickets"
                  fluid
                />
                <Icon
                  disabled={!this.props?.seasonPassCustomFields?.length > 0}
                  name="plus"
                  className="orange-button plus-btn"
                  onClick={this.countHandleChangePlusSissionPass}
                ></Icon>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          <Grid.Row>
            {this.props?.sessoinPass?.length > 0 &&
              this.props.sessoinPass.map((item,index) => {
                return (
                  <>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                      <div class="ui card seasonPassFromCard">
                        <div class="content">
                          <div
                            className="closeIcon"
                            onClick={() =>
                              this.countHandleChangeMinusSessionPass(index)
                            }
                          >
                            <i class="times circle outline icon"></i>
                          </div>
                          <div class="header">
                            <Popup
                              content={this.props.allFuction.state.addItem.name}
                              trigger={
                                <span className="ellipses">{`${this.props.allFuction.state.addItem.name}`}</span>
                              }
                            />
                            <span>{`- Pass ${index + 1}`}</span>
                          </div>
                          <div class="meta">
                            <Form>
                              {item.customFields &&
                                item.customFields.map((itemValue,subindex) => (
                                  <Form.Field class="ui input">
                                    <Input
                                      type="text"
                                      name={`customInput ${index} ${subindex}`}
                                      placeholder={itemValue.CustomDescriptions}
                                      value={itemValue.value}
                                      onChange={(event,{ value }) =>
                                        this.onHandleChange(event,{
                                          value,
                                          index,
                                          subindex,
                                        })
                                      }
                                      error={
                                        itemValue.CustomRequired
                                          ? this.props.allFuction.sessoinPassValidations.message(
                                            "text",
                                            itemValue.value,
                                            "required"
                                          )
                                          : ""
                                      }
                                    />
                                    {itemValue.CustomRequired && (
                                      <span className="estrickSign">*</span>
                                    )}
                                  </Form.Field>
                                ))}
                            </Form>
                          </div>
                          <p></p>
                        </div>
                      </div>
                    </Grid.Column>
                  </>
                )
              })}
          </Grid.Row>
        </Grid>

        {this.props.allFuction.state.totalprice ? (
          <Grid.Column width={16}>
            <Header as="h4" className="durationSubCaption secondary">
                Total :{" "}
                <span className="itemValue">
                  {commonFunctions.isNumberInteger(
                    this.props.allFuction.state.totalprice
                  )}
                </span>
            </Header>
          </Grid.Column>
        ) : (
          ""
        )}
        {this.props.allFuction.state.ItemDescription && (
          <Grid.Column width={16}>
            <Header as="h4">Item Description : </Header>
            <p> {this.props.allFuction.state.ItemDescription}</p>
          </Grid.Column>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall,dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes,dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId,dispatch),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show,dispatch),
    },
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(SessonPass);
