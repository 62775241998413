import React, { Component } from "react";
import { Grid, Icon, Image, List, Menu } from "semantic-ui-react";
import {
  Link,
  env,
  connect,
  commonFunctions,
  bindActionCreators,
  actions,
} from "../../../functional/global-import";
import { withRouter } from "react-router";

class widgetHeader extends Component {
  state = {
    activeItem: "Home",
    show: false,
    showMenu: true,
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
    if (this.state.show) {
      this.addShowClass();
    }
  };

  refreshPage = () => {
    setTimeout(() => {
      window.location.reload(false);
    });
  };

  addShowClass = () => {
    this.setState({ show: !this.state.show });
  };
  componentDidMount = () => {
    this.checkOnWaiverScreen();
  };

  checkOnWaiverScreen = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const customerid = queryParams.get("customerid");
    const documentid = queryParams.get("documentid");
    if (customerid !== null || documentid) {
      this.setState({ showMenu: !this.state.showMenu });
    }
  };
  render() {
    const { activeItem, show } = this.state;
    const imagePath = this.props.global.bussinessDetail;
    return (
      <div className="fixedHeader">
       <span className={`bg-wrapper ${show ? "": "hide"}`} onClick={this.addShowClass}></span>
        <Grid>
          <Grid.Column width={6}>
            <List horizontal>
              <List.Item
                as={Link}
                onClick={this.refreshPage}
                to={env.PUBLIC_URL}
              >
                <Image
                  src={commonFunctions.concatenateImageWithAPIUrl(imagePath)}
                  className="logo"
                />
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={10} verticalAlign="middle" textAlign="right">
            <Icon
              name="bars"
              className="menuIcon"
              size="large"
              onClick={this.addShowClass}
            />
            {this.state.showMenu && (
              <Menu text className={this.state.show && "show"}>
                <Menu.Item
                  as={Link}
                  name="Home"
                  onClick={this.refreshPage}
                  to={env.PUBLIC_URL}
                  active={activeItem === "Home"}
                />
                <Menu.Item
                  name="FAQ"
                  as={Link}
                  active={activeItem === "FAQ"}
                  to={`${env.PUBLIC_URL}/faq`}
                  onClick={this.handleItemClick}
                  className="hideMenu"
                />
                <Menu.Item
                  name="ContactUs"
                  as={Link}
                  to={`${env.PUBLIC_URL}/contact`}
                  active={activeItem === "ContactUs"}
                  onClick={this.handleItemClick}
                  className="hideMenu"
                />
                <Menu.Item
                  name="AboutUs"
                  as={Link}
                  to={`${env.PUBLIC_URL}/about`}
                  active={activeItem === "AboutUs"}
                  onClick={this.handleItemClick}
                  className="hideMenu"
                />
                <Menu.Item
                  name="Policy"
                  as={Link}
                  to={`${env.PUBLIC_URL}/policy`}
                  active={activeItem === "Policy"}
                  onClick={this.handleItemClick}
                  className="hideMenu"
                />
                <Icon
                  name="close"
                  className="closeIcon"
                  onClick={this.addShowClass}
                />
              </Menu>
            )}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(widgetHeader)
);
