import React, { Component, Fragment } from "react";
import { Modal, Grid, Header, Dimmer, Loader, Image } from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  withRouter,
  Notifications,
} from "../../../../../shared/functional/global-import";
import HomeModal from "../../../../../pages/home/modal-home";
import MyCartPage from "../../../../../pages/my-cart/my-cart";
import AddCartPopUp from "../../../../../pages/home/add-cart-popup";
class PluginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isIndividualItemClickec: false,
      individualProductLoader: false,
      isItemSpecificBookNow: false,
      isLocationSelector: false,
      HomePage: false,
      banner: false,
      allCatergory: false,
      backButtonCatergory: true,
      allCategories: [],
      insideWordpress: false,
      allData: "",
      businessLocationId: "",
      LOCATIONFORBUSINESS: [],
      bannerImage: "",
      allProducts: [],
      itemPrice: "",
      businessCategoryTypeId: "",
      addItem: "",
      nocategory: false,
      ProductDetailPage: false,
      productDetail: "",
      locations: "",
      categorySelected: "",
      itemId: 0,
      allTags: [],
      parentId: "",
    };    
  }
  componentDidMount = () => {
    const {
      isToOpenItems,
      businesscategoryId,
      businessLocationId,
      businessId,
    } = this.props;

    if (isToOpenItems) {
      this.setState({
        businessCategoryType: businesscategoryId,
        businessCategoryTypeId: businesscategoryId,
        businessLocationId: businessLocationId,
        businessId: businessId,
        nocategory: false,
        individualProductLoader: false,
        HomePage: true,
      });
    } else {
      this.getBusinessCategoryWebsiteWidget();
    }
  };
  onIndividualItemFunction = () => {
    let individualProductBookNowDetails =
      this?.props?.individualProductBookNowDetails;
    if (individualProductBookNowDetails) {
      if (individualProductBookNowDetails.productId) {
        this.setState({ individualProductLoader: true });
        let obj = {
          businessCategoryTypeId:
            individualProductBookNowDetails.businesscategoryId,
        };
        this.setState({ isItemSpecificBookNow: true });
        setTimeout(() => this?.onBusinessClick(obj), 2000);
      }
    }
  };

  backtochangeCategory = (e, value) => {
    this.setState(
      {
        HomePage: false,
        isItemSpecificBookNow: false,
        isIndividualItemClickec: value,
      },
      () => this.getBusinessCategoryWebsiteWidget(e)
    );
  };
  getBusinessCategoryWebsiteWidget = (e) => {
    let bussinessPathName = document
      .getElementById("rehntit-website-widget")
      .getAttribute("bussinesId");
    let bussinessLocationId = document
      .getElementById("rehntit-website-widget")
      .getAttribute("bussinesslocationid");
    bussinessLocationId =
      bussinessLocationId === null ? 0 : bussinessLocationId;
    const eventDefinebussinessLocation =
      e !== undefined ? e : bussinessLocationId;
    const EventDefineLocations = e !== undefined ? e : this.props.locations;
    
    this.props.actions.apiCall({
      urls: ["GETBUSINESSCATEGORYWEBSIYTE"],
      method: "GET",
      data: {
        WebsiteUrl:
          bussinessPathName === null
            ? this.props.global.bussinessUrl
            : bussinessPathName,
        BusinesslocationId: this.props.insideWordpress
          ? eventDefinebussinessLocation
          : EventDefineLocations,
      },
      onSuccess: (response) => {
                  
        if (response[0].errorCode !== "500") {
          const allCartItems = this.props.global.allProductDetail.products;
          const allCategorShow = JSON.parse(response[0].categories).filter(
            (item) => item.Categories != null
          );
          const cartBusinessIds = allCartItems.map((item) => {
            return item.addItem.businessId;
          });
          const currentBusinessId = response[0].businessId;
          const isSameId = cartBusinessIds.every(
            (currentValue) => currentValue === currentBusinessId
          );
          if (!isSameId) {
            this.props.actions.DeleteAllItemDetail();
          }
          let themeColor = response[0].color;
          this.setState({ businessLocationId: response[0].businessLocationId });
          const root = document.documentElement;
          root?.style.setProperty("--main-color", themeColor);
          this.props.actions.storeLocationTimeZone(
            response[0].locationTimeZone
          );
       
          this.props.actions.storeThemeColor(response[0].color);
          this.props.actions.storeBussinessId(response[0].businessId);
          this.props.actions.storeBussinessDetail(response[0].image);
       
          this.setState({
            svgImages: JSON.parse(response[0].svgImages),
            color: response[0].color,
          });
          this.setState({
            businessId: response[0].businessId,
            isLocationSelector: response[0].isLocationSelector,
            insideWordpress: true,
          });
          if (allCategorShow.length === 1) {
            const singleCategory = allCategorShow[0];
            this.setState({
              businessCategoryType: singleCategory.businessCategoryTypeId,
              nocategory: false,
              HomePage: true,
              businessCategoryTypeId: singleCategory.businessCategoryTypeId,
              individualProductLoader: false,
              allCatergory: false,
            });
          } else if (!allCategorShow.length) {
            this.setState({
              nocategory: false,
              HomePage: true,
              allCatergory: false,
              backButtonCatergory: true,
            });
          } else {
            this.setState({
              allCategories: allCategorShow,
              allCatergory: true,
              backButtonCatergory: true,
            });
          }

          this.props.actions.SHOWHEADERWEBSITE(response[0].widgetHeader);
          this.props.actions.storeInsideWordpress(true);
          this.setState({ banner: true, nocategory: true });
          this.setState({
            allTags:
              JSON.parse(response[0].categories)?.[0].Categories === null
                ? []
                : JSON.parse(
                    JSON.parse(response[0].categories)[0].Categories[0].Tags
                  ),
          });
        } else {
          this.props.actions.showNotification(
            {
              title: "Warning",
              message: response[0].responseMessage,
              position: "br",
              autoDismiss: commonFunctions.notificationTime(),
            },
            "error"
          );
        }
        !this.state.isIndividualItemClickec && this.onIndividualItemFunction();
      },
    });
  };

  onBusinessClick = (addBusinessDetail) => {
    this.setState({
      businessCategoryType: addBusinessDetail.businessCategoryTypeId,
      nocategory: false,
      HomePage: true,
      businessCategoryTypeId: addBusinessDetail.businessCategoryTypeId,
      individualProductLoader: false,
    });
  };

  bookNowModal = () => {
    this.setState({ HomePage: false });
  };
  render() {
    const {
      api,
      individualProductBookNowDetails,
      isShowAvailabletickets,
      isShowAvailableItem,
      fullState,
    } = this.props;
 
    return (
      <Modal
        open={this.props.openModal}
        onClose={this.props.closeModal}
        closeIcon
        size={"fullscreen"}
        className="bookNowModal"
      >
        <Modal.Content scrolling>
          {api.isApiLoading && (
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          )}
          {this.state.individualProductLoader && (
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          )}
          <Modal.Description className="orderPlaced">
            {this.state.nocategory && (
              <>
                {this.state.allCatergory && (
                  <Grid textAlign="center" style={{ width: "100%" }}>
                    <Grid.Column width={16} className="productWrap">
                      {this.state.allCategories &&
                        this.state.allCategories.map(
                          (addBusinessDetail, index) => {
                            return (
                              <>
                                <div className="productItem">
                                  <Grid.Column textAlign="center" key={index}>
                                    <Fragment>
                                      <Grid.Column textAlign="center">
                                        <span
                                          onClick={() =>
                                            this.onBusinessClick(
                                              addBusinessDetail
                                            )
                                          }
                                        >
                                          <div className="cmn-shad">
                                            <Header as="h3">
                                              {addBusinessDetail.catregoryCodeName ===
                                              "Season Pass"
                                                ? "Passes"
                                                : addBusinessDetail.catregoryCodeName}
                                            </Header>
                                            <Image
                                              src={commonFunctions.concatenateImageWithAPIUrl(
                                                addBusinessDetail.Image
                                              )}
                                              fluid
                                            />
                                          </div>
                                        </span>
                                      </Grid.Column>
                                    </Fragment>
                                  </Grid.Column>
                                </div>
                              </>
                            );
                          }
                        )}
                    </Grid.Column>
                  </Grid>
                )}
              </>
            )}

            {this.state.HomePage && (
              <HomeModal
                history={this.props.history}
                individualProductBookNowDetails={
                  individualProductBookNowDetails
                }
                isShowAvailableItem={isShowAvailableItem}
                isShowAvailabletickets={isShowAvailabletickets}
                isLocationSelector={this.state.isLocationSelector}
                backButtonCatergory={this.state.backButtonCatergory}
                insideWordpress={this.props.insideWordpress}
                fullstate={this.props.fullState}
                isItemSpecificBookNow={this.state?.isItemSpecificBookNow}
                openModal={this.state.HomePage}
                backtochangeCategory={this.backtochangeCategory}
                closeModal={this.bookNowModal}
                bookNowState={this.state}
                closeModalhome={this.props.closeModal}
                allFuction={this.props.allFuction}
               
                closeWordpressModal={this.props.closeModal}
              />
            )}
            <AddCartPopUp bookNow={this.props.bookNow}
              openaddtocart={this.props.openaddtocart}
            />
            {fullState.MyCartPage && (
              <MyCartPage
                
                history={this.props.history}
                bookNow={this.props.bookNow}
                backtochangeCategory={this.props.backtochangeCategory}
                fullstate={this.props.fullstate}
                allFuction={this.props.allFuction}
                locations={this.state.locations}
                closeModalhome={this.props.closeModal}
                closeWordpressModal={this.props.closeModal}
                insideWordpress={this.props.insideWordpress}
              />
            )}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      StoreBussinessUrl: bindActionCreators(
        actions.StoreBussinessUrl,
        dispatch
      ),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      DeleteAllItemDetail: bindActionCreators(
        actions.DeleteAllItemDetail,
        dispatch
      ),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      
      storeThemeColor: bindActionCreators(actions.storeThemeColor, dispatch),
      storeLocationTimeZone: bindActionCreators(
        actions.storeLocationTimeZone,
        dispatch
      ),
      storeShowCount: bindActionCreators(
        actions.storeShowCount,
        dispatch
      ),
      storeParentId: bindActionCreators(actions.storeParentId, dispatch),
      SHOWHEADERWEBSITE: bindActionCreators(
        actions.SHOWHEADERWEBSITE,
        dispatch
      ),
      storeBussinessLocationId: bindActionCreators(
        actions.storeBussinessLocationId,
        dispatch
      ),
      storeInsideWordpress: bindActionCreators(
        actions.storeInsideWordpress,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PluginModal)
);
