import React, { Component } from "react";
import axios from "axios";
import {
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../shared/functional/global-import";
import { env } from "../../shared/functional/global-import";
class GoogleCalenderEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventLogs: [],
      ticketValue: {},
    };
  }

  componentDidMount = () => {
  const { showCalenderEventData, userToken} = this.props.fullState;     
                                           
  if (showCalenderEventData) {
    const categoryIdMap = new Map();
    userToken?.forEach((item) => {
      const categoryIds = JSON.parse(item.businessCategoryId);
      categoryIds?.forEach((categoryId) => {
        if (!categoryIdMap.has(categoryId)) {
          categoryIdMap.set(categoryId, []);
        }
        categoryIdMap.get(categoryId).push(item);
      });
    });
    const showCalenderEvent = JSON.parse(showCalenderEventData.summary);
     showCalenderEvent?.forEach((eventData) => {
      const categoryId = eventData.BusinessCategoryType;
      const categoryItems = categoryIdMap.get(categoryId);
      if (categoryItems) {
      categoryItems?.forEach((item) => {
        this.addEvent(eventData, item, this.props.global.locationTimeZone);
        });
      }
    });
  }
  };
  addEvent = async (resp, token, locationTimeZone) => {               
    const { token: authToken } = token;
    const calendarAPIUrl =
      "https://www.googleapis.com/calendar/v3/calendars/primary/events";
    try {
      this.setState({ loading: true, error: null });
      const Tickets = JSON.parse(resp.Tickets);
      let ticketDescriptions = Tickets
        ? Tickets.map(
            (ticket) =>
              `${ticket.Title} - $${parseFloat(ticket.TicketAmount).toFixed(2)}`
          ).join(", ")
        : "";
      const eventDetails = {
        summary:`Order Id: #${resp.OrderId},${resp.ProductName}`,
        description:`<div style="display:flex; flex-direction:column; grid-gap: 5px;"><div style="margin-bottom: 0;"><strong>Order Id:</strong> #${resp.OrderId}</div><div style="margin-bottom: 0;"> <strong>Product Name:</strong> ${resp.ProductName}</div><div style="margin-bottom: 0;"><strong>Total Paid:</strong> $${parseFloat(resp.TotalPaid).toFixed(2)}</div><div style="margin-bottom: 0;"><strong>Order Status:</strong> ${resp.OrderStatus}</div>${Tickets? `<div style="margin-bottom: 0;"><strong>Ticket:</strong> ${ticketDescriptions}</div>`: ""}</div>`,
        start:{ dateTime: resp.StartDate, timeZone: locationTimeZone },
        end:{ dateTime: resp.EndDate, timeZone: locationTimeZone },
      };
      const response = await axios.post(
        calendarAPIUrl,
        eventDetails,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      // Call addCalendarEventLogs with the updated event details
       await this.addCalendarEventLogs(response, resp, token);
  
    } catch (error) {
      console.error("Error adding event:", error);
      this.setState({ error: "Error adding event" });
    } finally {
      this.setState({ loading: false });
    }
  };
  addCalendarEventLogs = async (response, resp, token) => {         
    const eventLogs = [{
      email: token.email,
      eventId: response.data.id,
      OrderId: resp.OrderId,
      applicationId: Number(env.APPLICATION_ID),
      summary: resp,
      businessId: this.props.global.addBussinessId,
      businessLocationId: this.props.businessLocationId,
      eventStartDate: resp.StartDate,
      eventEndDate: resp.EndDate,
      isEventSuccess: true,
      userName: token.userName,
      actionPerformedBy: this.props.addProductsTocart?.actionPerformedBy ?? this.props.fullState.addProductsTocart?.actionPerformedBy,
    }];
    const eventresponse = JSON.stringify(eventLogs);
    await this.props.actions.apiCall({
      urls: ["ADDGOOGLECALENDEREVENTLOGS"],
      method: "POST",
      data: {
        eventDetails: eventresponse,
      },
      onSuccess: (response) => {
        console.log("response",response)
        
      },
    });
  };
  render() {
    return <></>;
  }
  

}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      storeItemDetail: bindActionCreators(actions.storeItemDetail, dispatch),
      AllItemDetailToPay: bindActionCreators(
        actions.AllItemDetailToPay,
        dispatch
      ),
      cartItemDelete: bindActionCreators(actions.cartItemDelete, dispatch),
      DeleteAllItemDetail: bindActionCreators(
        actions.DeleteAllItemDetail,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
      DeleteItemIndividualDetail: bindActionCreators(
        actions.DeleteItemIndividualDetail,
        dispatch
      ),
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleCalenderEvent);
