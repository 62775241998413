import * as types from "../types";
//
const initialState = {
  codes: {},
  bussinessDetail: {},
  addWidgetLogo: "",
  bussinessUrl: "",
  themeColor: "",
  skipOptionInAddItem: true,
  addItemInCart: [],
  parentId: "",
  locationTimeZone:"",
  ShowHeaderOfWebsite: true,
  allProductDetail: {
    selectedTime: "",
    selectedDate: "",
    totalprice: 0,
    subtotal: 0,
    products: [],
  },
  allProductIndividualDetail: {
    selectedTime: "",
    selectedDate: "",
    totalprice: 0,
    subtotal: 0,
    products: [],
  },
  categoryDetail: {},
  addItemId: {},
  addActivityId: {},
  addBussinessId: {},
  bussinessLocationId: "",
};

const globalReducer = (state = initialState, actions) => {                       
  switch (actions.type) {
    case types.STORE_GLOBAL_CODES:
      return { ...state, codes: actions.payload };
    case types.STORE_BUSSINESS_DETAIL:
      return { ...state, bussinessDetail: actions.payload };
    case types.STORE_BUSSINESS_LOCATIONID:
      return { ...state, bussinessLocationId: actions.payload };
    case types.STORE_BUSSINESS_URL:
      return { ...state, bussinessUrl: actions.payload };
    case types.STORE_THEME_COLOR:
      return { ...state, themeColor: actions.payload };
    case types.STORE_SKIP_OPTION:
      return { ...state, skipOptionInAddItem: actions.payload };
    case types.STORE_PARENT_ID:
      return { ...state, parentId: actions.payload};
      case types.STORE_LOCATION_TIME_ZONE:
        return { ...state, locationTimeZone: actions.payload};
        case types.STORE_SHOW_COUNT:
          return { ...state, showCount: actions.payload};
          case types.STORE_INSIDE_WORDPRESS:
            return { ...state, insideWordpress: actions.payload};
            case types.STORE_WORDPRESS_BACK_TO_SHOP:
              return { ...state, wordpressBacktoStop: actions.payload};
    case types.STORE_ITEM_DETAIL:
      let newObj = [];
      state.addItemInCart.push(newObj);
      return { ...state };

    case types.STORE_DELETE_ALLITEMS:
      state.allProductDetail = {
        documentId: [],
        selectedTime: "",
        selectedDate: "",
        totalprice: "",
        subtotal: 0,
        products: [],
      };
      return { ...state };

    case types.STORE_DELETE_INDIVIDUAL_ITEMS:
      state.allProductIndividualDetail = {
        documentId: [],
        selectedTime: "",
        selectedDate: "",
        totalprice: 0,
        subtotal: 0,
        products: [],
      };
                
      return { ...state };
    case types.SHOW_HEADER_WEBSITE:
      return { ...state, ShowHeaderOfWebsite: actions.payload };
    case types.STORE_ALLITEM_DETAIL_TOPAY:
      state.allProductDetail.products.push(actions.payload[0]);
      state.allProductDetail.selectedTime =
        actions.payload[1].extraDetail.selectedTime;
      state.allProductDetail.selectedDate =
        actions.payload[1].extraDetail.selectedDate;
      state.allProductDetail.totalprice =
        actions.payload[1].extraDetail.totalprice;
      state.allProductDetail.subtotal = actions.payload[1].extraDetail.subtotal;
      return { ...state };
    case types.STORE_INDIVIDUAL_ITEM_DETAIL:
      state.allProductIndividualDetail.products.push(actions.payload[0]);
      state.allProductIndividualDetail.selectedTime =
        actions.payload[1].extraDetail.selectedTime;
      state.allProductIndividualDetail.selectedDate =
        actions.payload[1].extraDetail.selectedDate;
      state.allProductIndividualDetail.totalprice =
        actions.payload[1].extraDetail.totalprice;
      state.allProductIndividualDetail.subtotal =
        actions.payload[1].extraDetail.subtotal;

      return { ...state };
    case types.STORE_ITEM_UPDATE:
      state.allProductDetail.products = actions.payload;
      state.allProductDetail.selectedDate = "";
      state.allProductDetail.selectedTime = "";
      state.allProductDetail.subtotal = 0;
      state.allProductDetail.totalprice = 0;
      return { ...state };

    case types.STORE_BUSSINESS_ID:
      return { ...state, addBussinessId: actions.payload };

    default:
      return state;
  }
};
export default globalReducer;
