import React, { Component } from "react";
import {
  Grid,
  Image,
  Menu,
  Header,
  List,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
} from "../../shared/functional/global-import";

class StepOne extends Component {
  state = {
    activeItem: "account",
    locationDetails: [],
    activeid: "",
    locationId: "",
    isApiLoading: false,
  };

  componentDidMount = () => {
    this.getLocationType();
    this.handleBusinessLocation();
  };

  // API For Get Location Data From Selected Item
  getLocationType = () => {
    this.setState({ isApiLoading: true });
    this.props.actions.apiCall({
      urls: ["GETLOCATIONBYITEMTYPE"],
      method: "GET",
      data: {
        InWebsiteWidget: 1,
        ItemId: this.props.addItem.productId,
        BusinessCategoryType: this.props.addItem.businessCategoryType,
        BusinessId: this.props.addItem.businessId,
        BusinessLocationId: this.props.addItem.businessLocationId,
        PageNo: 1,
        PageSize: 100,
      },
      onSuccess: (response) => {
        const { locationDetails } = this.state;
        this.setState({ locationId: response[0].businessLocationId });
        locationDetails.push(response);
        this.setState({ isApiLoading: false });
      },
    });
  };

  handleBusinessLocation = () => {
    const { allFuction } = this.props;
    const { locationId } = this.state;
    this.props.allFuction.setState({
      ...allFuction.state,
      activeItem: locationId,
    });
    this.setState({ activeid: "location1" });
  };

  render() {
    const { activeid, locationDetails } = this.state;
    return (
      <Grid>
        <Grid.Column width={16}>
          <Header as="h5">Set Location</Header>
        </Grid.Column>
        <Grid.Column computer={6} mobile={16}>
          <Menu secondary vertical className="locationMenu">
            {locationDetails.map((locations) => {
                        
              let aa = locations[0].businessLocationId;
              return (
                <>
                  {locations.map((locationData, index) => {
                    return (
                      <>
                      {locationData.image ===
                     "NullImage.png" ? null : (
                      
                      <Menu.Item
                        name="location1"
                        active={activeid === "location1"}
                    
                        data={aa}
                      >
                        <Image
                          src={commonFunctions.concatenateImageWithAPIUrl(
                            locationData.image
                          )}
                          fluid
                        />
                      </Menu.Item>
                      
                      )}
                      </>
                    );
                  })}
                </>
              );
            })}
          </Menu>
        </Grid.Column>
        <Grid.Column computer={10} mobile={16}>
          <Menu secondary vertical className="locationMenu">
            {locationDetails.map((locationsAddress) => {
              return (
                <>
                  {locationsAddress.map((addressData) => {
                    return (
                      <Menu.Item>
                        <div className="address-bar">
                          <b>{addressData.businessLocationName}</b>
                          <List>
                            <List.Item
                              icon="map marker alternate"
                              content={
                                <p className="showTextLimted">
                                  {addressData.address}
                                </p>
                              }
                            />
                            <List.Item
                              icon="mobile alternate"
                              content={
                                <p className="showTextLimted">
                                  {addressData.mobileNo}{" "}
                                </p>
                              }
                            />
                            <List.Item
                              icon="time"
                              content={
                                <p className="showTextLimted">
                                  {addressData.businessLocationTiming}
                                </p>
                              }
                            />
                          </List>
                        </div>
                      </Menu.Item>
                    );
                  })}
                </>
              );
            })}
          </Menu>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StepOne);
