import React, { Component } from "react";
import {
  Button,
  Modal,
  Grid,
  Form,
  Header,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { DateInput, TimeInput } from "semantic-ui-calendar-react";
import {
  commonFunctions,
  connect,
  bindActionCreators,
  actions,
  Notifications,
} from "../../../../functional/global-import";
import moment from "moment";

class RentalPeriodModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Date: "",
      time: "",
      availabilitySchedule: {
        startDate: this.props.startDate, // start date.
        EndDate: this.props.EndDate, // end date.
        startTime: this.props.startTime, // start time
        endTime: this.props.endTime, // end time
      },
      todayDate: "",
    };
    this.validator = commonFunctions.initializeSimpleValidator();
  }

  componentDidMount = () => {
    this.showTodayDate();
  };

  showTodayDate = () => {
    var today = new Date();
    var now = moment(today).format("MMM DD YYYY");
    this.setState({ todayDate: now });
  };

  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  // Onchange Function For Date Input
  onHandleChangeDate = (e, { name, value, type, checked, data }) => {

    const { allFuction } = this.props;
    if (data === "startDate") {
      this.setState({
        availabilitySchedule: {
          ...this.state.availabilitySchedule,
          startDate: value,
        },
      });
      allFuction.setState({ startDate: value });
    } else if (data === "EndDate") {
      this.setState({
        availabilitySchedule: {
          ...this.state.availabilitySchedule,
          EndDate: value,
        },
      });
      allFuction.setState({ EndDate: value });
    } else if (data === "startTime") {
      this.setState({
        availabilitySchedule: {
          ...this.state.availabilitySchedule,
          startTime: value,
        },
      });
      allFuction.setState({ startTime: value });
    } else if (data === "endTime") {
      this.setState({
        availabilitySchedule: {
          ...this.state.availabilitySchedule,
          endTime: value,
        },
      });
      allFuction.setState({ endTime: value });
    }
  };

  getAllCategories = (e) => {
    const { allState } = this.props;
    const { availabilitySchedule } = this.state;
    let sDate = availabilitySchedule.startDate;
    let eDate = availabilitySchedule.EndDate;
    let sTime = availabilitySchedule.startTime;
    let eTime = availabilitySchedule.endTime;
    var isFormValid = commonFunctions.onHandleFormSubmit(e, this);
    if (isFormValid) {
      let startDateTime = new Date(sDate.concat(" ", sTime));
      let endDateTime = new Date(eDate.concat(" ", eTime));
      if (startDateTime < endDateTime) {
        this.props.actions.apiCall({
          urls: ["GETCATEGORIESFORBUSINESS"],
          method: "GET",
          data: {
            businessId: this.props.global.addBussinessId,
            BusinessLocationId: allState.locations,
            ParentId: this.props.global.parentId,
            BusinessCategoryType: allState.businessCategoryType,
            startDate: availabilitySchedule.startDate,
            EndDate: availabilitySchedule.EndDate,
            startTime: availabilitySchedule.startTime,
            endTime: availabilitySchedule.endTime,
            PageNo: 1,
            PageSize: 100,
          },
          onSuccess: (response) => {
            if (response[0].count === 0) {
              const emptyShow =
                this.props.global.allProductDetail.products.length < 1
                  ? true
                  : false;
              {
                emptyShow && (
                  <span>No Item available for selected Date/Time.</span>
                );
              }
            }
            this.props.allFuction.setState({ allProducts: response });
            if (response.length === 0) {
              this.props.allFuction.setState({ nocategory: true });
            }
            this.props.closeModal();
          },
        });
      } else {
        this.props.actions.showNotification(
          {
            title: "Warning",
            message: "Start Date/Time must be less than End Date/Time",
            position: "br",
            autoDismiss: commonFunctions.notificationTime(),
          },
          "error"
        );
      }
    }
  };

  closeModalData = () => {
    this.props.closeModal();
    this.clearForm();
  };

  clearForm = () => {
    this.setState({
      availabilitySchedule: {
        ...this.state.availabilitySchedule,
        startDate: "",
        EndDate: "",
        startTime: "",
        endTime: "",
      },
    });
    this.props.allFuction.setState({
      startDate: "",
      EndDate: "",
      startTime: "",
      endTime: "",
    });
  };

  render() {
    const { api } = this.props;
    const { availabilitySchedule, todayDate } = this.state;
    return (
      <Modal
        bottom={true}
        open={this.props.openModal}
        closeIcon
        onClose={this.closeModalData}
        closeOnDimmerClick={false}
      >
        {api.isApiLoading && (
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        )}
        <Modal.Header>Check Availability </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Grid>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <Header as="h5">Pickup Date/Time</Header>
                  <Form.Group inline widths="equal">
                    <DateInput
                      name="txtStartDate"
                      dateFormat="MMM-DD-YYYY"
                      placeholder="Start Date"
                      data="startDate"
                      hideMobileKeyboard={true}
                      value={availabilitySchedule.startDate}
                      iconPosition="right"
                      onChange={this.onHandleChangeDate}
                      closable={true}
                      fluid
                      minDate={todayDate}
                      error={this.validator.message(
                        "startDate",
                        availabilitySchedule.startDate,
                        `required`
                      )}                     
                      keydown={false}
                      open
                    />

                    <TimeInput
                      name="txtStartTime"
                      placeholder="Start Time"
                      data="startTime"
                      value={availabilitySchedule.startTime}
                      iconPosition="right"
                      timeFormat="AMPM"
                      onChange={this.onHandleChangeDate}
                      closable={true}
                      fluid
                      error={this.validator.message(
                        "startTime",
                        availabilitySchedule.startTime,
                        `required`
                      )}
                      hideMobileKeyboard={true}
                      open
                    />
                  </Form.Group>
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={16}>
                  <Header as="h5">Return Date/Time</Header>
                  <Form.Group inline widths="equal">
                    <DateInput
                      id={"EndDate"}
                      hideMobileKeyboard={true}
                      name="txtEndDate"
                      dateFormat="MMM-DD-YYYY"
                      placeholder="End Date"
                      data="EndDate"
                      value={availabilitySchedule.EndDate}
                      iconPosition="right"
                      onChange={this.onHandleChangeDate}
                      closable={true}
                      fluid
                      open
                      // minDate={availabilitySchedule.startDate}
                      error={this.validator.message(
                        "EndDate",
                        availabilitySchedule.EndDate,
                        `required`
                      )}
                    />
                    <TimeInput
                      name="txtStartTime"
                      placeholder="End Time"
                      data="endTime"
                      timeFormat="AMPM"
                      value={availabilitySchedule.endTime}
                      iconPosition="right"
                      onChange={this.onHandleChangeDate}
                      closable={true}
                      fluid
                      error={this.validator.message(
                        "endTime",
                        availabilitySchedule.endTime,
                        `required`
                      )}
                      hideMobileKeyboard={true}
                      popupPosition={"top right"}      
                      open               
                    />
                  </Form.Group>
                </Grid.Column>
              </Grid>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button className="secondaryBtn" onClick={this.clearForm}>
            Clear
          </Button>
          <Button className="primaryBtn" onClick={this.getAllCategories}>
            Apply
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    api: state.api,
    global: state.global,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      apiCall: bindActionCreators(actions.apiCall, dispatch),
      storeGlobalCodes: bindActionCreators(actions.storeGlobalCodes, dispatch),
      storeBussinessId: bindActionCreators(actions.storeBussinessId, dispatch),
      storeBussinessDetail: bindActionCreators(
        actions.storeBussinessDetail,
        dispatch
      ),
      showNotification: bindActionCreators(Notifications.show, dispatch),
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RentalPeriodModal);
