import React, { Component } from "react";
import { Modal, Grid, Radio, Divider } from "semantic-ui-react";

class SelectMail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = () => {
    this.setState({ closeModal: !this.state.closeModal });
  };

  render() {
    const onChangeSelectCostumer = (index) => {
      const { addProductsTocart } = this.props.allFuction.state;
      const data = this.props.allCoustomer;
      addProductsTocart.lastName =
        data[0].lastName === null ? "" : data[index].lastName;
      addProductsTocart.firstName = data[index].firstName;
      addProductsTocart.address = data[index].address;
      addProductsTocart.customerId = data[index].customerId;
      addProductsTocart.stateId = data[index].stateId;
      addProductsTocart.zipCode = data[index].zipCode;
      addProductsTocart.city = data[index].city;
      addProductsTocart.phoneNumber = data[index].phoneNumber;
      addProductsTocart.actionPerformedBy = data[index].emailId;
      this.props.allFuction.setState({
        addProductsTocart: {
          ...this.props.allFuction.state.addProductsTocart,
          addProductsTocart,
        },
      });
      this.props.onclose();
    };
    return (
      <Modal
        open={this.props.openModal}
        onClose={this.props.onclose}
        closeIcon
        size={"tiny"}
      >
        <Modal.Content>
          <Modal.Header as={"h3"} className="signerModalHeader">
            The email is registered to the following accounts, please confirm
            which is yours or select none and continue to create a new account.
          </Modal.Header>
          <Divider />
          <Modal.Description>
            <Grid>
              <Grid.Column width={16}>
                {this.props.allCoustomer &&
                  this.props.allCoustomer.map((Coustomer, index) => (
                    <div>
                      <Radio
                        className="custom-radio-btn"
                        name="Coustomer"
                        label={`${Coustomer.firstName} ${Coustomer.lastName}`}
                        onClick={(event) =>
                          onChangeSelectCostumer(index, event)
                        }
                        value={Coustomer.customerId}
                        checked={
                          this.props.allFuction.state.addProductsTocart
                            .customerId === Coustomer.customerId
                        }
                      />
                      <p>&nbsp;</p>{" "}
                    </div>
                  ))}
              </Grid.Column>
            </Grid>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default SelectMail;
